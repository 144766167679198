import React, { useEffect, useState } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';


export default function MessagePrompt(props) {
  const message = props.message;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (message) setOpen(true);
  }, [message]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Snackbar open={open}
                autoHideDuration={props.autoHideDuration}
                onClose={handleClose}
                {...props}
                className="common-message-prompt">
        <MuiAlert elevation={6} variant="filled" severity={props.severity} >
          {props.message}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};
