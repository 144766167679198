import React from 'react';
import { PageHeader } from '../common/index';
import { EquipmentTable } from './index';
import BiometricUsagePromptDialog from '../authentication/BiometricUsagePromptDialog';
import { isMobile } from 'react-device-detect';


export default function MyEquipment(props) {

  return (
    <div className="equipments-my-equipment">
      <PageHeader location={props.location} />

      <EquipmentTable history={props.history} />

      {
          isMobile && <BiometricUsagePromptDialog/>
      }
    </div>
  );
};
