import React, { useState } from 'react';
import {
  Typography,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  FormHelperText,
  IconButton,
  Button,
  Grid,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import AuthTextField from './AuthTextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useForgotPassword, useSignIn } from './redux/hooks';
import Swal from 'sweetalert2';
import { LoadingButton } from '../common';
import { isEmpty } from 'underscore';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const validationSchema = Yup.object({
  email: Yup.string().required('Required').email(),
  password: Yup.string().required('Required'),
});


export default function SignIn(props) {
  const isXs = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const [showPassword, setShowPassword] = useState(false);
  const [captchaError, setCaptchaError] = useState('');
  const {signIn, signInError, signInPending} = useSignIn();
  const {forgotPassword, forgotPasswordPending} = useForgotPassword();
  const {executeRecaptcha} = useGoogleReCaptcha();

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: values => {
      // executeRecaptcha('login').then(res => {
      //     if (res) {
              signIn(values);
      //     } else {
      //       setCaptchaError('Invalid Login.');
      //     }
      // }, err => {
      //     setCaptchaError('Invalid Login.');
      // });
    },
  });

  const handleForgotPassword = () => {
    if (!formik.values.email) {
      Swal.fire('', 'Please enter email address', 'warning');
      return;
    }

    executeRecaptcha('forgotpassword').then(res => {
        if (res) {
            forgotPassword(formik.values.email)
                .then(res => {
                    Swal.fire({
                        icon: 'success',
                        text: res.message,
                        timer: 2000
                    });
                });
        } else {
            setCaptchaError('Error on Forgot Password.');
        }
    }, err => {
        setCaptchaError('Error on Forgot Password.');
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const errorMessage = !isEmpty(signInError)
                       ? signInError.message
                       : (captchaError ? captchaError : null);

  return (
    <div className="authentication-sign-in">
      <Typography 
        variant="h5" 
        component="h5" 
        color="secondary" 
        className={isXs ? "authentication-header-content authentication-header-content-xs" : "authentication-header-content"}>
          Log In
      </Typography>

      <section className="authentication-form-container">
        <form noValidate autoComplete="on" onSubmit={formik.handleSubmit}>
          <Grid container
                direction="column"
                justify="center"
                alignItems="center"
          >
            <Grid container
                  item
                  md={12}
                  className={`${isXs ? "authentication-form-grid-row error-xs-style" : "authentication-form-grid-row"}`}>
              <Grid item md={12} className="authentication-form-grid-cell">
                {
                    errorMessage
                    ? <Alert elevation={0} variant="standard" severity="error">
                        {errorMessage}
                      </Alert>
                    : null
                }
              </Grid>
            </Grid>

            <Grid container
                  item
                  md={12}
                  className="authentication-form-grid-row">
              <Grid item xs={12} md={12} className="authentication-form-grid-cell">
                <AuthTextField
                  id="email"
                  type="email"
                  label="EMAIL ADDRESS"
                  fullWidth
                  helperText={formik.errors.email ? formik.errors.email : ''}
                  error={Boolean(formik.errors.email)}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  required
                />
              </Grid>
            </Grid>

          <Grid container
                  item
                  md={12}
                  className="authentication-form-grid-row">
              <Grid item md={12} xs={12} className="authentication-form-grid-cell">
                <FormControl fullWidth
                             error={Boolean(formik.errors.password)}
                             className="authentication-auth-text-field">
                  <InputLabel htmlFor="password">PASSWORD</InputLabel>
                  <Input
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    required
                  />
                  <FormHelperText id="password-error-text">{formik.errors.password ? formik.errors.password : ''}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container
                  item
                  md={12}
                  justify="center"
                  alignItems="center"
                  className="authentication-form-grid-row">
              <Grid item className="authentication-form-grid-cell">
                <Typography className="authentication-form-question-opt" variant="p" component="p">Don't have an account? <a href="/auth/register">Register Now</a></Typography>
              </Grid>
            </Grid>

            <Grid container
                  item
                  md={12}
                  className="authentication-form-grid-row">
              <Grid item md={12} xs={12} className="authentication-form-grid-cell">
                {
                  signInPending === true
                  ? <LoadingButton />
                  : <Button className="authentication-form-main-btn"
                            type="submit"
                            variant="contained"
                            size="large"
                            color="secondary"
                            fullWidth>
                      Login
                    </Button>
                }
              </Grid>
            </Grid>

            <Grid container
                  item
                  md={12}
                  justify="center"
                  alignItems="center"
                  className="authentication-form-grid-row">
              <Grid item className="authentication-form-grid-cell">
                {
                  forgotPasswordPending === true
                  ? <LoadingButton variant="text" />
                  : <Typography className="authentication-forgot-password" variant="a" component="a" onClick={handleForgotPassword}>Forgot password?</Typography>
                }
                {/*<a href="https://dc2kentprodcontent.blob.core.windows.net/qrspecsheets/GDM49HCTSL01.pdf" target="_blank" download>Download here.</a>*/}
              </Grid>
            </Grid>

            {
                window.ReactNativeWebView &&
                <Grid container
                      item
                      md={12}
                      justify="center"
                      alignItems="center"
                      className="authentication-form-grid-row">
                  <Grid item className="authentication-form-grid-cell">
                    <IconButton primary onClick={() => {
                        let data = {
                            'action': 'SIGNIN_BIOMETRIC',
                        };
                        window.ReactNativeWebView.postMessage(JSON.stringify(data));
                    }}>
                      <FingerprintIcon fontSize="large"/>
                    </IconButton>
                  </Grid>
                </Grid>
            }
          </Grid>
        </form>
      </section>
    </div>
  );
};
