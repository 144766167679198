export const SALUTATIONS = [
  'Mr.',
  'Mrs.',
  'Miss.',
  'Dr.',
];

export const PROFILE_GET_PROFILE_BEGIN = 'PROFILE_GET_PROFILE_BEGIN';
export const PROFILE_GET_PROFILE_SUCCESS = 'PROFILE_GET_PROFILE_SUCCESS';
export const PROFILE_GET_PROFILE_FAILURE = 'PROFILE_GET_PROFILE_FAILURE';
export const PROFILE_GET_PROFILE_DISMISS_ERROR = 'PROFILE_GET_PROFILE_DISMISS_ERROR';
export const PROFILE_UPDATE_PROFILE_BEGIN = 'PROFILE_UPDATE_PROFILE_BEGIN';
export const PROFILE_UPDATE_PROFILE_SUCCESS = 'PROFILE_UPDATE_PROFILE_SUCCESS';
export const PROFILE_UPDATE_PROFILE_FAILURE = 'PROFILE_UPDATE_PROFILE_FAILURE';
export const PROFILE_UPDATE_PROFILE_DISMISS_ERROR = 'PROFILE_UPDATE_PROFILE_DISMISS_ERROR';
export const PROFILE_UPDATE_PROFILE_PHOTO_BEGIN = 'PROFILE_UPDATE_PROFILE_PHOTO_BEGIN';
export const PROFILE_UPDATE_PROFILE_PHOTO_SUCCESS = 'PROFILE_UPDATE_PROFILE_PHOTO_SUCCESS';
export const PROFILE_UPDATE_PROFILE_PHOTO_FAILURE = 'PROFILE_UPDATE_PROFILE_PHOTO_FAILURE';
export const PROFILE_UPDATE_PROFILE_PHOTO_DISMISS_ERROR = 'PROFILE_UPDATE_PROFILE_PHOTO_DISMISS_ERROR';
export const PROFILE_UPDATE_BACKGROUND_IMAGE_BEGIN = 'PROFILE_UPDATE_BACKGROUND_IMAGE_BEGIN';
export const PROFILE_UPDATE_BACKGROUND_IMAGE_SUCCESS = 'PROFILE_UPDATE_BACKGROUND_IMAGE_SUCCESS';
export const PROFILE_UPDATE_BACKGROUND_IMAGE_FAILURE = 'PROFILE_UPDATE_BACKGROUND_IMAGE_FAILURE';
export const PROFILE_UPDATE_BACKGROUND_IMAGE_DISMISS_ERROR = 'PROFILE_UPDATE_BACKGROUND_IMAGE_DISMISS_ERROR';
