import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MANUFACTURERS_TOGGLE_ADD_MANUFACTURER_DIALOG,
} from './constants';
import { isNull } from 'underscore';

export function toggleAddManufacturerDialog(flag=null) {
  return {
    type: MANUFACTURERS_TOGGLE_ADD_MANUFACTURER_DIALOG,
    flag: flag,
  };
}

export function useToggleAddManufacturerDialog() {
  const dispatch = useDispatch();
  const isAddManufacturerDialogVisible = useSelector(state => state.manufacturers.isAddManufacturerDialogVisible);
  const boundAction = useCallback((...params) => dispatch(toggleAddManufacturerDialog(...params)), [dispatch]);
  return { isAddManufacturerDialogVisible, toggleAddManufacturerDialog: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANUFACTURERS_TOGGLE_ADD_MANUFACTURER_DIALOG:
      return {
        ...state,
        isAddManufacturerDialogVisible: isNull(action.flag) ? !state.isAddManufacturerDialogVisible : action.flag,
      };

    default:
      return state;
  }
}
