import React, {useEffect, useState} from 'react';
import {
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
} from '@material-ui/core';
import StringHelper from "../common/services/StringHelper";
import * as mimeTypes from 'mime-types';
import pdfIcon from './../../images/pdf.png';
import videoIcon from './../../images/VideoImage.png';
import ActivityImageDialog from "./ActivityImageDialog";
import {useToggleActivityImageDialog} from "./redux/toggleActivityImageDialog";
import ReactCardCarousel from "react-card-carousel";


function ActivityFilePreview(props) {
  let comp = null;
  const mimeType = mimeTypes.lookup(props.fileName);

  if (mimeType.includes('pdf')) {
    comp = (
        <Card className="activity-card-file-preview" onClick={props.onClick}>
          <CardMedia image={pdfIcon} />
          <CardContent>
            <a href={props.fileName} target="_blank">Preview</a>
          </CardContent>
        </Card>
    );
  } else if (mimeType.includes('video') || mimeType.includes('image')) {
    let isVideo = mimeType.includes('video');
    const icon = isVideo ? videoIcon : props.fileName;
    comp = (
        <Card className="activity-card-file-preview" onClick={props.onClick}>
          <CardMedia image={icon} />
          <CardContent>
            <a href="#openImageModal" onClick={(e) => {
              props.handleActivityImgClick(e, props.fileName)
            }}>Preview</a>
          </CardContent>
        </Card>
    );
  }
  return comp;
}

// function ActivityFilePreview(props) {
//   let comp = null;
//   const mimeType = mimeTypes.lookup(props.fileName);
//
//   if (mimeType.includes('pdf')) {
//     comp = (
//         <a href={props.fileName} target="_blank">
//           <img src={pdfIcon} alt={props.fileName}/>
//         </a>
//     );
//   } else if (mimeType.includes('video') || mimeType.includes('image')) {
//     let isVideo = mimeType.includes('video');
//     const icon = isVideo ? videoIcon : props.fileName;
//     comp = (
//         <a href="#openImageModal" onClick={(e) => {
//           props.handleActivityImgClick(e, props.fileName)
//         }}>
//           <img src={icon} alt={props.fileName}/>
//         </a>
//     );
//   }
//   return comp;
// }

export default function ActivityDetailsDialogContent(props) {
  const [activityFiles, setActivityFiles] = useState([]);
  const _activityImage = props.activityDetails.activity.activityImage;
  const {toggleActivityImageDialog} = useToggleActivityImageDialog();

  const handleActivityImgClick = (event, fileName) => {
    event.preventDefault();
    toggleActivityImageDialog(true, fileName, activityFiles);
  };

  const cardClick = (f) => {
    console.log('ON CLICK CARD',f);
  };

  useEffect(() => {
    if (_activityImage) {
      try {
        const parsedActivityImage = JSON.parse(_activityImage);
        setActivityFiles(parsedActivityImage);
      } catch(e) {
        setActivityFiles([_activityImage]);
      }
    } else {
      setActivityFiles([]);
    }
  }, [_activityImage]);

  useEffect(() => {
    return () => {
      setActivityFiles([]);
    };
  }, []);

  let selectedShippersListData = -1;
  if(props.shippersListData && props.shippersListData.length>0 
    && props.activityDetails.activity.activtyType
    && props.activityDetails.activity.activtyType.activityTypeId === 2
    && props.activityDetails.activity.shipperId) {
      selectedShippersListData = props.shippersListData.find((item) => (item.shipperId == props.activityDetails.activity.shipperId));
  }

  return (
    <div className="equipments-activity-details-dialog-content">
      <Grid container
            direction="row"
            alignItems="flex-start">
        <Grid item lg={6}>
          <table>
            <tbody>
            <tr>
              <td colSpan={2}>
                <Typography variant="p" display="block" color="secondary">{props.user.email}</Typography>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <Typography variant="p" display="block" color="secondary">{props.user.owner && props.user.owner.company}</Typography>
              </td>
            </tr>

            <tr><td colSpan={2}></td></tr>

            <tr><td width="60%">Manufacturer</td><td>{props.activityDetails.equipmentDetails.manufacturer.description}</td></tr>
            <tr><td width="60%">Serial Number</td><td>{props.activityDetails.equipmentDetails.serialNumber}</td></tr>
            <tr>
              <td colSpan={2}>
                <div className="activity-media-carousel-container">
                  <ReactCardCarousel autoplay={false} spread="narrow">
                    {
                      activityFiles.map((fileName, index) => (
                          <ActivityFilePreview fileName={fileName}
                                               onClick={cardClick.bind(this, fileName)}
                                               handleActivityImgClick={handleActivityImgClick} />
                      ))
                    }
                  </ReactCardCarousel>
                </div>

                {/*<GridList cellHeight={160} className="activity-preview-files" cols={3}>*/}
                {/*  {activityFiles.map((fileName, index) => (*/}
                {/*      <GridListTile key={index} cols={1}>*/}
                {/*        <ActivityFilePreview fileName={fileName}*/}
                {/*                             handleActivityImgClick={handleActivityImgClick} />*/}
                {/*      </GridListTile>*/}
                {/*  ))}*/}
                {/*</GridList>*/}

                <ActivityImageDialog  />
              </td>
            </tr>
            </tbody>
          </table>
        </Grid>

        <Grid item lg={6}>
            <table>
              <tbody>
                <tr><td>Description</td><td>{props.activityDetails.equipmentDetails.part ? props.activityDetails.equipmentDetails.part.description : ""}</td></tr>
                <tr><td>Owner</td><td>{ props.activityDetails.equipmentDetails.owner ? props.activityDetails.equipmentDetails.owner.company : "" }</td></tr>
                <tr><td>Date of Install</td>
                    <td>{StringHelper.formatDisplayDate(props.activityDetails.equipmentDetails.dateOfInstall)}</td>
                </tr>
                {/* <tr><td>Location</td><td>{props.activityDetails.equipmentDetails.location}</td></tr> */}

                <tr>
                  <td>Activity</td>
                  <td>
                    {/* <Select
                      id="activity-select"
                      disabled
                      className="input-select"
                      value={props.activityDetails.activity.activtyType && props.activityDetails.activity.activtyType.description.toUpperCase()}
                    >
                      {
                        ACTIVTY_SELECTIONS.map(activity => <MenuItem value={activity}>{activity}</MenuItem> )
                      }
                    </Select> */}
                    { props.activityDetails.activity.activtyType && props.activityDetails.activity.activtyType.description }
                  </td>
                </tr>

                {/** Shipped(2) */}
                { (props.activityDetails.activity.activtyType
                  && props.activityDetails.activity.activtyType.activityTypeId === 2) &&
                  <>
                    { selectedShippersListData != -1 &&
                      <tr>
                        <td>Shipper</td>
                        <td>{selectedShippersListData.name}</td>
                      </tr>
                    }
                    <tr>
                      <td>ProNumber</td>
                      <td>{props.activityDetails.activity.proNumber}</td>
                    </tr>
                  </>
                }

                {/* Storage(3), Warehouse Entry(4), Warehouse Exit(5),
                  *  Maintenance Scheduled(10), Maintenance Unscheduled(11),
                  *  Warranty Work(12), Service Agreement Work(13) */}
                { ( props.activityDetails.activity.activtyType &&
                 ( props.activityDetails.activity.activtyType.activityTypeId === 3
                  || props.activityDetails.activity.activtyType.activityTypeId === 4
                  || props.activityDetails.activity.activtyType.activityTypeId === 5
                  || props.activityDetails.activity.activtyType.activityTypeId === 10
                  || props.activityDetails.activity.activtyType.activityTypeId === 11
                  || props.activityDetails.activity.activtyType.activityTypeId === 12
                  || props.activityDetails.activity.activtyType.activityTypeId === 13 )) &&
                    <tr>
                      <td>Cost</td>
                      <td>${ props.activityDetails.activity.cost ? props.activityDetails.activity.cost : 0 }</td>
                    </tr>
                }

                {/* Maintenance Scheduled(10), Maintenance Unscheduled(11),
                  * Warranty Work(12), Service Agreement Work(13) */}
                { ( props.activityDetails.activity.activtyType &&
                 ( props.activityDetails.activity.activtyType.activityTypeId === 10
                  || props.activityDetails.activity.activtyType.activityTypeId === 11
                  || props.activityDetails.activity.activtyType.activityTypeId === 12
                  || props.activityDetails.activity.activtyType.activityTypeId === 13 )) &&
                    <tr>
                      <td>Repair Time</td>
                      <td>{ props.activityDetails.activity.repairTime ? `${props.activityDetails.activity.repairTime} minutes` : 'n/a' }</td>
                    </tr>
                }

                <tr><td valign="top">Location</td><td>
                <div>{props.activityDetails.activity.address && props.activityDetails.activity.address.line1}<br/>
                {/* {props.activityDetails.activity.address && props.activityDetails.activity.address.line2}<br/> */}
                {props.activityDetails.activity.address && props.activityDetails.activity.address.city},&nbsp; 
                                       {props.activityDetails.activity.address && props.activityDetails.activity.address.state}&nbsp;
                                       {props.activityDetails.activity.address && props.activityDetails.activity.address.zipCode}<br/>
                {props.activityDetails.activity.address && props.activityDetails.activity.address.country}
                </div></td></tr>
              </tbody>
            </table>
          </Grid>
        </Grid>

      <Grid container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
      >
        <Grid item>
          <Typography variant="p" display="block">Notes</Typography>
        </Grid>
        <Grid item container lg={12}>
          <Typography variant="p" component="p" class="fob-notes-container">{ props.activityDetails.activity.notes }</Typography>
        </Grid>
      </Grid>
    </div>
  );
};
