import React, { useCallback, useEffect } from 'react';
import { PageHeader } from '../common';
import RolesTable from './RolesTable';
import { useSelector } from 'react-redux';
import { useGetRoles } from './redux/hooks';
import { useToggleAdminCommonDialog } from '../admin/redux/toggleAdminCommonDialog';
import { CreateRoleDialog, RoleDetailsDialog } from './index';
import { isEmpty } from 'underscore';


export default function RoleList(props) {
  const {getRoles} = useGetRoles();
  const rolesData = useSelector(state => state.roles.rolesData);
  const {toggleAdminCommonDialog} = useToggleAdminCommonDialog();
  const newRoles = useSelector(state => state.roles.newRoles);

  useEffect(() => {
    getRoles(1, 1000);
  }, [getRoles]);

  useEffect(() => {
    if (!isEmpty(newRoles)) getRoles(1, 1000);
  }, [newRoles, getRoles]);

  const toggleRoleDetailsDialog = useCallback(
    (params) => {
      toggleAdminCommonDialog('RoleDetailsDialog', true, params);
    }, [toggleAdminCommonDialog]);

  return (
    <div className="roles-role-list">
      <PageHeader location={props.location} />

      <RolesTable data={rolesData}
                  history={props.history}
                  toggleRoleDetailsDialog={toggleRoleDetailsDialog}
                  onClickAddBtn={() => {
                    toggleAdminCommonDialog('CreateRoleDialog', true, {});
                  }}
      />

      <RoleDetailsDialog />
      <CreateRoleDialog />
    </div>
  );
};

