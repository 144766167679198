import React, { useState } from 'react';
import {LinearProgress} from '@material-ui/core';
import Dropzone from 'react-dropzone';
import uploadIcon from './../../images/DocumentAdd@3x.png';
import csvIcon from './../../images/csv.png';
import { clone, isEmpty } from 'underscore';
import * as prettySize from 'prettysize';

let initialProgress = {
  progress: 0,
  uploaded: 0,
  totalSize: 0
};


export default function UploadBox(props) {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileProgress, setFileProgress] = useState(initialProgress);

  const activateLoader = (_fileProgress, step, totalSize) => {
    if (_fileProgress.progress >= 100) return;

    setTimeout(() => {
      let fileProgressCopy = clone(_fileProgress);
      fileProgressCopy.progress += 1;
      fileProgressCopy.uploaded += step;
      fileProgressCopy.totalSize = totalSize;
      setFileProgress(fileProgressCopy);
      activateLoader(fileProgressCopy, step, totalSize);
    }, 10);
  };

  const handleOnDrop = files => {
    setLoading(true);
    setFiles(files);

    let file = files[0];
    // let totalFileSizeMb = file.size / Math.pow(1024,2);
    let step = file.size / 100;

    setFileProgress({
      progress: 0,
      uploaded: 0,
      totalSize: file.size,
    });

    activateLoader(fileProgress, step, file.size);
  };

  return (
    <Dropzone onDrop={files => {
      handleOnDrop(files);

      if (props.onDrop) {
        props.onDrop(files);
      }
    }}>
      {({getRootProps, getInputProps}) => (
        <div className="common-upload-box-container">
          <div className="common-upload-box" {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="common-upload-box-text-container">
                  <img src={uploadIcon} alt=""/>
                  <p>Drag and drop or <a href="#/">browse</a> your files</p>
              </div>
          </div>

          <div className="common-upload-box-files">
            <ul>
              {
                files.map(file => {

                  if (loading && !isEmpty(fileProgress)) {
                    return (
                      <li key={file.name} className="common-upload-box-file-item-loading">
                        <img src={csvIcon} alt=""/>
                        <div className="common-upload-box-file-item-progress-container">
                          <LinearProgress variant="determinate" value={props.progress} color="secondary" />
                          <section className="common-upload-box-file-item-progress-section">
                            <span className="uploaded-mbs">{prettySize(fileProgress.uploaded, true)} of {prettySize(fileProgress.totalSize, true)}</span>
                            <span className="uploaded-percentage">{fileProgress.progress >= 100 ? 'Done' : `Uploading... ${fileProgress.progress}%`}</span>
                          </section>
                        </div>
                      </li>
                    );
                  }

                  return (
                    <li key={file.name} className="common-upload-box-file-item">
                        <img src={csvIcon} alt=""/> {file.name}
                    </li>
                  );
                })
              }
            </ul>
          </div>
        </div>
      )}
    </Dropzone>
  );
};

