// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { MyEquipment, EquipmentDetails, AddEquipment } from './';

export default {
  path: 'equipment',
  childRoutes: [
    { path: '', component: MyEquipment },
    { path: 'add', component: AddEquipment, exact: true},
    { path: ':id', component: EquipmentDetails },
  ],
};
