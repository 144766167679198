import React from 'react';
import { Card, CardContent, CardHeader, List, ListItem, ListItemText, ListItemIcon} from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';


const guides = Array(6).fill().map((_, i) => {
  return 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed';
});


export default function UserGuideCard(props) {
  return (
    <Card className="help-user-guide-card">
      <CardHeader subheader="User Guide" />
      <CardContent>
        <List component="nav" aria-label="main mailbox folders">
          {
            guides.map(g => <ListItem button component="a" href="#guide1">
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary={g} />
            </ListItem>)
          }
        </List>
      </CardContent>
    </Card>
  );
};

