import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  EQUIPMENTS_GET_OWNERS_BEGIN,
  EQUIPMENTS_GET_OWNERS_SUCCESS,
  EQUIPMENTS_GET_OWNERS_FAILURE,
  EQUIPMENTS_GET_OWNERS_DISMISS_ERROR,
} from './constants';
import ApiService from "../../common/services/ApiService";

export function getOwners(page=1, limit=1000, order='ASC') {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: EQUIPMENTS_GET_OWNERS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      ApiService.getOwners(page, limit, order).then(
        (res) => {
          dispatch({
            type: EQUIPMENTS_GET_OWNERS_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: EQUIPMENTS_GET_OWNERS_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissGetOwnersError() {
  return {
    type: EQUIPMENTS_GET_OWNERS_DISMISS_ERROR,
  };
}

export function useGetOwners(params) {
  const dispatch = useDispatch();

  const { ownersData, getOwnersPending, getOwnersError } = useSelector(
    state => ({
      ownersData: state.equipments.ownersData,
      getOwnersPending: state.equipments.getOwnersPending,
      getOwnersError: state.equipments.getOwnersError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(getOwners(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetOwnersError());
  }, [dispatch]);

  return {
    ownersData,
    getOwners: boundAction,
    getOwnersPending,
    getOwnersError,
    dismissGetOwnersError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case EQUIPMENTS_GET_OWNERS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getOwnersPending: true,
        getOwnersError: null,
      };

    case EQUIPMENTS_GET_OWNERS_SUCCESS:
      // The request is success
      return {
        ...state,
        getOwnersPending: false,
        getOwnersError: null,
        ownersData: action.data,
      };

    case EQUIPMENTS_GET_OWNERS_FAILURE:
      // The request is failed
      return {
        ...state,
        getOwnersPending: false,
        getOwnersError: action.data.error,
      };

    case EQUIPMENTS_GET_OWNERS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getOwnersError: null,
      };

    default:
      return state;
  }
}
