import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  EQUIPMENTS_UPDATE_WARRANTY_BEGIN,
  EQUIPMENTS_UPDATE_WARRANTY_SUCCESS,
  EQUIPMENTS_UPDATE_WARRANTY_FAILURE,
  EQUIPMENTS_UPDATE_WARRANTY_DISMISS_ERROR,
} from './constants';
import ApiService from '../../common/services/ApiService';

export function updateWarranty(warrantyId, params) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: EQUIPMENTS_UPDATE_WARRANTY_BEGIN,
    });

    if (params.warrantyDateStart && params.warrantyDateStart instanceof Date) {
      params.startDate = params.warrantyDateStart.toISOString();
    }

    if (params.warrantyDateEnd && params.warrantyDateEnd instanceof Date) {
      params.endDate = params.warrantyDateEnd.toISOString();
    }

    const promise = new Promise((resolve, reject) => {
      ApiService.updateWarranty(warrantyId, params).then(
        (res) => {
          dispatch({
            type: EQUIPMENTS_UPDATE_WARRANTY_SUCCESS,
            data: res,
            message: 'Successfully updated warranty',
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: EQUIPMENTS_UPDATE_WARRANTY_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissUpdateWarrantyError() {
  return {
    type: EQUIPMENTS_UPDATE_WARRANTY_DISMISS_ERROR,
  };
}

export function useUpdateWarranty(params) {
  const dispatch = useDispatch();

  const { updateWarrantyPending, updateWarrantyError } = useSelector(
    state => ({
      updateWarrantyPending: state.equipments.updateWarrantyPending,
      updateWarrantyError: state.equipments.updateWarrantyError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(updateWarranty(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissUpdateWarrantyError());
  }, [dispatch]);

  return {
    updateWarranty: boundAction,
    updateWarrantyPending,
    updateWarrantyError,
    dismissUpdateWarrantyError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case EQUIPMENTS_UPDATE_WARRANTY_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        updateWarrantyPending: true,
        updateWarrantyError: null,
      };

    case EQUIPMENTS_UPDATE_WARRANTY_SUCCESS:
      // The request is success
      return {
        ...state,
        updateWarrantyPending: false,
        updateWarrantyError: null,
        updateWarrantyMessage: action.message,
      };

    case EQUIPMENTS_UPDATE_WARRANTY_FAILURE:
      // The request is failed
      return {
        ...state,
        updateWarrantyPending: false,
        updateWarrantyError: action.data.error,
      };

    case EQUIPMENTS_UPDATE_WARRANTY_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        updateWarrantyError: null,
      };

    default:
      return state;
  }
}
