import React, { useEffect, useState, useCallback, useRef } from 'react';
import {BaseTable, LoadingButton} from '../common/index';
import EquipmentsHelper from '../common/services/EquipmentsHelper';
import {
  AppBar,
  Divider,
  Button,
  TableCell,
  TableRow,
  Toolbar,
  Typography,
  TextField,
  TableSortLabel,
  MenuItem,
  Grid,
  Box
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {withFormik} from 'formik';
import {useSearchEquipment} from "../equipments/redux/searchEquipment";
import Swal from "sweetalert2";
import {isEmpty} from 'underscore';
import { useGetAllParts } from '../parts/redux/hooks';
import {useHistory} from "react-router-dom";
import CacheService from "../common/services/CacheService";
import StringHelper from "../common/services/StringHelper";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";


const initialPage = 1,
    initialRowsPerPage = 5,
    initialOrder = 'desc',
    initialOrderColumn = 'Manufacturer',
    initialValues = {
        manufacturerId: '',
        partId: '',
        serialNumber: '',
        latId: '',
        categoryId: '',
        activityTypeId: '', // Status on the UI
    };

const CheckCircleOutlineIconChecked = () => {
  return <CheckCircleOutlineIcon color="secondary" />
};

const CheckCircleOutlineIconUnchecked = () => {
  return <CheckCircleOutlineIcon style={{opacity: 0.15}} />
};

const SearchForm = (props) => {
    const {getAllPartsPending, partsData, getAllParts} = useGetAllParts();
    const {searchEquipmentPending} = useSearchEquipment();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const {
        manufacturersData,
        categoriesData,
        activityTypeData,
        setFormValues,
        resetSearchResult,
        handleSubmit,
        handleChange,
        setFieldValue,
        resetForm,
        values,
        searchClicked
    } = props;

    useEffect(() => {
        if (searchClicked || !values.manufacturerId) return;

        // This will be triggered only on load of page
        getAllParts(values.manufacturerId);
    }, [getAllParts, values.manufacturerId, searchClicked]);

    const handleManufacturerIdChange = (e) => {
      const manufacturerId = e.target.value;
      setFieldValue("manufacturerId", manufacturerId);
      setFieldValue("partId", initialValues.partId);
      getAllParts(manufacturerId);
    };

    const handleCategoryIdChange = (e) => {
        setFieldValue("categoryId", e.target.value);
    };

    const handleActivityTypeChange = (e) => {
        setFieldValue("activityTypeId", e.target.value);
    };

    return (
        <form onSubmit={handleSubmit} className="search-input-form">
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={{'justify-content': 'space-between'}}
            >

            <Grid container
                  item
                  direction={isMobile ? 'column' : 'row'}
                  xs={12} sm={12} md={9}
                  justifyContent="center"
                  alignItems="stretch"
                  spacing={1}>

                <Grid item xs={12} sm={12} md={3}>
                    <TextField
                        id="manufacturerId"
                        name="manufacturerId"
                        select={(manufacturersData && manufacturersData.rows && manufacturersData.rows.length) ? true : false}
                        label="Manufacturer"
                        className="fob-filled-input-with-outside-label search-input-ext manufacturer-search-select"
                        value={(manufacturersData && manufacturersData.rows && manufacturersData.rows.length) ? values.manufacturerId : ""}
                        onChange={(e) => { handleManufacturerIdChange(e); }}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            disableUnderline: true,
                        }}
                        children={manufacturersData && manufacturersData.rows.length && [<MenuItem key="0" value="">Select Manufacturer</MenuItem>].concat(manufacturersData.rows.map((item) => (
                            <MenuItem key={item.manufacturerId} value={item.manufacturerId}>
                                {item.description}
                            </MenuItem>
                        )))}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                    <TextField
                        disabled={(values.manufacturerId && partsData.length && !getAllPartsPending) ? false : true}
                        id="partId"
                        name="partId"
                        select={(partsData && partsData.length) ? true : false}
                        label="Asset Number"
                        className="fob-filled-input-with-outside-label search-input-ext part-search-select"
                        value={(partsData && partsData.length) ? values.partId : ""}
                        onChange={handleChange}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            disableUnderline: true,
                        }}
                        children={partsData && partsData.length && [<MenuItem key="0" value="">Select Asset Type</MenuItem>].concat(partsData.map((item) => (
                            <MenuItem key={item.partId} value={item.partId}>
                                {item.description}
                            </MenuItem>
                        )))}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                    <TextField
                        id="serialNumber"
                        name="serialNumber"
                        label="Serial Number"
                        className="search-input-ext"
                        value={values.serialNumber}
                        onChange={handleChange}
                        fullWidth
                        key="serial"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            disableUnderline: true,
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                    <TextField
                        id="latId"
                        name="latId"
                        label="LATID"
                        className="search-input-ext"
                        value={values.latId}
                        onChange={handleChange}
                        fullWidth
                        key="latId"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            disableUnderline: true,
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={3}>
                    <TextField
                        id="categoryId"
                        name="categoryId"
                        label="Category"
                        select={(Array.isArray(categoriesData) && categoriesData.length) ? true : false}
                        className="fob-filled-input-with-outside-label search-input-ext manufacturer-search-select"
                        value={(Array.isArray(categoriesData) && categoriesData.length) ? values.categoryId : ""}
                        onChange={(e) => handleCategoryIdChange(e)}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            disableUnderline: true,
                        }}
                        children={
                            Array.isArray(categoriesData)
                            && categoriesData.length
                            && [<MenuItem key="0" value="">Select Category</MenuItem>].concat(categoriesData.map((item) => (
                                <MenuItem key={item.categoryID} value={item.categoryID}>
                                    {item.description}
                                </MenuItem>
                            )))
                        }
                    />
                </Grid>

                <Grid item xs={12} sm={12} md={3}>
                    <TextField
                        id="activityTypeId"
                        name="activityTypeId"
                        label="Status"
                        select={(Array.isArray(activityTypeData) && activityTypeData.length) ? true : false}
                        className="fob-filled-input-with-outside-label search-input-ext manufacturer-search-select"
                        value={(Array.isArray(activityTypeData) && activityTypeData.length) ? values.activityTypeId : ""}
                        onChange={handleActivityTypeChange}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            disableUnderline: true,
                        }}
                        children={
                            Array.isArray(activityTypeData)
                            && activityTypeData.length
                            && [<MenuItem key="0" value="">Select Status</MenuItem>].concat(activityTypeData.map((item) => (
                                <MenuItem key={item.activityTypeId} value={item.activityTypeId}>
                                    {item.description}
                                </MenuItem>
                            )))
                        }
                    />
                </Grid>
            </Grid>

            {/*
                BUTTON ACTIONS CONTAINER
            */}
                <Grid container
                      direction="row"
                      xs={12} sm={12} md={3}
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}>
                    {
                        searchEquipmentPending === true
                        ?  <Grid item xs={12} sm={12} md={12}>
                                <Box pl={{xs: 0, sm: 0, md: 1}}
                                     pt={{xs: 2, sm: 0, md: 0}}>
                                    <LoadingButton size="medium" fullWidth />
                                </Box>
                            </Grid>
                        : (<>
                            <Grid item xs={12} sm={12} md={6}>
                                <Box pl={{xs: 0, sm: 0, md: 1}}
                                     pt={{xs: 2, sm: 0, md: 0}}>
                                    <Button variant="contained"
                                            color="secondary"
                                            type="submit"
                                            fullWidth>
                                        Search
                                    </Button>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6}>
                                <Box pr={{xs: 0, sm: 0, md: 1}}>
                                    {
                                        searchEquipmentPending === true
                                            ? null
                                            : <Button variant="contained"
                                                      color="primary"
                                                      disabled={searchEquipmentPending === true}
                                                      fullWidth
                                                      onClick={() => {
                                                          CacheService.resetSearchPageSavedFilterCriteria();
                                                          setFormValues(initialValues);
                                                          resetSearchResult();
                                                          resetForm();
                                                      }}>
                                                Clear
                                            </Button>
                                    }
                                </Box>
                            </Grid>
                        </>)
                    }
                </Grid>
            </Grid>
        </form>
    );
};

const Header = (props) => {
    const {
        SForm,
    } = props;

  const user = props.user ? props.user : { owner: {} }

  return (
      <AppBar position="relative" color="default" className="common-base-table-header">
        <Toolbar>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid container item sm={12} md={2} className="user-profile-space-container">
              <span className="user-profile-space">
                <Typography component="p">{user.email}</Typography>
                <Typography component="p">{user.owner && user.owner.company}</Typography>
              </span>
            </Grid>
            {/* <Divider orientation="vertical" flexItem /> */}
            <Grid container item sm={12} md={10}>
              {SForm}
            </Grid>  
          </Grid>
        </Toolbar>
      </AppBar>
  )
};

const renderTableBody = (rows, page, rowsPerPage, remote, history) => {
  const rowsOnPage = remote ? rows : rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  return rowsOnPage.map((row, idx) => (
    <TableRow key={idx} onClick={() => {
        history.push(`/search/equipment/${row.assetId}`)
    }}>
      <TableCell align="center">{row.manufacturer.description}</TableCell>
      <TableCell align="center">{row.serialNumber}</TableCell>
      <TableCell align="center">{row.latId}</TableCell>
      <TableCell align="center">{isEmpty(row.warranty) ? <CheckCircleOutlineIconUnchecked /> : <CheckCircleOutlineIconChecked/>}</TableCell>
      <TableCell align="center">{Array.isArray(row.serviceAgreements) && row.serviceAgreements.length > 0 ? <CheckCircleOutlineIconChecked /> : <CheckCircleOutlineIconUnchecked />}</TableCell>
      <TableCell align="center">{row.part.description}</TableCell>
      <TableCell align="center">{isEmpty(row.address) ? null : `${row.address.state}, ${row.address.city}` }</TableCell>
    </TableRow>
  ));
};


export default function SearchResultTable(props) {
  const {manufacturersData, categoriesData, activityTypeData} = props;
  const criteria = CacheService.getSearchPageSavedFilterCriteria();
  const {searchEquipment, searchResult, resetSearchResult} = useSearchEquipment();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [searchClicked, setSearchClicked] = useState(false);
  let _initialPage = isEmpty(criteria) ? initialPage : criteria.page;
  const [page, setPage] = useState(_initialPage);
  let _initialRowsPerPage = isEmpty(criteria) ? initialRowsPerPage : criteria.rowsPerPage;
  const [rowsPerPage, setRowsPerPage] = useState(_initialRowsPerPage);
  const [orderColumn, setOrderColumn] = useState(isEmpty(criteria) ? initialOrderColumn : criteria.sort.orderColumn);
  const [order, setOrder] = useState(isEmpty(criteria) ? initialOrder : criteria.sort.order);
  const [formValues, setFormValues] = useState(initialValues);
  let history = useHistory();

  const MyForm = withFormik({
        mapPropsToValues: () => (!isEmpty(formValues) ? formValues : initialValues),
        enableReinitialize: false,
        handleSubmit: values => {
            if (!values.manufacturerId && !values.partId && !values.serialNumber && !values.latId && !values.categoryId && !values.activityTypeId) {
                Swal.fire({
                    icon: 'warning',
                    text: 'Please enter any filter value',
                });
                return;
            }
            setSearchClicked(true);
            let sortData = order && orderColumn ? {order: order, orderColumn: orderColumn} : {};
            let urlParam = null;
            var queryParamObj = StringHelper.getQueryParams(window.location.search)
            if(queryParamObj && queryParamObj.url) {
              urlParam = queryParamObj.url;
            }
            CacheService.saveSearchPageFilterCriteria(values, {sort: sortData, page: page, rowsPerPage: rowsPerPage, urlParam: urlParam});
            searchEquipment(values, initialPage, initialRowsPerPage, order, orderColumn, urlParam);
            setFormValues(values);
        }
  })(SearchForm);

  useEffect(() => {
    const criteria = CacheService.getSearchPageSavedFilterCriteria();

    if (isEmpty(criteria)) return;

    setFormValues(criteria.values);
    // if(criteria.urlParam) {
    //   window.location.search = `/?url=${criteria.urlParam}`;
    // }
    searchEquipment(criteria.values, criteria.page, criteria.rowsPerPage, criteria.sort.order, criteria.sort.orderColumn, criteria.urlParam);
  }, [searchEquipment]);

  useEffect(() => {
    setColumns(EquipmentsHelper.getMyEquipmentTableColumns());
  }, []);

  const handleChangePage = useCallback(
        (_page, fetchData=true) => {
            let _newPage = _page + 1;
            CacheService.saveSearchPageFilterCriteria(formValues, {
                sort: {order: order, orderColumn: orderColumn},
                page: _newPage,
                rowsPerPage: rowsPerPage
            });
            if (fetchData) searchEquipment(formValues, _newPage, rowsPerPage, order, orderColumn);
            setPage(_newPage);
        }, [searchEquipment, formValues, rowsPerPage, order, orderColumn]);

  useEffect(() => {
    if (!searchResult) return;

      setRows(searchResult.rows);

      if (searchResult.count <= rowsPerPage && page > 1) {
          handleChangePage(0, false);
      }
  }, [searchResult, rowsPerPage, page, handleChangePage]);

  const handleRowsPerPageChanged = useCallback(
    (_rowsPerPage) => {
      CacheService.saveSearchPageFilterCriteria(formValues, {sort: {order: order, orderColumn: orderColumn}, page: initialPage, rowsPerPage: _rowsPerPage});
      searchEquipment(formValues, initialPage, _rowsPerPage, order, orderColumn);
      setRowsPerPage(_rowsPerPage);
      setPage(initialPage);
    }, [searchEquipment, formValues, order, orderColumn]);

  const handleOrderChanged = useCallback(
    (order, orderColumn) => {
      const newOrder = (order === 'desc') ? 'asc' : 'desc';
      CacheService.saveSearchPageFilterCriteria(formValues, {sort: {order: newOrder, orderColumn: orderColumn}, page: page, rowsPerPage: rowsPerPage});
      searchEquipment(formValues, page, rowsPerPage, newOrder, orderColumn);
      setOrder(newOrder);
      setOrderColumn(orderColumn);
    }, [searchEquipment, formValues, page, rowsPerPage]);

  const header = () => (<Header user={props.user}
                                SForm={<MyForm manufacturersData={manufacturersData}
                                               categoriesData={categoriesData}
                                               activityTypeData={activityTypeData}
                                               setFormValues={setFormValues}
                                               resetSearchResult={resetSearchResult}
                                               searchClicked={searchClicked}/>}
                                searchEquipment={searchEquipment}
  />);

  const renderTableColumns = useCallback(
    () => {
      if (!columns.length) return [];

      let cols = columns.map((col, idx) => {
        return (
          <TableCell
            key={idx}
            align="center">
              { col.orderColumn ?
                <TableSortLabel
                  className={col.orderColumn === orderColumn ? "selected-column" : "not-selected-column"}
                  direction={col.orderColumn === orderColumn ? order : initialOrder }
                  onClick={() => handleOrderChanged(col.orderColumn === orderColumn ? order : initialOrder, col.orderColumn)}
                >
                  {col.label}
                </TableSortLabel>
                :
                  col.label
              }
          </TableCell>
        );
      });

      return cols;
    }, [columns, handleOrderChanged, order, orderColumn]);

  return (
    <div className="search-search-result-table">
      <BaseTable initialPage={page}
                remote
                headerComponent={header}
                initialRowsPerPage={_initialRowsPerPage}
                columns={columns}
                rows={rows}
                renderTableColumns={renderTableColumns}
                renderTableBody={(rows, page, rowsPerPage, remote) => {
                    return renderTableBody(rows, page, rowsPerPage, true, history);
                }}
                changePage={handleChangePage}
                rowsPerPageChanged={handleRowsPerPageChanged}
                showResultInfo={true}
                count={searchResult ? searchResult.count : null} />
    </div>
  );
};

