import React, {useEffect} from 'react';
import {Grid, TextField} from '@material-ui/core';
import {findWhere} from 'underscore';
import {useGetStatesOfCountry} from "../common/redux/getStatesOfCountry";
import {MySelect} from '../common';


export default function OwnerDetailsForm(props) {
    const {getStatesOfCountry, states} = useGetStatesOfCountry();

    useEffect(() => {
        if (!props.formik.values.address.country) return;

        let country = findWhere(props.countries, {name: props.formik.values.address.country});

        if (!country) return;

        getStatesOfCountry(country.id);
    }, [props.formik.values.address.country, getStatesOfCountry, props.countries]);

    return (
        <div className="equipments-owner-details-form">
            <Grid container
                  direction="column"
                  spacing={2}>

                <Grid item container md={12} spacing={2}>
                    <Grid item container>
                        <TextField
                            id="company"
                            label="Company"
                            InputProps={{disableUnderline: true}}
                            InputLabelProps={{shrink: true}}
                            className="fob-filled-input-with-outside-label"
                            value={props.formik.values.company}
                            onChange={props.formik.handleChange}
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <Grid item container md={12} spacing={2} direction="row">
                    <Grid item container md={6}>
                        <TextField
                            id="address.line1"
                            label="Address Line 1"
                            InputProps={{disableUnderline: true}}
                            InputLabelProps={{shrink: true}}
                            className="fob-filled-input-with-outside-label"
                            value={props.formik.values.address.line1}
                            onChange={props.formik.handleChange}
                            fullWidth
                        />
                    </Grid>

                    <Grid item container md={6}>
                        <TextField
                            fullWidth
                            id="address.line2"
                            label="Address Line 2"
                            InputProps={{disableUnderline: true}}
                            InputLabelProps={{shrink: true}}
                            className="fob-filled-input-with-outside-label"
                            value={props.formik.values.address.line2}
                            onChange={props.formik.handleChange}
                        />
                    </Grid>
                </Grid>

                <Grid item container md={12} spacing={2}>
                    <Grid item md={6}>
                        <TextField
                            id="address.city"
                            label="City"
                            InputProps={{disableUnderline: true}}
                            InputLabelProps={{shrink: true}}
                            className="fob-filled-input-with-outside-label"
                            value={props.formik.values.address.city}
                            onChange={props.formik.handleChange}
                            fullWidth
                        />
                    </Grid>

                    <Grid item md={6}>
                        <MySelect
                            id="address.state"
                            name="address.state"
                            label="State"
                            color="secondary"
                            placeholder="Select State"
                            value={props.formik.values.address.state}
                            onChange={(_, option) => {
                                props.formik.setFieldValue('address.state', option && option.name ? option.name : '');
                            }}
                            getOptionLabel={(option) => typeof option === 'string' ? option : option.name}
                            renderOption={(option) => option.name}
                            options={[{name: 'Select State', isLabel: true}].concat(states)}
                        />
                    </Grid>
                </Grid>

                <Grid item container md={12} spacing={2}>
                    <Grid item container md={6}>
                        <TextField
                            id="address.zipCode"
                            label="Zip"
                            InputProps={{disableUnderline: true}}
                            InputLabelProps={{shrink: true}}
                            className="fob-filled-input-with-outside-label"
                            value={props.formik.values.address.zipCode}
                            onChange={props.formik.handleChange}
                            fullWidth
                        />
                    </Grid>

                    <Grid item container md={6}>
                        <MySelect
                            id="address.country"
                            name="address.country"
                            label="Country"
                            color="secondary"
                            placeholder="Select Country"
                            value={props.formik.values.address.country}
                            onChange={(_, option) => {
                                props.formik.setFieldValue('address.country', option && option.name ? option.name : '');
                                props.formik.setFieldValue('address.state', '');
                            }}
                            getOptionLabel={(option) => typeof option === 'string' ? option : option.name}
                            renderOption={(option) => option.name}
                            options={[{name: 'Select Country', isLabel: true}].concat(props.countries)}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

