import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  COMMON_GET_STATES_OF_COUNTRY_BEGIN,
  COMMON_GET_STATES_OF_COUNTRY_SUCCESS,
  COMMON_GET_STATES_OF_COUNTRY_FAILURE,
  COMMON_GET_STATES_OF_COUNTRY_DISMISS_ERROR,
} from './constants';
import {getCountryStates} from "../places/state";

export function getStatesOfCountry(countryId) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: COMMON_GET_STATES_OF_COUNTRY_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const states = getCountryStates(countryId).filter(item => !item.isoCode.includes('UM-')).map(item => ({
        id: item.isoCode,
        name: item.isoCode,
        country_id: item.countryCode,
      }));
      dispatch({
        type: COMMON_GET_STATES_OF_COUNTRY_SUCCESS,
        states: states,
      });
      resolve(states);
    });

    return promise;
  };
}

export function dismissGetStatesOfCountryError() {
  return {
    type: COMMON_GET_STATES_OF_COUNTRY_DISMISS_ERROR,
  };
}

export function useGetStatesOfCountry(params) {
  const dispatch = useDispatch();

  const { getStatesOfCountryPending, getStatesOfCountryError, states } = useSelector(
    state => ({
      getStatesOfCountryPending: state.common.getStatesOfCountryPending,
      getStatesOfCountryError: state.common.getStatesOfCountryError,
      states: state.common.states,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(getStatesOfCountry(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetStatesOfCountryError());
  }, [dispatch]);

  return {
    states: states,
    getStatesOfCountry: boundAction,
    getStatesOfCountryPending,
    getStatesOfCountryError,
    dismissGetStatesOfCountryError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_GET_STATES_OF_COUNTRY_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getStatesOfCountryPending: true,
        getStatesOfCountryError: null,
      };

    case COMMON_GET_STATES_OF_COUNTRY_SUCCESS:
      // The request is success
      return {
        ...state,
        getStatesOfCountryPending: false,
        getStatesOfCountryError: null,
        states: action.states,
      };

    case COMMON_GET_STATES_OF_COUNTRY_FAILURE:
      // The request is failed
      return {
        ...state,
        getStatesOfCountryPending: false,
        getStatesOfCountryError: action.data.error,
      };

    case COMMON_GET_STATES_OF_COUNTRY_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getStatesOfCountryError: null,
      };

    default:
      return state;
  }
}
