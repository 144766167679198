import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { ManufacturerDetailsForm } from './index';
import { useToggleAddManufacturerDialog } from './redux/hooks';
import LoadingButton from "../common/LoadingButton";


export default function AddManufacturerDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {isAddManufacturerDialogVisible, toggleAddManufacturerDialog} = useToggleAddManufacturerDialog();

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isAddManufacturerDialogVisible}
      className="manufacturers-add-manufacturer-dialog fob-details-dialog">
      <form onSubmit={props.formik.handleSubmit}>
        <DialogTitle>
          <Toolbar>
            <Typography variant="h5" display="inline">Add Manufacturer</Typography>
            <span style={{flexGrow: 1}}></span>
            <IconButton
              edge="end"
              color="inherit" aria-label="menu"
              onClick={toggleAddManufacturerDialog.bind(this, false)}>
              <HighlightOffIcon />
            </IconButton>
          </Toolbar>
          <Divider />
        </DialogTitle>

        <DialogContent>
          <ManufacturerDetailsForm formik={props.formik} />
        </DialogContent>

        <DialogActions>
          {
              props.formik.isSubmitting
              ? <LoadingButton />
              : <Button variant="contained"
                        disableElevation
                        color="secondary"
                        type="submit"
                        className="rounded-btn"
                  >
                    Save
               </Button>
          }

          <Button variant="contained"
                  disableElevation
                  disabled={props.formik.isSubmitting}
                  onClick={toggleAddManufacturerDialog.bind(this, false)}
                  className="rounded-btn dark-btn"
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

