import React from 'react';
import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Toolbar,
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useSelector} from 'react-redux';
import {useToggleAdminCommonDialog} from '../admin/redux/toggleAdminCommonDialog';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {isBoolean} from 'underscore';


export default function ManufacturersInformationDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const toggleDialog = useSelector(state => state.admin.toggleDialog);
  const {toggleAdminCommonDialog} = useToggleAdminCommonDialog();

  if (!props.assetManufacturerInfo.manufacturerInfo) return null;

  return (
    <Dialog
        fullScreen={fullScreen}
        open={toggleDialog.name === 'ManufacturersInformationDialog' && toggleDialog.flag === true}
        className="manufacturers-manufacturers-information-dialog">

        <DialogTitle>
            <Toolbar>
                <Typography variant="h5" display="inline">Manufacturer Information</Typography>
                <span style={{flexGrow: 1}}></span>
                <IconButton
                    edge="end"
                    color="inherit" aria-label="menu"
                    onClick={toggleAdminCommonDialog.bind(this, 'ManufacturersInformationDialog', false, {})}>
                    <HighlightOffIcon/>
                </IconButton>
            </Toolbar>
            <Divider/>
        </DialogTitle>

        <DialogContent>
            <Container maxWidth="sm">
                <Grid container
                      lg={12}
                      direction="row"
                      justify="center"
                      alignItems="center">
                    <Grid item lg={6}>
                        {
                            props.assetManufacturerInfo.manufacturerInfo.ImageUrl
                            ? <img className="manufacturers-info-img" src={props.assetManufacturerInfo.manufacturerInfo.ImageUrl} />
                            : null
                        }
                    </Grid>

                    <Grid item lg={6}>
                        <TableContainer>
                        <Table size="small">
                            <TableBody>
                                <TableRow className="tr-two-col-cell">
                                    <TableCell>Production Date:</TableCell>
                                    <TableCell>
                                        {
                                            props.assetManufacturerInfo.manufacturerInfo.ProductionDate
                                            ? props.assetManufacturerInfo.manufacturerInfo.ProductionDate
                                            : ''
                                        }
                                    </TableCell>
                                </TableRow>
                                <TableRow className="tr-two-col-cell">
                                    <TableCell>Ship Date:</TableCell>
                                    <TableCell>
                                        {
                                            props.assetManufacturerInfo.manufacturerInfo.ShipDate
                                            ? props.assetManufacturerInfo.manufacturerInfo.ShipDate
                                            : ''
                                        }
                                    </TableCell>
                                </TableRow>
                                <TableRow className="tr-two-col-cell">
                                    <TableCell>Warranty Void:</TableCell>
                                    <TableCell>
                                        {
                                            isBoolean(props.assetManufacturerInfo.manufacturerInfo.WarrantyVoid)
                                            ? (props.assetManufacturerInfo.manufacturerInfo.WarrantyVoid ? 'Yes' : 'No')
                                            : ''
                                        }
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colspan="2">Specification Sheet</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colspan="2" className="td-single-row-val">
                                        {
                                            props.assetManufacturerInfo.manufacturerInfo.SpecSheetFileUrl
                                            && <a href={props.assetManufacturerInfo.manufacturerInfo.SpecSheetFileUrl}
                                                  target="_blank"
                                                  download>{props.assetManufacturerInfo.manufacturerInfo.SpecSheetFileUrl}</a>
                                        }
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colspan="2">Service Manual</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colspan="2" className="td-single-row-val">
                                        {
                                            props.assetManufacturerInfo.manufacturerInfo.ServiceManualFileUrl
                                            && <a href={props.assetManufacturerInfo.manufacturerInfo.ServiceManualFileUrl}
                                                  target="_blank"
                                                  download>{props.assetManufacturerInfo.manufacturerInfo.ServiceManualFileUrl}</a>
                                        }
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Container>
        </DialogContent>

        <DialogActions>
            <Button variant="contained"
                    disableElevation
                    onClick={() => {
                        toggleAdminCommonDialog('CreateRoleDialog', false, {});
                    }}
                    className="rounded-btn dark-btn"
            >
                Close
            </Button>
        </DialogActions>
    </Dialog>
  );
};

