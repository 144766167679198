import React from 'react';
import TextField from '@material-ui/core/TextField';


export default function AuthTextField(props) {
  return (
    <TextField
      className="authentication-auth-text-field"
      {...props}
    />
  );
};

