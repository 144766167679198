import React from 'react';
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  DialogActions,
  Button,
  Toolbar,
  IconButton,
  Typography,
  Grid,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useToggleWarrantyDialog } from './redux/toggleWarrantyDialog';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import StringHelper from "../common/services/StringHelper";


const WarrantyContent = (props) => {
  return (
    <Grid container
          direction="row"
          justify="center"
          alignItems="center">

      <Grid item xs={12} md={5}>
        <table>
          <tbody>
            <tr>
              <td>
                <Typography variant="p" display="block" color="secondary">{props.user.email}</Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant="p" display="block" color="secondary">{props.user.owner && props.user.owner.company}</Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </Grid>

      <Grid item xs={12} md={7}>
        <table>
          <tbody>
          <tr><td>Manufacturer</td><td>{props.equipmentDetails.manufacturer.description}</td></tr>
          <tr><td>Serial Number</td><td>{props.equipmentDetails.serialNumber}</td></tr>
          <tr><td>Description</td><td>{props.equipmentDetails.part.description}</td></tr>
          <tr><td>Owner</td><td>{props.equipmentDetails.owner.company}</td></tr>
          <tr>
            <td>Date of Install</td>
            <td>{StringHelper.formatDisplayDate(props.equipmentDetails.dateOfInstall)}</td>
          </tr>
          <tr><td>Location</td><td>{StringHelper.formatAddress(props.equipmentDetails.address)}</td></tr>
          <tr>
            <td>Warranty Date Start</td>
            <td>{StringHelper.formatDisplayDate(props.equipmentDetails.warranties[0].startDate)}</td>
          </tr>
          <tr>
            <td>Warranty Date End</td>
            <td>{StringHelper.formatDisplayDate(props.equipmentDetails.warranties[0].endDate)}</td>
          </tr>
          </tbody>
        </table>
      </Grid>
    </Grid>
  )
};


export default function WarrantyDetailsDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {toggleWarrantyDialog} = useToggleWarrantyDialog();
  const isWarrantyDialogVisible = useSelector(state => state.equipments.isWarrantyDialogVisible);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isWarrantyDialogVisible}
      className="equipments-warranty-details-dialog fob-details-dialog"
    >
          <DialogTitle>
            <Toolbar>
              <Typography variant="h5" display="inline">Warranty Information</Typography>

              <span style={{flexGrow: 1}}></span>

              <IconButton
                edge="end"
                color="inherit" aria-label="menu"
                onClick={toggleWarrantyDialog.bind(this, false)}>
                <HighlightOffIcon />
              </IconButton>
            </Toolbar>

            <Divider />

          </DialogTitle>

          <DialogContent>
            <WarrantyContent equipmentDetails={props.equipmentDetails} user={props.user} />
          </DialogContent>

          <DialogActions>
            <Button variant="contained"
                    disableElevation
                    onClick={toggleWarrantyDialog.bind(this, false)}
                    className="rounded-btn dark-btn"
            >
              Close
            </Button>
          </DialogActions>
    </Dialog>
  );
};
