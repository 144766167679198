// This is the root reducer of the feature. It is used for:
//   1. Load reducers from each action in the feature and process them one by one.
//      Note that this part of code is mainly maintained by Rekit, you usually don't need to edit them.
//   2. Write cross-topic reducers. If a reducer is not bound to some specific action.
//      Then it could be written here.
// Learn more from the introduction of this approach:
// https://medium.com/@nate_wang/a-new-approach-for-managing-redux-actions-91c26ce8b5da.

import initialState from './initialState';
import { reducer as getMyEquipmentsReducer } from './getMyEquipments';
import { reducer as getEquipmentDetailsReducer } from './getEquipmentDetails';
import { reducer as toggleWarrantyDialogReducer } from './toggleWarrantyDialog';
import { reducer as toggleServiceAgreementDialogReducer } from './toggleServiceAgreementDialog';
import { reducer as toggleActivityDetailsDialogReducer } from './toggleActivityDetailsDialog';
import { reducer as updateWarrantyReducer } from './updateWarranty';
import { reducer as getActivityDetailsReducer } from './getActivityDetails';
import { reducer as updateServiceAgreementReducer } from './updateServiceAgreement';
import { reducer as createEquipmentReducer } from './createEquipment';
import { reducer as createWarrantyReducer } from './createWarranty';
import { reducer as createServiceAgreementReducer } from './createServiceAgreement';
import { reducer as searchEquipmentReducer } from './searchEquipment';
import { reducer as searchEquipmentFromQRCodeReducer } from './searchEquipmentFromQRCode';
import { reducer as searchEquipmentFromLatReducer } from './searchEquipmentFromLat';
import { reducer as toggleServiceAgreementListReducer } from './toggleServiceAgreementList';
import { reducer as getOwnersReducer } from './getOwners';
import { reducer as getActivityTypesReducer } from './getActivityTypes';
import { reducer as getShippersReducer } from './getShippers';
import { reducer as saveActivityReducer } from './saveActivity';
import { reducer as updateEquipmentReducer } from './updateEquipment';
import { reducer as toggleAddOwnerDialogReducer } from './toggleAddOwnerDialog';
import { reducer as saveOwnerReducer } from './saveOwner';
import { reducer as getAssetManufacturerInfoReducer } from './getAssetManufacturerInfo';
import { reducer as getAssetActivitiesReducer } from './getAssetActivities';
import { reducer as toggleActivityImageDialogReducer } from './toggleActivityImageDialog';
import { reducer as getAssetsAddressByUserReducer } from './getAssetsAddressByUser';

const reducers = [
  getMyEquipmentsReducer,
  getEquipmentDetailsReducer,
  toggleWarrantyDialogReducer,
  toggleServiceAgreementDialogReducer,
  toggleActivityDetailsDialogReducer,
  updateWarrantyReducer,
  getActivityDetailsReducer,
  updateServiceAgreementReducer,
  createEquipmentReducer,
  updateEquipmentReducer,
  createWarrantyReducer,
  createServiceAgreementReducer,
  searchEquipmentReducer,
  searchEquipmentFromQRCodeReducer,
  searchEquipmentFromLatReducer,
  toggleServiceAgreementListReducer,
  getOwnersReducer,
  getActivityTypesReducer,
  getShippersReducer,
  saveActivityReducer,
  toggleAddOwnerDialogReducer,
  saveOwnerReducer,
  getAssetManufacturerInfoReducer,
  getAssetActivitiesReducer,
  toggleActivityImageDialogReducer,
  getAssetsAddressByUserReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
