import React, { useEffect, createRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import appStyle from "./../../styles/jss/app-styles";
import { TopNav, SideNav, Copyright } from './../common/index';
import { Container, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import AuthService from '../authentication/services/AuthService';
import AuthContext from '../authentication/AuthContext';
import { isEmpty } from 'underscore';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CacheService from "../common/services/CacheService";
import StringHelper from "../common/services/StringHelper";
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {CONFIG} from "../../config";
import parsePhoneNumber from 'libphonenumber-js'
import * as Yup from 'yup';
import {useSearchEquipmentFromQRCode} from "../equipments/redux/searchEquipmentFromQRCode";


window.hellothere = function() {
  alert('hello there at '+Date.now());
};

Yup.addMethod(Yup.string, 'phone', function () {
    return this.test({
        name: 'phone',
        message: `Phone number is invalid`,
        test: function (value) {
            if (!value) return this.resolve();
            const phoneNumber = parsePhoneNumber(value, 'US');
            if (!phoneNumber) return false;
            let isValid = phoneNumber.isValid();
            return isValid;
        }
    });
});

const useStyles = makeStyles(appStyle);

const unprotectedRoutes = ['/', '/auth/signin', '/auth/register', '/logout'];

const fullScreenRoutes = [].concat(unprotectedRoutes);

const HOME_PAGE = '/equipment';
const LOGIN_PAGE = '/auth/signin';

export default function App({children, history, location}) {
  const {searchEquipmentFromQRCodePending, searchResultFromQRCode, searchEquipmentFromQRCodeError, dismissSearchEquipmentFromQRCodeError} = useSearchEquipmentFromQRCode();
  const pathName = location.pathname;
  const search = location.search;
  const queryParamObj = StringHelper.getQueryParams(location.search);

  const isFullScreenRoute = fullScreenRoutes.includes(pathName);
  const drawSidenav = useSelector(state => state.common.drawSidenav);
  const userProfile = useSelector(state => state.profile.userProfile);

  // styles
  const classes = useStyles();
  const isSm = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const sideNavClassClosed = drawSidenav && !isSm ? '' : classes.mainPanelExpanded;
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = createRef();

  // useEffect(() => {
  //   history.listen((location, action) => {
  //     // location is an object like window.location
  //     console.log('history loc changed ', action, location.pathname, location.state)
  //   });
  // }, [history]);

  useEffect(() => {
    // console.log('location changed: ',pathName);

    if(AuthService.checkIsUserAuthenticated()) {
      // clear myequipment cached table info if page is non equipment page
      if (!pathName.includes('equipment')) {
        CacheService.resetMyEquipmentTableInfo();
      }

      if (unprotectedRoutes.includes(pathName)) {
        if(queryParamObj.redirect) {
          history.replace(queryParamObj.redirect);
        } else {
          history.replace(HOME_PAGE);
        }
      }

      if(!searchEquipmentFromQRCodePending && searchResultFromQRCode && searchResultFromQRCode.assetId) {
        window.location.pathname = `/search/equipment/${searchResultFromQRCode.assetId}`;
      }
    } else {
      if (pathName==='/' || pathName==='') {
        history.replace(LOGIN_PAGE);
      } else if(!unprotectedRoutes.includes(pathName)) {
        if(pathName.includes('/search')) {
          if(pathName.includes('/search/lat/')) {
            history.replace(`${LOGIN_PAGE}?redirect=${pathName}`);
          } else {
            history.replace(`${LOGIN_PAGE}?redirect=/search`);  
          }
        } else {
          history.replace(`${LOGIN_PAGE}?redirect=${pathName}`);
        }
      }
    }
  }, [history, pathName, search, queryParamObj, searchEquipmentFromQRCodePending, searchResultFromQRCode]);

  let authUser = isEmpty(userProfile) ? AuthService.getAuthUser() : userProfile;

  if(searchEquipmentFromQRCodeError) {
    // eslint-disable-next-line no-restricted-globals
    let confirmed = confirm("Serial Number not found within the system. Please request the equipment owner to add this asset to the Blockchain FOB - Lifecycle Asset Tracker.");
    if(confirmed === true || confirmed === false) {
      dismissSearchEquipmentFromQRCodeError();
    }
  }

  return (
    <div className={classes.wrapper}>
      { searchEquipmentFromQRCodePending && 
        <div className="loading-overlay page-content-loading">
            <CircularProgress color="primary" />
          </div>
      }
      { isFullScreenRoute
        ?
            <GoogleReCaptchaProvider reCaptchaKey={CONFIG.GOOGLE_RECAPTCHA_SITEKEY}
                                     useRecaptchaNet
                                     render="explicit">
              <Container maxWidth="lg" className={classes.fullscreenPanel}>
                {children}
              </Container>
            </GoogleReCaptchaProvider>
            :
            <AuthContext.Provider value={authUser}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <div>
                  <TopNav history={history} />
                  <div className={`${classes.mainPanel} ${sideNavClassClosed}`} ref={mainPanel}>
                    <SideNav history={history} />
                    <div class={!isSm ? "main-content-wrapper" : "main-content-wrapper main-content-wrapper-mobile"}>
                      {children}
                    </div>
                    <Copyright />
                  </div>
                </div>
              </MuiPickersUtilsProvider>
            </AuthContext.Provider>
      }
    </div>
  );
}
