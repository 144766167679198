import React, { useEffect } from 'react';
import { PageHeader } from '../common';
import {
  Card, CircularProgress,
} from '@material-ui/core';
import { SearchResultTable } from './index';
import { useGetManufacturers } from '../manufacturers/redux/hooks';
import AuthService from "../authentication/services/AuthService";
import {useSearchEquipmentFromLat} from "../equipments/redux/searchEquipmentFromLat";
import Swal from "sweetalert2";
import {useGetCategories} from "../categories/redux/hooks";
import {useGetActivityTypes} from "../equipments/redux/getActivityTypes";

export default function Search(props) {
  const {searchEquipmentFromLatPending, searchResultFromLat, searchEquipmentFromLat, searchEquipmentFromLatError} = useSearchEquipmentFromLat();
  const {manufacturersData, getManufacturers} = useGetManufacturers();
  const {categoriesData, getCategories} = useGetCategories();
  const {getActivityTypes, activityTypeData} = useGetActivityTypes();
  const user = AuthService.getAuthUser();
  const latId = (props.match.params && props.match.params.latId) ? props.match.params.latId : null;

  useEffect(() => {
      getManufacturers(1, 100);
      getCategories();
      getActivityTypes();
  }, [getManufacturers, getCategories, getActivityTypes]);

  useEffect(() => {
      if (!latId) {
        return;
      }

      searchEquipmentFromLat(latId);
  }, [latId, searchEquipmentFromLat]);

  useEffect(() => {
    if(!searchEquipmentFromLatPending && searchResultFromLat && searchResultFromLat.assetId) {
      props.history.replace(`/search/equipment/${searchResultFromLat.assetId}`);
    } else if(
        (searchEquipmentFromLatError && searchEquipmentFromLatError.message !== "Request not allowed")
        || (searchResultFromLat && !searchResultFromLat.assetId)
    ) {
      props.history.push(`/equipment/add?latid=${latId}`)
      // Swal.fire({
      //   icon: 'error',
      //   text: 'Serial Number not found within the system. Please request the equipment owner to add this asset to the Blockchain FOB - Lifecycle Asset Tracker.'
      // });
    }
  }, [searchEquipmentFromLatPending, searchResultFromLat, props.history, searchEquipmentFromLatError, latId])

  return (
    <div className="search-search">
      { searchEquipmentFromLatPending &&
        <div className="loading-overlay page-content-loading">
          <CircularProgress color="primary" />
        </div>
      }
      <PageHeader location={props.location} />
      
      <Card className="fob-content-body">
        <SearchResultTable user={user}
                           manufacturersData={manufacturersData}
                           categoriesData={categoriesData}
                           activityTypeData={activityTypeData} />
      </Card>
    </div>
  );
};
