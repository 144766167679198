import React, {useState, useEffect, useCallback} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Button, IconButton, InputBase, Paper, Popper, TextField, ClickAwayListener} from "@material-ui/core";
import DateRangeIcon from '@material-ui/icons/DateRange';
import SaveIcon from '@material-ui/icons/Save';
import DayPickerRangeControllerWrapper from "./DayPickerRangeControllerWrapper";
import moment from 'moment';
import Swal from "sweetalert2";


const useStyles = makeStyles((theme) => ({
    input: {
        margin: theme.spacing(1),
        boxShadow: theme.shadows[1],
        backgroundColor: '#fff',
    },
    form: {
        padding: '1rem',
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    }
}));

const popperId = 'date-picker-popper';
const inputDateIds = ['start-date', 'end-date'];
const initialDateValues = {startDate: null, endDate: null};

export default function MyDateRangePicker({dateRange, onDateRangeChanged}) {
  const [value, setValue] = useState('');
  const [myDateRange, setMyDateRange] = useState(dateRange);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  useEffect(() => {
      const {startDate, endDate} = dateRange;
      if (startDate && endDate) {
          const startDateString = startDate.format('MMM D YYYY');
          const endDateString = endDate.format('MMM D YYYY');
          setValue(`${startDateString} - ${endDateString}`);
      }
  }, [dateRange]);

  // const updateDateStringValue = (theDateRange) => {
  //     const {startDate, endDate} = theDateRange;
  //     if (startDate && endDate) {
  //         const startDateString = startDate.format('MMM D YYYY');
  //         const endDateString = endDate.format('MMM D YYYY');
  //         setValue(`${startDateString} - ${endDateString}`);
  //     }
  // };

  const handleShowDatePicker = (event) => {
      console.log('handleShowDatePicker: ');
      setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? popperId : undefined;

  // const handleDateRangeChanged = useCallback((dateRange) => {
  //     console.log('handleDateRangeChanged: ',dateRange);
  //     const {startDate, endDate} = dateRange;
  //
  //     if (startDate && endDate) {
  //         const startDateString = startDate.format('MMM D YYYY');
  //         const endDateString = endDate.format('MMM D YYYY');
  //         setAnchorEl(null);
  //         setValue(`${startDateString} - ${endDateString}`);
  //         // onDateRangeChanged(dateRange);
  //     }
  // }, []);

  const clickAwayHandler = (e,b) => {
      const target = e.target || e.srcElement;
      console.log('clickAwayHandler',e,target,target.id);
      if (e.target && e.target.id && ![inputDateIds].includes(e.target.id)) {
          // DO NOTHING
      } else {
          setAnchorEl(null);
      }
  };

  const handleSaveDateRange = (event) => {
    event.preventDefault();
    console.log('handleSave: ',myDateRange);
    const {startDate, endDate} = myDateRange;

    if (startDate && endDate) {
        setAnchorEl(null);
        onDateRangeChanged(myDateRange);
    } else {
        Swal.fire({
          icon: 'info',
          text: 'Please complete start date and end date fields.',
          timer: 1500,
          showConfirmButton: false,
        });
    }
  };

  const handleClear = () => {
      setMyDateRange(initialDateValues);
      setValue('');
      setAnchorEl(null);
      onDateRangeChanged(initialDateValues);
  };

  return (
    <div className="common-my-date-range-picker">
        <Paper className="common-my-date-range-picker-input-container"
               aria-describedby={id}
               onClick={handleShowDatePicker}>
            <IconButton aria-label="search">
                <DateRangeIcon />
            </IconButton>
            <InputBase id="input-date-string"
                       placeholder="Date Range"
                       value={value} />
        </Paper>

        <ClickAwayListener onClickAway={clickAwayHandler}>
        <Popper id={id} open={open} anchorEl={anchorEl}>
            <Paper elevation={1} style={{padding: '1rem'}}>
                <form noValidate
                      className={classes.form}
                      onSubmit={handleSaveDateRange}>
                    <TextField
                        id="start-date"
                        label="Start Date"
                        type="date"
                        value={myDateRange.startDate ? myDateRange.startDate.format('YYYY-MM-DD') : null}
                        onChange={(e) => setMyDateRange({
                            ...myDateRange,
                            startDate:  moment(e.target.value, 'YYYY-MM-DD'),
                        })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField
                        id="end-date"
                        label="End Date"
                        type="date"
                        value={myDateRange.endDate ? myDateRange.endDate.format('YYYY-MM-DD') : null}
                        onChange={(e) => setMyDateRange({
                            ...myDateRange,
                            endDate:  moment(e.target.value, 'YYYY-MM-DD'),
                        })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        style={{margin: '0 0 0 1rem'}}
                    />

                    <Button color="secondary"
                            variant="outlined"
                            onClick={handleClear}
                            style={{margin: '0 1rem 0 1rem'}}>
                        Clear
                    </Button>
                    <Button color="secondary"
                            variant="contained"
                            type="submit"
                            startIcon={<SaveIcon />}>
                        Save
                    </Button>
                </form>
            {/*<FormControl style={{padding: '1rem'}}>*/}
            {/*    <InputLabel shrink={true} htmlFor="start-date">Start Date</InputLabel>*/}
            {/*    <Input id="start-date"*/}
            {/*           type="date"*/}
            {/*           value={dateRange.startDate ? dateRange.startDate.format('YYYY-MM-DD') : null}*/}
            {/*           onChange={(e) => onDateRangeChanged({*/}
            {/*               ...dateRange,*/}
            {/*               startDate:  moment(e.target.value, 'YYYY-MM-DD'),*/}
            {/*           })} />*/}
            {/*</FormControl>*/}
            {/*<FormControl style={{padding: '1rem'}}>*/}
            {/*    <InputLabel shrink={true} htmlFor="end-date">End Date</InputLabel>*/}
            {/*    <Input id="end-date"*/}
            {/*           type="date"*/}
            {/*           value={dateRange.endDate ? dateRange.endDate.format('YYYY-MM-DD') : null}*/}
            {/*           onChange={(e) => onDateRangeChanged({*/}
            {/*               ...dateRange,*/}
            {/*               endDate: moment(e.target.value, 'YYYY-MM-DD'),*/}
            {/*           })} />*/}
            {/*</FormControl>*/}

            {/*<DayPickerRangeControllerWrapper onDateRangeChanged={handleDateRangeChanged}*/}
            {/*                                 initialStartDate={moment()}*/}
            {/*                                 initialEndDate={moment()}*/}
            {/*                                 onOutsideClick={() => {*/}
            {/*                                     setAnchorEl(null);*/}
            {/*                                 }}*/}
            {/*                                 onPrevMonthClick={() => {*/}
            {/*                                     return true;*/}
            {/*                                 }}*/}
            {/*                                 onNextMonthClick={() => {*/}
            {/*                                     return true;*/}
            {/*                                 }}*/}
            {/*                                 isOutsideRange={() => false}/>*/}
            </Paper>
        </Popper>
        </ClickAwayListener>
    </div>
  );
};
