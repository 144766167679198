import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
    PROFILE_UPDATE_BACKGROUND_IMAGE_BEGIN,
    PROFILE_UPDATE_BACKGROUND_IMAGE_SUCCESS,
    PROFILE_UPDATE_BACKGROUND_IMAGE_FAILURE,
    PROFILE_UPDATE_BACKGROUND_IMAGE_DISMISS_ERROR,
} from './constants';
import ApiService from '../../common/services/ApiService';
import AuthService from '../../authentication/services/AuthService';

export function updateBackgroundImage(file) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: PROFILE_UPDATE_BACKGROUND_IMAGE_BEGIN,
    });

    let formData = new FormData();
    formData.append('file', file);

    const promise = new Promise((resolve, reject) => {
      ApiService.updateBackgroundImage(formData).then(
        (res) => {
            AuthService.updateAuthUser(res);
            let successMessage = 'Successfully updated cover photo';
            dispatch({
              type: PROFILE_UPDATE_BACKGROUND_IMAGE_SUCCESS,
              data: res,
              message: successMessage
            });
            resolve({
              data: res,
              message: successMessage,
            });
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: PROFILE_UPDATE_BACKGROUND_IMAGE_FAILURE,
            data: {
              error: {
                message: err && err.response && err.response.data ? err.response.data.message : 'Unable to save cover photo.'
              }
            },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissUpdateBackgroundImageError() {
  return {
    type: PROFILE_UPDATE_BACKGROUND_IMAGE_DISMISS_ERROR,
  };
}

export function useUpdateBackgroundImage(params) {
  const dispatch = useDispatch();

  const { updateBackgroundImagePending, updateBackgroundImageError } = useSelector(
    state => ({
        updateBackgroundImagePending: state.profile.updateBackgroundImagePending,
        updateBackgroundImageError: state.profile.updateBackgroundImageError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(updateBackgroundImage(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissUpdateBackgroundImageError());
  }, [dispatch]);

  return {
    updateBackgroundImage: boundAction,
    updateBackgroundImagePending,
    updateBackgroundImageError,
    dismissUpdateBackgroundImageError: boundDismissError
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case PROFILE_UPDATE_BACKGROUND_IMAGE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        updateBackgroundImagePending: true,
        updateBackgroundImageError: null
      };

    case PROFILE_UPDATE_BACKGROUND_IMAGE_SUCCESS:
      // The request is success
      return {
        ...state,
        updateBackgroundImagePending: false,
        updateBackgroundImageError: null,
        userProfile: action.data,
        updateBackgroundImageMessage: action.message,
      };

    case PROFILE_UPDATE_BACKGROUND_IMAGE_FAILURE:
      // The request is failed
      return {
        ...state,
        updateBackgroundImagePending: false,
        updateBackgroundImageError: action.data.error,
      };

    case PROFILE_UPDATE_BACKGROUND_IMAGE_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        updateBackgroundImageError: null,
      };

    default:
      return state;
  }
}
