import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  ADMIN_TOGGLE_ADMIN_COMMON_DIALOG,
} from './constants';
import { isNull } from 'underscore';

export function toggleAdminCommonDialog(dialogName, flag=null, params={}) {
  return {
    type: ADMIN_TOGGLE_ADMIN_COMMON_DIALOG,
    dialogName: dialogName,
    flag: flag,
    params: params,
  };
}

export function useToggleAdminCommonDialog() {
  const dispatch = useDispatch();
  const { toggleDialog } = useSelector(
    state => ({
      toggleDialog: state.admin.toggleDialog,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...params) => dispatch(toggleAdminCommonDialog(...params)), [dispatch]);
  return {
    toggleDialog: toggleDialog,
    toggleAdminCommonDialog: boundAction,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case ADMIN_TOGGLE_ADMIN_COMMON_DIALOG:
      return {
        ...state,
        toggleDialog: {
          flag: isNull(action.flag) ? !state.toggleDialog.flag : action.flag,
          name: action.dialogName,
          ...action.params
        },
      };

    default:
      return state;
  }
}
