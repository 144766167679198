import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  EQUIPMENTS_GET_MY_EQUIPMENTS_BEGIN,
  EQUIPMENTS_GET_MY_EQUIPMENTS_SUCCESS,
  EQUIPMENTS_GET_MY_EQUIPMENTS_FAILURE,
  EQUIPMENTS_GET_MY_EQUIPMENTS_DISMISS_ERROR,
} from './constants';
import ApiService from '../../common/services/ApiService';
import AuthService from '../../authentication/services/AuthService';
import {isEmpty} from 'underscore';


export function getMyEquipments(page, rowsPerPage, order, orderColumn) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: EQUIPMENTS_GET_MY_EQUIPMENTS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let user = AuthService.getAuthUser();

      // if (isEmpty(user) || isEmpty(user.owner)) {
      //     reject(null);
      //     return;
      // }

      const ownerId = (isEmpty(user) && isEmpty(user.owner)) 
                      ? user.owner.ownerId 
                      : user.id;

      ApiService.getAssets(ownerId, page, rowsPerPage, order, orderColumn).then(
        (res) => {
          dispatch({
            type: EQUIPMENTS_GET_MY_EQUIPMENTS_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: EQUIPMENTS_GET_MY_EQUIPMENTS_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissGetMyEquipmentsError() {
  return {
    type: EQUIPMENTS_GET_MY_EQUIPMENTS_DISMISS_ERROR,
  };
}

export function useGetMyEquipments(params) {
  const dispatch = useDispatch();

  const { getMyEquipmentsPending, getMyEquipmentsError, equipments } = useSelector(
    state => ({
      getMyEquipmentsPending: state.equipments.getMyEquipmentsPending,
      getMyEquipmentsError: state.equipments.getMyEquipmentsError,
      equipments: state.equipments.equipments,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(getMyEquipments(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetMyEquipmentsError());
  }, [dispatch]);

  return {
    getMyEquipments: boundAction,
    getMyEquipmentsPending,
    getMyEquipmentsError,
    dismissGetMyEquipmentsError: boundDismissError,
    equipments: equipments,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case EQUIPMENTS_GET_MY_EQUIPMENTS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getMyEquipmentsPending: true,
        getMyEquipmentsError: null,
      };

    case EQUIPMENTS_GET_MY_EQUIPMENTS_SUCCESS:
      // The request is success
      return {
        ...state,
        getMyEquipmentsPending: false,
        getMyEquipmentsError: null,
        equipments: action.data,
      };

    case EQUIPMENTS_GET_MY_EQUIPMENTS_FAILURE:
      // The request is failed
      return {
        ...state,
        getMyEquipmentsPending: false,
        getMyEquipmentsError: action.data.error,
      };

    case EQUIPMENTS_GET_MY_EQUIPMENTS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getMyEquipmentsError: null,
      };

    default:
      return state;
  }
}
