import React, {useState, useCallback} from 'react';
import {
    Grid,
    Typography,
} from '@material-ui/core';
import {SPEND_BY_MODEL_HEADER_TEXT} from '../redux/constants';
import ApiService from '../../common/services/ApiService';
import ScrollColumn2D from './widgets/ScrollColumn2D';
import { sortBy } from 'underscore';

const LABEL_TEXT = 'Model Number',
      VALUE_TEXT = 'Grand Total';

const LABEL_KEY = 'modelNumber',
      VALUE_KEY = 'grandTotal';

function useGetData() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const getData = useCallback((startDate, endDate) => {
        if (!startDate || !endDate) return;

        setLoading(true);

        const startDateStr = startDate ? startDate.format('YYYY-MM-DD') : '';
        const endDateStr = endDate ? endDate.format('YYYY-MM-DD') : '';

        ApiService.getSpendByModelAggregation(startDateStr, endDateStr)
            .then((res) => {
                if (res) {
                    const filteredData = sortBy(res.filter(item => !!item[VALUE_KEY] && !!item[LABEL_KEY]), (item) => -item[VALUE_KEY]);

                    let initialData = {
                        categories: [{
                            category: filteredData.map(item => ({label: item[LABEL_KEY]})),
                        }],
                        dataset: [{
                            data: filteredData.map(item => ({value: item[VALUE_KEY]})),
                        }]
                    };

                    setData(initialData);
                } else {
                    setError(res);
                }
            }, (err) => {
                setError(err);
            })
            .finally(() => setLoading(false));
    }, []);

    return {
        data,
        loading,
        error,
        getData,
    };
}

function SpendByModelWidget() {
    const [dateRange, setDateRange] = useState({startDate: null, endDate: null});
    const {data, loading, error, getData} = useGetData(dateRange.startDate, dateRange.endDate);

    //console.log('data: ',data,' loadng: ',loading,' error: ',error);

    const handleDateRangeChanged = useCallback(({startDate, endDate}) => {
        setDateRange({startDate, endDate});
    }, []);

    const handleApplyFilter = useCallback(() => {
        getData(dateRange.startDate, dateRange.endDate);
    }, [getData, dateRange]);

    return (
        <ScrollColumn2D
            loading={loading}
            data={data}
            dateRange={dateRange}
            onDateRangeChanged={handleDateRangeChanged}
            handleApplyFilter={handleApplyFilter}
            otherChartOptions={{
                caption: SPEND_BY_MODEL_HEADER_TEXT,
                xaxisname: LABEL_TEXT,
                yaxisname: VALUE_TEXT,
            }}
        />
    );
}

export default function SpendByModel() {
    return (
        <Grid item
              container
              direction="column"
              justifyContent="center"
              alignItems="center">
            <Grid item>
                <Typography color="primary" className="dashboard-dashboard-content-header-title" variant="h6">{SPEND_BY_MODEL_HEADER_TEXT}</Typography>
            </Grid>
            <Grid item
                  container
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  style={{justifyContent: 'space-around'}}>
                <Grid item
                      container
                      alignItems="center"
                      style={{justifyContent: 'center'}}
                      md={12}
                      lg={12}>
                    <SpendByModelWidget />
                </Grid>
            </Grid>
        </Grid>
    );
}