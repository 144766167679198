import React, { useCallback, useContext } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import appStyle from '../../styles/jss/app-styles';
import {useSelector} from 'react-redux';
import NavigationManager from './services/NavigationManager';
import AuthService from '../authentication/services/AuthService';
import AuthContext from '../authentication/AuthContext';
import { useToggleSidenav } from './redux/hooks';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import QRCodeScannerDialog from './QRCodeScannerDialog';
import {useToggleQRCodeScannerDialog} from "./redux/toggleQRCodeScannerDialog";

const useStyles = makeStyles(appStyle, {generateId: null});


export default function SideNav(props) {
  const {toggleQRCodeScannerDialog} = useToggleQRCodeScannerDialog();
  const classes = useStyles();
  const {toggleSidenav} = useToggleSidenav();
  const drawSidenav = useSelector(state => state.common.drawSidenav);
  const user = useContext(AuthContext);
  const isSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const MENU_ITEMS = NavigationManager.getMenuItems(user);

  const handleLogout = useCallback(
    () => {
      AuthService.signOut();
    },[]);

  const handleScan = () => {
    if(window.ReactNativeWebView) {
      let data = {
        'action': 'OPEN_QR_SCANNER',
      };
      window.ReactNativeWebView.postMessage(JSON.stringify(data));
    } else {
      toggleQRCodeScannerDialog();
    }
  }

  const renderListItem = (item, index) => {
    if(item.url === '/logout') {
      return  <ListItem   button
                  component="a"
                  onClick={handleLogout}
                  key={index}
                  className={props.history.pathname === item.url ? 'selected' : ''}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.sideNavLabel ? item.sideNavLabel : item.label} />
              </ListItem>
    } else if(item.url === '/scan') {
      if(isSm) {
        return  <ListItem  button
                    component="a"
                    onClick={handleScan}
                    key={index}
                    className={props.history.pathname === item.url ? 'selected' : ''}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.sideNavLabel ? item.sideNavLabel : item.label} />
                </ListItem>
      }
    } else {
      return  <ListItem  button
                component="a"
                href={item.url}
                key={index}
                className={props.history.pathname === item.url ? 'selected' : ''}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.sideNavLabel ? item.sideNavLabel : item.label} />
              </ListItem>
    }
  }

  return (
    <div className={!isSm ? "common-side-nav" : "common-side-nav common-side-nav-mobile"}>
      <Drawer
        variant="permanent"
        anchor="left"
        className={drawSidenav && !isSm ? 'drawer-opened' : 'drawer-closed'}
      >
        <div className={drawSidenav ? classes.sidePanel+" sidepanelOpen" : classes.sidePanelClosed+" sidepanelClosed"}>
          <List>
          
          <ListItem component="div"
                    className="primary-menu-bar-list-item">
            <ListItemIcon>
              <IconButton disableRipple disableFocusRipple color="secondary" aria-label="menu" className="primary-menu-bar" onClick={toggleSidenav}>
                <MenuIcon />
              </IconButton>
            </ListItemIcon>
          </ListItem>
          
            {
              MENU_ITEMS.map((item, index) => renderListItem(item, index) )
            }
          </List>
          <QRCodeScannerDialog history={props.history} />
        </div>
      </Drawer>
    </div>
  );
};

