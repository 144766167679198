import React from 'react';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import { Button } from '@material-ui/core';


export default function AddAddressButton(props) {
  return (
      <Button variant="contained"
              color="secondary"
              startIcon={<MyLocationIcon />}
              className="common-add-address-button rounded-btn"
              onClick={props.onClick}
      >ADD ADDRESS</Button>
  );
};

