export default class CommonController {

    constructor(store) {
        this.store = store;
    }

    dispatch(action) {
        this.store.dispatch(action);
    }
}
