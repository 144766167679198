let CONFIG = process.env.SYSTEM_CONFIG
  ? {...process.env, ...process.env.SYSTEM_CONFIG.parsed}
  : {...process.env};

CONFIG['ROLES'] = {
    'admin': {
        id: 1,
        label: 'Admin'
    },
    'manufacturer': {
        id: 2,
        label: 'Manufacturer'
    },
    'distribution': {
        id: 3,
        label: 'Distribution'
    },
    'dealer': {
        id: 4,
        label: 'Dealer'
    },
    'warehouse': {
        id: 5,
        label: 'Warehouse'
    },
    'installer': {
        id: 6,
        label: 'Installer'
    },
    'owner': {
        id: 7,
        label: 'Individual Owner'
    },
    'maintenance': {
        id: 8,
        label: 'Maintenance / Repair Personnel'
    },
    'user': {
        id: 9,
        label: 'User'
    },
};

export {CONFIG};