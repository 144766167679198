import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  EQUIPMENTS_CREATE_EQUIPMENT_BEGIN,
  EQUIPMENTS_CREATE_EQUIPMENT_SUCCESS,
  EQUIPMENTS_CREATE_EQUIPMENT_FAILURE,
  EQUIPMENTS_CREATE_EQUIPMENT_DISMISS_ERROR,
} from './constants';
import ApiService from '../../common/services/ApiService';
import { isEmpty } from 'underscore';

export function createEquipment(data) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: EQUIPMENTS_CREATE_EQUIPMENT_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      ApiService.saveEquipment(data).then(
        (res) => {
          if (!isEmpty(data.warranty)) {
            data.warranty['assetId'] = res.assetId;
            ApiService.createWarranty(data.warranty).then(res1 => {
              let resolveResponse = {
                data: res,
                message: 'Equipment Created.',
              };
              dispatch({
                type: EQUIPMENTS_CREATE_EQUIPMENT_SUCCESS,
                ...resolveResponse,
              });
              resolve(resolveResponse);
            }, err1 => {
              dispatch({
                type: EQUIPMENTS_CREATE_EQUIPMENT_FAILURE,
                data: {
                  error: {
                    message: err1 && err1.response && err1.response.data ? err1.response.data.message : 'Unable to create warranty.'
                  }
                },
              });
              reject(err1);
            })
          } else {
            let resolveResponse = {
              data: res,
              message: 'Equipment Created.',
            };
            dispatch({
              type: EQUIPMENTS_CREATE_EQUIPMENT_SUCCESS,
              ...resolveResponse,
            });
            resolve(resolveResponse);
          }
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: EQUIPMENTS_CREATE_EQUIPMENT_FAILURE,
            data: {
              error: {
                message: err && err.response && err.response.data ? err.response.data.message : 'Unable to Create Equipment.'
              }
            },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissCreateEquipmentError() {
  return {
    type: EQUIPMENTS_CREATE_EQUIPMENT_DISMISS_ERROR,
  };
}

export function useCreateEquipment(params) {
  const dispatch = useDispatch();

  const { createdEquipment, createEquipmentPending, createEquipmentError } = useSelector(
    state => ({
      createdEquipment: state.equipments.createdEquipment,
      createEquipmentPending: state.equipments.createEquipmentPending,
      createEquipmentError: state.equipments.createEquipmentError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(createEquipment(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissCreateEquipmentError());
  }, [dispatch]);

  return {
    createdEquipment,
    createEquipment: boundAction,
    createEquipmentPending,
    createEquipmentError,
    dismissCreateEquipmentError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case EQUIPMENTS_CREATE_EQUIPMENT_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        createEquipmentPending: true,
        createEquipmentError: null,
      };

    case EQUIPMENTS_CREATE_EQUIPMENT_SUCCESS:
      // The request is success
      return {
        ...state,
        createEquipmentPending: false,
        createEquipmentError: null,
        createdEquipment: action.data,
      };

    case EQUIPMENTS_CREATE_EQUIPMENT_FAILURE:
      // The request is failed
      return {
        ...state,
        createEquipmentPending: false,
        createEquipmentError: action.data.error,
      };

    case EQUIPMENTS_CREATE_EQUIPMENT_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        createEquipmentError: null,
      };

    default:
      return state;
  }
}
