
import {signIn} from "./redux/signIn";
import StringHelper from "../common/services/StringHelper";
import {searchEquipmentFromQRCode} from "../equipments/redux/searchEquipmentFromQRCode";


const AuthenticationController = CommonController => class extends CommonController {
    constructor(store) {
        super(store);
    }

    login(email, password) {
        console.log('authenticate: ',email,password);
        this.dispatch(signIn({email, password}))
    }

    scanQRCodeCallBack(data) {
        if(data) {
            if(StringHelper.isUrlValid(data)) {
                this.dispatch(searchEquipmentFromQRCode({manufacturerId: "1"}, "Manufacturer", data));
            } else {
                alert("The data is not valid. Please scan again.");
            }
        }
    }
}

export default AuthenticationController;
