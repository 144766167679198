import React, { useEffect, useState } from 'react';
import {
  Typography,
  Button,
  Grid,
  FormControl,
  FormHelperText,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  CircularProgress
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import AuthTextField from './AuthTextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {useRegister, useForgotPassword, useSetPass, useGetUserFromTempToken} from './redux/hooks';
import { useSelector } from 'react-redux';
import { LoadingButton } from '../common';
import Swal from "sweetalert2";
import Alert from '@material-ui/lab/Alert';
import _ from 'underscore';
import history from '../../common/history';
import StringHelper from "../common/services/StringHelper";
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const LOGIN_PAGE = '/auth/signin';

const validationSchema = Yup.object({
  firstName: Yup.string().required('Required').max(50),
  lastName: Yup.string().required('Required').max(50),
  email: Yup.string().required('Required').email(),
  phoneNumber: Yup.string().phone().required('Required').max(50),
});

const setPassValidationSchema = Yup.object({
  password: Yup.string()
              .required('Required') 
              .min(8, 'Password should be 8 chars minimum')
              .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,100}$/, 'Must contain at least one number and one uppercase and lowercase letter and one symbol, and at least 8 or more characters'),
  confirmPassword: Yup.string()
              .oneOf([Yup.ref('password'), null], 'Passwords must match')
              .required('Required')
});


export default function Register(props) {
  const isXs = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const {register, registerError, registerPending, registerSuccessMessage} = useRegister();
  const {setPass, setPassError, setPassPending, setPassSuccessMessage} = useSetPass();
  const {getUserFromTempToken, getUserFromTempTokenPending, getUserFromTempTokenError} = useGetUserFromTempToken();
  const {forgotPassword} = useForgotPassword();
  const forgotPasswordPending = useSelector(state => state.authentication.forgotPasswordPending);
  const {executeRecaptcha} = useGoogleReCaptcha();
  const [captchaError, setCaptchaError] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [ showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const [setPassword, handleSetPassword] = useState(false);
  const queryParamObj = StringHelper.getQueryParams(props.location.search);
  const user = useSelector(state => state.authentication.user);
  useEffect(() => {
    if(queryParamObj.t) {
      handleSetPassword(true);
      getUserFromTempToken({
        temporaryToken: queryParamObj.t
      });
    }
  }, [queryParamObj.t, getUserFromTempToken]);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      password: '',
      confirmPassword: ''
    },
    validationSchema: setPassword ? setPassValidationSchema : validationSchema,
    onSubmit: values => {
      if(!setPassword) {
          executeRecaptcha('register').then(res => {
              if (res) {
                  register(values);
              } else {
                  setCaptchaError('Unable to Register.');
              }
          }, err => {
              setCaptchaError('Unable to Register.');
          });
            // .then(res => {
            //   console.log('res reg: ',res);
            // }, err => {
            //   let errMessage = 'Unable to register';
            //
            //   if (err.response && err.response.data && err.response.data.message) {
            //     errMessage = err.response.data.message;
            //   }
            //
            //   Swal.fire({
            //     icon: 'error',
            //     text: errMessage,
            //   });
            // });
      } else {
        executeRecaptcha('setPass').then(res => {
          if (res) {
            setPass({
              password: values.password,
              temporaryToken: queryParamObj.t
            });
          } else {
              setCaptchaError('Unable to set new password.');
          }
      }, err => {
          setCaptchaError('Unable to set new password.');
      });
      }
    },
  });

  const clearForm = formik.resetForm;

  useEffect(() => {
    if (!registerSuccessMessage) return;

    Swal.fire({
      icon: 'success',
      text: registerSuccessMessage,
      timer: 3000
    });

    clearForm();
  }, [registerSuccessMessage, clearForm]);

  useEffect(() => {
    if (!setPassSuccessMessage) return;

    Swal.fire({
      icon: 'success',
      text: setPassSuccessMessage,
      timer: 3000
    });

    clearForm();
    setShowPassword(false);
    setShowConfirmPassword(false);
    handleSetPassword(false);
    setTimeout(function() {
      history.replace(LOGIN_PAGE);
    }, 3000);
  }, [setPassSuccessMessage, clearForm]);

  const handleForgotPassword = () => {
    if (!formik.values.email) {
      Swal.fire('', 'Please enter email address', 'warning');
      return;
    }

    executeRecaptcha('forgotpassword').then(res => {
        if (res) {
      forgotPassword(formik.values.email)
      .then(res => {
          Swal.fire({
              icon: 'success',
              text: res.message,
              timer: 2000
          });
      });
        } else {
            setCaptchaError('Error on Forgot Password.');
        }
    }, err => {
        setCaptchaError('Error on Forgot Password.');
    });
  };

  const errorMessage = !_.isEmpty(registerError)
      ? registerError.message
      : (captchaError ? captchaError : null);

  return (
    <div className="authentication-register">
      <Typography 
        variant="h5" 
        component="h5" 
        color="secondary" 
        className={isXs ? "authentication-header-content authentication-header-content-xs" : "authentication-header-content"}>
          Register
      </Typography>

      <section className="authentication-form-container">
        { getUserFromTempTokenPending &&
          <div className="loading-overlay">
            <CircularProgress color="primary" />
          </div>
        }
        { !setPassword ?
          <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
            <Grid container
                  direction="column"
                  justify="center"
                  alignItems="center"
            >
              <Grid container
                    item
                    md={12}
                    className={`${isXs ? "authentication-form-grid-row error-xs-style" : "authentication-form-grid-row"}`}>
                <Grid item md={12} className="authentication-form-grid-cell">
                    {
                        errorMessage
                            ? <Alert elevation={0} variant="standard" severity="error">
                                {errorMessage}
                              </Alert>
                            : null
                    }
                </Grid>
              </Grid>

              <Grid container
                    item
                    md={12}
                    spacing={2}
                    className={`authentication-form-grid-row ${isXs ? 'authentication-form-grid-multiple-cells-row-xs' : 'authentication-form-grid-multiple-cells-row'}`}>
                <Grid item md={6} xs={12} className="authentication-form-grid-cell">
                  <AuthTextField
                    id="firstName"
                    type="text"
                    label="FIRST NAME"
                    fullWidth
                    helperText={formik.errors.firstName ? formik.errors.firstName : ''}
                    error={Boolean(formik.errors.firstName)}
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    required
                  />
                </Grid>

                <Grid item md={6} xs={12} className="authentication-form-grid-cell">
                  <AuthTextField
                    id="lastName"
                    type="text"
                    label="LAST NAME"
                    fullWidth
                    helperText={formik.errors.lastName ? formik.errors.lastName : ''}
                    error={Boolean(formik.errors.lastName)}
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    required
                  />
                </Grid>
              </Grid>

              <Grid container
                    item
                    md={12}
                    className="authentication-form-grid-row">
                <Grid item md={12} xs={12} className="authentication-form-grid-cell">
                  <AuthTextField
                    id="email"
                    type="email"
                    label="EMAIL ADDRESS"
                    fullWidth
                    helperText={formik.errors.email ? formik.errors.email : ''}
                    error={Boolean(formik.errors.email)}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    required
                  />
                </Grid>
              </Grid>

              <Grid container
                    item
                    md={12}
                    className="authentication-form-grid-row">
                <Grid item md={12} xs={12} className="authentication-form-grid-cell">
                  <AuthTextField
                    id="phoneNumber"
                    type="tel"
                    label="PHONE NUMBER"
                    fullWidth
                    helperText={formik.errors.phoneNumber ? formik.errors.phoneNumber : ''}
                    error={Boolean(formik.errors.phoneNumber)}
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    required
                  />
                </Grid>
              </Grid>

              <Grid container
                    item
                    md={12}
                    className="authentication-form-grid-row">
                <Grid item md={12} xs={12} className="authentication-form-grid-cell">
                  {
                    registerPending === true
                    ? <LoadingButton />
                    : <Button className="authentication-form-main-btn"
                              type="submit"
                              variant="contained"
                              size="large"
                              color="secondary"
                              fullWidth>
                        Register
                      </Button>
                  }
                </Grid>
              </Grid>

              <Grid container
                    item
                    md={12}
                    justify="center"
                    alignItems="center"
                    className="authentication-form-grid-row">
                <Grid item className="authentication-form-grid-cell">
                  {
                    forgotPasswordPending === true
                      ? <LoadingButton variant="text" />
                      : <Typography className="authentication-forgot-password" variant="a" component="a" onClick={handleForgotPassword}>Forgot password?</Typography>
                  }
                </Grid>
              </Grid>
            </Grid>
          </form>
          :
          <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
            <Grid container
                  direction="column"
                  justify="center"
                  alignItems="center"
            >
              <Grid container
                    item
                    md={12}
                    className={`${isXs ? "authentication-form-grid-row error-xs-style" : "authentication-form-grid-row"}`}>
                <Grid item md={12} className="authentication-form-grid-cell">
                    {
                        !_.isEmpty(setPassError) &&
                            <Alert elevation={0} variant="standard" severity="error">
                                {setPassError.message}
                            </Alert>
                    }
                    {
                        !_.isEmpty(getUserFromTempTokenError) &&
                            <Alert elevation={0} variant="standard" severity="error">
                                {getUserFromTempTokenError.message}
                            </Alert>
                    }
                </Grid>
              </Grid>

              <Grid container
                    item
                    md={12}
                    spacing={2}
                    className={`authentication-form-grid-row ${isXs ? 'authentication-form-grid-multiple-cells-row-xs' : 'authentication-form-grid-multiple-cells-row'}`}>
                <Grid item md={6} xs={12} className="authentication-form-grid-cell">
                  <AuthTextField
                    id="firstName"
                    type="text"
                    label="FIRST NAME"
                    fullWidth
                    disabled
                    value={user.firstName}
                  />
                </Grid>

                <Grid item md={6} xs={12} className="authentication-form-grid-cell">
                  <AuthTextField
                    id="lastName"
                    type="text"
                    label="LAST NAME"
                    fullWidth
                    disabled
                    value={user.lastName}
                  />
                </Grid>
              </Grid>

              <Grid container
                    item
                    md={12}
                    className="authentication-form-grid-row">
                <Grid item md={12} xs={12} className="authentication-form-grid-cell">
                  <AuthTextField
                    id="email"
                    type="email"
                    label="EMAIL ADDRESS"
                    fullWidth
                    disabled
                    value={user.email}
                  />
                </Grid>
              </Grid>

              <Grid container
                    item
                    md={12}
                    className="authentication-form-grid-row">
                <Grid item md={12} xs={12} className="authentication-form-grid-cell">
                  <AuthTextField
                    id="phoneNumber"
                    type="tel"
                    label="PHONE NUMBER"
                    fullWidth
                    disabled
                    value={user.phoneNumber}
                  />
                </Grid>
              </Grid>

              <Grid container
                  item
                  md={12}
                  className="authentication-form-grid-row">
                <Grid item md={12} xs={12} className="authentication-form-grid-cell">
                  <FormControl fullWidth
                              error={Boolean(formik.errors.password)}
                              className="authentication-auth-text-field">
                    <InputLabel htmlFor="password">PASSWORD</InputLabel>
                    <Input
                      id="password"
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      }
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      required
                    />
                    <FormHelperText id="password-error-text">{formik.errors.password ? formik.errors.password : ''}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container
                  item
                  md={12}
                  className="authentication-form-grid-row">
                <Grid item md={12} xs={12} className="authentication-form-grid-cell">
                  <FormControl fullWidth
                              error={Boolean(formik.errors.confirmPassword)}
                              className="authentication-auth-text-field">
                    <InputLabel htmlFor="password">CONFIRM PASSWORD</InputLabel>
                    <Input
                      id="confirmPassword"
                      type={showConfirmPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                          >
                            {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        </InputAdornment>
                      }
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      required
                    />
                    <FormHelperText id="password-error-text">{formik.errors.confirmPassword ? formik.errors.confirmPassword : ''}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container
                    item
                    md={12}
                    className="authentication-form-grid-row">
                <Grid item md={12} xs={12} className="authentication-form-grid-cell">
                  {
                    setPassPending === true
                    ? <LoadingButton />
                    : <Button className="authentication-form-main-btn"
                              type="submit"
                              variant="contained"
                              size="large"
                              color="secondary"
                              fullWidth>
                        Register
                      </Button>
                  }
                </Grid>
              </Grid>
            </Grid>
          </form>
        }
      </section>
    </div>
  );
};
