import {CONFIG} from './../../../config';
import moment from 'moment';
import {isObject, chain} from 'underscore';


export default class StringHelper {
    static formatAddress(address) {
        return address && address.line1 && address.state && address.city
               ? `${address.line1} ${address.state}, ${address.city}`
               : '';
    }

    static formatDisplayDate(dateStr) {
        if (!dateStr) return '';
        return moment(dateStr).parseZone().format(CONFIG.DATE_DISPLAY_FORMAT);
    }

    static parseDateForSave(inputDate) {
        if (!inputDate) return '';
        let _date = isObject(inputDate) ? inputDate : moment(inputDate);
        return _date.format('YYYY-MM-DD');
    }

    static getQueryParams(query) {
        if(query) {
            return chain(query.slice(1).split('&'))
                 .map((item) => { if (item) { return item.split('='); } return false; })
                 .compact()
                 .object()
                 .value();
        } else {
            return {};
        }
    }

    static isUrlValid(str) {
        if(str) {
            var regexp = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig;
            if(regexp.test(str)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    static isLatUrl(url) {
        let res = url.match(/lat\/?[0-9]+$/gmi);
        return !!res
    }

    static getLatIdFromUrl(url) {
        return url.match(/[0-9]+$/gm);
    }
}
