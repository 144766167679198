import React, {useState} from 'react';
import {Grid, Paper, Typography, IconButton, InputBase, Button, CircularProgress} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReactFC from "react-fusioncharts";
import SearchIcon from '@material-ui/icons/Search';
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import MyDateRangePicker from "../../../common/MyDateRangePicker";
import {isObject} from 'underscore';

const CHART_HEIGHT = 732;

const commonDataSource = {
    theme: 'fusion', // 'fusion', 'gammel', 'candy', 'ocean', 'zune', 'carbon', 'umber'
    bgColor: '#fafbfc',

    labelPosition: 'outside',
    labelFontSize: '14px',
    exportEnabled: '1',

    showValues: '1',
    rotateValues: '1',
    numberPrefix: '$',
    numVisiblePlot: '12',
    scrollHeight: '10',
    flatScrollBars: '1',
    scrollShowButtons: '0',
    scrollColor: '#cccccc',
    showHoverEffect: '1',

    showBorder: '1',
    borderColor: '#ff5e5e',
    borderThickness: '4',
    borderAlpha: '80',
    chartLeftMargin: '20',
    chartTopMargin: '20',
    chartRightMargin: '20',
    chartBottomMargin: '20',
    scrollPosition: 'bottom',
    plotSpacePercent: '100'
};

const createDataSource = (data, otherChartOptions={}) => {
    return {
        chart: {
            ...commonDataSource,
            ...otherChartOptions
        },
        ...data,
    };
};

const getChartConfig = (data, height, chartType, otherChartOptions={}) => {
    return {
        type: chartType,
        width: '100%',
        height: '100%',
        dataFormat: 'json',
        dataSource: createDataSource(data, otherChartOptions),
    };
};

function FilterDateRange({
    dateRange,
    onDateRangeChanged,
}) {
    return (<MyDateRangePicker dateRange={dateRange}
                               onDateRangeChanged={onDateRangeChanged} />
    );
}

export default function ScrollLine2D({
    data,
    loading,
    onDateRangeChanged,
    dateRange,
    handleApplyFilter,
    showFilters=true,
    otherChartOptions={},
    customLegend=null,
    height=CHART_HEIGHT,
    chartType='scrollline2d',
}) {
  const chartConfigs = getChartConfig(data, height, chartType, otherChartOptions);

  return (
    <div className="dashboard-chart-2d-doughnut-paper"
         component={Paper}
         elevation={0}
         variant="outlined"
         direction="column"
         justifyContent="center"
         alignItems="center">
        {
            showFilters &&
            <Grid item
                  container
                  direction="row"
                  spacing={0}
                  className="dashboard-chart-2d-doughnut-paper-grid">
                <Grid container
                      item
                      xs={12}
                      sm={5}
                      md={5}
                      lg={5}>
                    <FilterDateRange dateRange={dateRange} onDateRangeChanged={onDateRangeChanged} />
                </Grid>
                <Grid container
                      item
                      xs={12}
                      sm={2}
                      md={2}
                      lg={2}>
                    <Button variant="contained"
                            color="secondary"
                            onClick={handleApplyFilter}>Apply</Button>
                </Grid>
            </Grid>
        }

        {
            loading &&
            <CircularProgress />
        }

        {
            (!loading && data) && (
                <Grid item>
                    <ReactFC {...chartConfigs} />
                    {
                        customLegend &&
                        <Grid container
                              alignItems="center"
                              style={{justifyContent: 'center', marginBottom: '24px'}}>
                            <Grid item>
                                {customLegend}
                            </Grid>
                        </Grid>
                    }
                </Grid>
            )
        }
    </div>
  );
};
