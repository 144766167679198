import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  EQUIPMENTS_TOGGLE_ACTIVITY_DETAILS_DIALOG,
} from './constants';
import { isNull } from 'underscore';


export function toggleActivityDetailsDialog(flag=false, equipmentDetails={}, activity={}, isAdd=false, newActivityAddedTimestamp=null) {
  return {
    type: EQUIPMENTS_TOGGLE_ACTIVITY_DETAILS_DIALOG,
    flag: flag,
    selectedActivity: {activity, equipmentDetails, isAdd: isAdd},
    newActivityAddedTimestamp: newActivityAddedTimestamp,
  };
}

export function useToggleActivityDetailsDialog() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => dispatch(toggleActivityDetailsDialog(...params)), [dispatch]);
  return { toggleActivityDetailsDialog: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case EQUIPMENTS_TOGGLE_ACTIVITY_DETAILS_DIALOG:
      let newState = {
        ...state,
        isActivityDetailsDialogVisible: isNull(action.flag) ? !state.isActivityDetailsDialogVisible : action.flag,
        selectedActivity: action.selectedActivity,
      };
      newState.newActivityAddedTimestamp = action.newActivityAddedTimestamp ? action.newActivityAddedTimestamp : null;
      return newState;

    default:
      return state;
  }
}
