import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  COMMON_TOGGLE_QR_CODE_SCANNER_DIALOG,
} from './constants';
import { isNull } from 'underscore';

export function toggleQRCodeScannerDialog(flag=null) {
  return {
    type: COMMON_TOGGLE_QR_CODE_SCANNER_DIALOG,
    flag: flag,
  };
}

export function useToggleQRCodeScannerDialog() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => dispatch(toggleQRCodeScannerDialog(...params)), [dispatch]);
  return { toggleQRCodeScannerDialog: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_TOGGLE_QR_CODE_SCANNER_DIALOG:
      return {
        ...state,
        isQRCodeScannerDialogVisible: isNull(action.flag) ? !state.isQRCodeScannerDialogVisible : action.flag,
      };

    default:
      return state;
  }
}
