import _ from 'underscore';


const MANUFACTURER_TABLE_KEY_COLS = [
  {key: 'description', label: 'Manufacture', order: 1},
  {key: 'location', label: 'Location', order: 2},
];



export default class ManufacturerssHelper {

  static getManufacturerTableColumns() {
    return _.sortBy(MANUFACTURER_TABLE_KEY_COLS, item => {
      return item.order
    }).map(item => {
      return item.label;
    });
  }

}