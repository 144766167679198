import {ADMIN_CATEGORIES} from "../redux/manageRoleConstants";
import {ROLES_MANUFACTURER_ROLE_ID} from "../redux/constants";

export default class ManageRoleService {

    static getAdminCategoriesByRole(roleId) {
        switch (roleId) {
            case 1:
                return ADMIN_CATEGORIES
            case 2:
                return ADMIN_CATEGORIES.filter(r => {
                    return r.name === 'admin-assets';
                });
            default:
                return [];
        }
    }

    static isManufacturer(roleId) {
        return roleId == ROLES_MANUFACTURER_ROLE_ID;
    }
}