import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  EQUIPMENTS_UPDATE_EQUIPMENT_BEGIN,
  EQUIPMENTS_UPDATE_EQUIPMENT_SUCCESS,
  EQUIPMENTS_UPDATE_EQUIPMENT_FAILURE,
  EQUIPMENTS_UPDATE_EQUIPMENT_DISMISS_ERROR,
} from './constants';
import ApiService from '../../common/services/ApiService';

export function updateEquipment(data) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: EQUIPMENTS_UPDATE_EQUIPMENT_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      ApiService.updateEquipment(data).then(
        (res) => {
            let resolveResponse = {
              data: res,
              message: 'Equipment Updated.',
            };
            dispatch({
              type: EQUIPMENTS_UPDATE_EQUIPMENT_SUCCESS,
              ...resolveResponse,
            });
            resolve(resolveResponse);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: EQUIPMENTS_UPDATE_EQUIPMENT_FAILURE,
            data: {
              error: {
                message: err && err.response && err.response.data ? err.response.data.message : 'Unable to Update Equipment.'
              }
            },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissUpdateEquipmentError() {
  return {
    type: EQUIPMENTS_UPDATE_EQUIPMENT_DISMISS_ERROR,
  };
}

export function useUpdateEquipment(params) {
  const dispatch = useDispatch();

  const { updatedEquipment, updateEquipmentPending, updateEquipmentError } = useSelector(
    state => ({
      updatedEquipment: state.equipments.updatedEquipment,
      updateEquipmentPending: state.equipments.updateEquipmentPending,
      updateEquipmentError: state.equipments.updateEquipmentError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(updateEquipment(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissUpdateEquipmentError());
  }, [dispatch]);

  return {
    updatedEquipment,
    updateEquipment: boundAction,
    updateEquipmentPending,
    updateEquipmentError,
    dismissUpdateEquipmentError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case EQUIPMENTS_UPDATE_EQUIPMENT_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        updateEquipmentPending: true,
        updateEquipmentError: null,
      };

    case EQUIPMENTS_UPDATE_EQUIPMENT_SUCCESS:
      // The request is success
      return {
        ...state,
        updateEquipmentPending: false,
        updateEquipmentError: null,
        updatedEquipment: action.data,
      };

    case EQUIPMENTS_UPDATE_EQUIPMENT_FAILURE:
      // The request is failed
      return {
        ...state,
        updateEquipmentPending: false,
        updateEquipmentError: action.data.error,
      };

    case EQUIPMENTS_UPDATE_EQUIPMENT_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        updateEquipmentError: null,
      };

    default:
      return state;
  }
}
