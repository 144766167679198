import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  AUTHENTICATION_GET_USER_FROM_TEMP_TOKEN_BEGIN,
  AUTHENTICATION_GET_USER_FROM_TEMP_TOKEN_SUCCESS,
  AUTHENTICATION_GET_USER_FROM_TEMP_TOKEN_FAILURE,
  AUTHENTICATION_GET_USER_FROM_TEMP_TOKEN_DISMISS_ERROR,
} from './constants';
import ApiService from '../../common/services/ApiService';

export function getUserFromTempToken(args) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: AUTHENTICATION_GET_USER_FROM_TEMP_TOKEN_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      ApiService.getUserFromTempToken(args).then(
        (res) => {
          dispatch({
            type: AUTHENTICATION_GET_USER_FROM_TEMP_TOKEN_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: AUTHENTICATION_GET_USER_FROM_TEMP_TOKEN_FAILURE,
            // data: { error: err },
            data: {
                error: {
                    message: (err && err.response && err.response.data && err.response.data.message) ? err.response.data.message : 'Invalid token!'
                }
            },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissGetUserFromTempTokenError() {
  return {
    type: AUTHENTICATION_GET_USER_FROM_TEMP_TOKEN_DISMISS_ERROR,
  };
}

export function useGetUserFromTempToken(params) {
  const dispatch = useDispatch();

  const { getUserFromTempTokenPending, getUserFromTempTokenError, user } = useSelector(
    state => ({
      user: state.authentication.user,
      getUserFromTempTokenPending: state.authentication.getUserFromTempTokenPending,
      getUserFromTempTokenError: state.authentication.getUserFromTempTokenError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(getUserFromTempToken(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetUserFromTempTokenError());
  }, [dispatch]);

  return {
    user: user,
    getUserFromTempToken: boundAction,
    getUserFromTempTokenPending,
    getUserFromTempTokenError,
    dismissGetUserFromTempTokenError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case AUTHENTICATION_GET_USER_FROM_TEMP_TOKEN_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getUserFromTempTokenPending: true,
        getUserFromTempTokenError: null,
      };

    case AUTHENTICATION_GET_USER_FROM_TEMP_TOKEN_SUCCESS:
      // The request is success
      return {
        ...state,
        getUserFromTempTokenPending: false,
        getUserFromTempTokenError: null,
        user: action.data,
      };

    case AUTHENTICATION_GET_USER_FROM_TEMP_TOKEN_FAILURE:
      // The request is failed
      return {
        ...state,
        getUserFromTempTokenPending: false,
        getUserFromTempTokenError: action.data.error,
      };

    case AUTHENTICATION_GET_USER_FROM_TEMP_TOKEN_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getUserFromTempTokenError: null,
      };

    default:
      return state;
  }
}
