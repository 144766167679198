import React, { useContext, useEffect } from 'react';
import { PageHeader } from '../common';
import { Card, CardContent } from '@material-ui/core';
import { MyProfileForm } from './index';
import AuthContext from '../authentication/AuthContext';
// import { useGetProfile } from './redux/hooks';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useUpdateProfile } from './redux/hooks';
// import AuthService from '../authentication/services/AuthService';
import Swal from "sweetalert2";
import { useGetCountries } from '../common/redux/getCountries';


const validationSchema = Yup.object({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  password: Yup.string(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
});


export default function MyProfile(props) {
  const user = useContext(AuthContext);
  const {updateProfile} = useUpdateProfile();
  const {getCountries, countries} = useGetCountries();

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  const formik = useFormik({
    initialValues: {
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      password: '',
      confirmPassword: '',
      email: user.email || '',
      phoneNumber: user.phoneNumber || '',
      title: user.title || '',
      profileImage: user.profileImage || '',
      backgroundImage: user.backgroundImage || '',

      owner: {
        ownerId: (user.owner && user.owner.ownerId) || '',
        company: (user.owner && user.owner.company) || '',
        address: {
          line1: (user.owner && user.owner.address.line1) || '',
          line2: (user.owner && user.owner.address.line2) || '',
          city: (user.owner && user.owner.address.city) || '',
          state: (user.owner && user.owner.address.state) || '',
          zipCode: (user.owner && user.owner.address.zipCode) || '',
          country: (user.owner && user.owner.address.country) || 'United States',
        }
      }
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: values => {
      updateProfile(values)
        .then(res => {
          Swal.fire({
            icon: 'success',
            text: res.message,
            timer: 2000
          });
        });
    },
  });

  return (
    <div className="profile-my-profile">
      <PageHeader location={props.location} />

      <form noValidate autoComplete="on" onSubmit={formik.handleSubmit}>
        <Card className="fob-content-body">
            <CardContent>
                 <MyProfileForm user={user} formik={formik} countries={countries} />
            </CardContent>
          </Card>
        </form>
    </div>
  );
};
