import React, { useEffect } from 'react';
import {
  Button, Container,
  DialogActions, DialogContent,
  DialogTitle,
  Divider, Grid,
  IconButton, TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import { useToggleAdminCommonDialog } from '../admin/redux/toggleAdminCommonDialog';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useGetStatesOfCountry } from '../common/redux/hooks';
import { findWhere } from 'underscore';
import { MySelect, LoadingButton, DialogBox } from '../common';


export default function AddAddressDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const toggleDialog = useSelector(state => state.admin.toggleDialog);
  const {toggleAdminCommonDialog} = useToggleAdminCommonDialog();
  const {getStatesOfCountry} = useGetStatesOfCountry();
  const states = useSelector(state => state.common.states);


  useEffect(() => {
    if (!props.formik.values.address.country) return;

    let country = findWhere(props.countries, {name: props.formik.values.address.country});

    if (!country) return;

    getStatesOfCountry(country.id);
  }, [props.formik.values.address.country, getStatesOfCountry, props.countries]);

  const handleSaveAddress = () => {
    if (props.formik.values.address.line1 && props.formik.values.address.city && props.formik.values.address.state && props.formik.values.address.zipCode) {
      props.formik.setFieldValue('address.hasAddress', true);
    } else {
      props.formik.setFieldValue('address.hasAddress', false);
    }

    if (props.setVisible) {
      props.setVisible(false);
    } else {
      toggleAdminCommonDialog.bind(this, 'AddAddressDialog', false, {})
    }
  };

  return (
    <DialogBox
      fullScreen={fullScreen}
      open={props.visible || (toggleDialog.name === 'AddAddressDialog' && toggleDialog.flag === true)}
      className="equipments-add-address-dialog fob-details-dialog fob-form-dialog">
        <DialogTitle>
          <Toolbar>
            <Typography variant="h5" display="inline">Add Address</Typography>
            <span style={{flexGrow: 1}}></span>
            <IconButton
              edge="end"
              color="inherit" aria-label="menu"
              disabled={props.loading}
              onClick={() => {
                if(!props.loading) {
                  if (props.setVisible) {
                    props.setVisible(false);
                  } else {
                    toggleAdminCommonDialog.bind(this, 'AddAddressDialog', false, {})
                  }
                }
              }}>
              <HighlightOffIcon />
            </IconButton>
          </Toolbar>
          <Divider />
        </DialogTitle>

        <DialogContent>
          <Container maxWidth="sm">
            <Grid container
                  md={12}
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={2}>
              <Grid container item md={12}>
                <TextField
                  id="address.line1"
                  label="Address Line 1"
                  InputProps={{disableUnderline: true}}
                  InputLabelProps={{ shrink: true }}
                  className="fob-filled-input-with-outside-label"
                  value={props.formik.values.address.line1}
                  onChange={props.formik.handleChange}
                  fullWidth
                />
              </Grid>

              <Grid container item md={12}>
                <TextField
                  id="address.line2"
                  label="Address Line 2"
                  InputProps={{disableUnderline: true}}
                  InputLabelProps={{ shrink: true }}
                  className="fob-filled-input-with-outside-label"
                  value={props.formik.values.address.line2}
                  onChange={props.formik.handleChange}
                  fullWidth
                />
              </Grid>

              <Grid item container md={12} className="two-input-row-grid">
                <Grid item container sm={12} md={6}>
                  <TextField
                    id="address.city"
                    label="City"
                    InputProps={{disableUnderline: true}}
                    InputLabelProps={{ shrink: true }}
                    className="fob-filled-input-with-outside-label"
                    value={props.formik.values.address.city}
                    onChange={props.formik.handleChange}
                    fullWidth
                  />
                </Grid>

                <Grid item container sm={12} md={6}>
                    <MySelect
                      id="address.state"
                      name="address.state"
                      label="State"
                      color="secondary"
                      placeholder="Select State"
                      value={props.formik.values.address.state}
                      onChange={(_, option) => {
                        props.formik.setFieldValue('address.state', option && option.name ? option.name : '');
                      }}
                      getOptionLabel={(option) => typeof option === 'string' ? option : option.name}
                      renderOption={(option) => option.name}
                      options={[{name: 'Select State', isLabel: true}].concat(states)}
                    />
                </Grid>
              </Grid>

              <Grid item container md={12} className="two-input-row-grid">
                <Grid item container sm={12} md={6}>
                  <TextField
                    id="address.zipCode"
                    label="Zip"
                    InputProps={{disableUnderline: true}}
                    InputLabelProps={{ shrink: true }}
                    className="fob-filled-input-with-outside-label"
                    value={props.formik.values.address.zipCode}
                    onChange={props.formik.handleChange}
                    fullWidth
                  />
                </Grid>

                <Grid item container sm={12} md={6}>
                  <MySelect
                    id="address.country"
                    name="address.country"
                    label="Country"
                    color="secondary"
                    placeholder="Select Country"
                    value={props.formik.values.address.country}
                    onChange={(_, option) => {
                      props.formik.setFieldValue('address.country', option && option.name ? option.name : '');
                      props.formik.setFieldValue('address.state', '');
                    }}
                    getOptionLabel={(option) => typeof option === 'string' ? option : option.name}
                    renderOption={(option) => option.name}
                    options={[{name: 'Select Country', isLabel: true}].concat(props.countries)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </DialogContent>

        <DialogActions>
          { !props.loading ?
            <Button variant="contained"
                    disableElevation
                    color="secondary"
                    onClick={props.isSubmitForm ? props.formik.handleSubmit : handleSaveAddress}
                    className="rounded-btn"
            >
              Save
            </Button>
            :
            <LoadingButton />
          }

          <Button variant="contained"
                  disableElevation
                  disabled={props.loading}
                  onClick={() => {
                    if(!props.loading) {
                      if (props.setVisible) {
                        props.setVisible(false);
                      } else {
                        toggleAdminCommonDialog.bind(this, 'AddAddressDialog', false, {})
                      }
                    }
                  }}
                  className="rounded-btn dark-btn"
          >
            Cancel
          </Button>
        </DialogActions>
    </DialogBox>
  );
};

