import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  EQUIPMENTS_SAVE_ACTIVITY_BEGIN,
  EQUIPMENTS_SAVE_ACTIVITY_SUCCESS,
  EQUIPMENTS_SAVE_ACTIVITY_FAILURE,
  EQUIPMENTS_SAVE_ACTIVITY_DISMISS_ERROR,
} from './constants';
import ApiService from '../../common/services/ApiService';


export function saveActivity(data) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: EQUIPMENTS_SAVE_ACTIVITY_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      let saveActivityPromise = data.files && data.files.length
        ? ApiService.saveActivityWithImage(data)
        : ApiService.saveActivity(data)

        saveActivityPromise.then(
            (res) => {
                dispatch({
                    type: EQUIPMENTS_SAVE_ACTIVITY_SUCCESS,
                    data: res,
                });
                resolve({...res, message: 'Successfully created activity.'});
            },
            // Use rejectHandler as the second argument so that render errors won't be caught.
            (err) => {
                dispatch({
                    type: EQUIPMENTS_SAVE_ACTIVITY_FAILURE,
                    data: {
                        error: {
                            message: err && err.response && err.response.data ? err.response.data.message : 'Unable to create activity.'
                        }
                    },
                });
                reject(err);
            },
        );
    });

    return promise;
  };
}

export function dismissSaveActivityError() {
  return {
    type: EQUIPMENTS_SAVE_ACTIVITY_DISMISS_ERROR,
  };
}

export function useSaveActivity(params) {
  const dispatch = useDispatch();

  const { saveActivityPending, saveActivityError } = useSelector(
    state => ({
      saveActivityPending: state.equipments.saveActivityPending,
      saveActivityError: state.equipments.saveActivityError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(saveActivity(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissSaveActivityError());
  }, [dispatch]);

  return {
    saveActivity: boundAction,
    saveActivityPending,
    saveActivityError,
    dismissSaveActivityError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case EQUIPMENTS_SAVE_ACTIVITY_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        saveActivityPending: true,
        saveActivityError: null,
      };

    case EQUIPMENTS_SAVE_ACTIVITY_SUCCESS:
      // The request is success
      return {
        ...state,
        saveActivityPending: false,
        saveActivityError: null,
      };

    case EQUIPMENTS_SAVE_ACTIVITY_FAILURE:
      // The request is failed
      return {
        ...state,
        saveActivityPending: false,
        saveActivityError: action.data.error,
      };

    case EQUIPMENTS_SAVE_ACTIVITY_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        saveActivityError: null,
      };

    default:
      return state;
  }
}
