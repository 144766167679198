import React, {useState} from 'react';
import {Grid, Paper, Typography, IconButton, InputBase, Button, CircularProgress} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReactFC from "react-fusioncharts";
import SearchIcon from '@material-ui/icons/Search';
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import MyDateRangePicker from "../../../common/MyDateRangePicker";
import {isObject} from 'underscore';

const CHART_HEIGHT = 500;

const commonDataSource = {
    theme: 'fusion', // 'fusion', 'gammel', 'candy', 'ocean', 'zune', 'carbon', 'umber'
    pieRadius: 120,
    showValues: '1',
    showLabels: '1',
    bgColor: '#fafbfc',
    // paletteColors: '#5338fc, #00c2f9, #4998ae, #ff9500',
    defaultCenterLabel: '100%',

    labelPosition: 'outside',
    labelFontSize: '14px',

    exportEnabled: '1',

    enableSmartLabels: '1',
    showPercentValues: '0',
    valuePosition: 'outside',
    showZeroPies: '1',
    showPercentInToolTip: '1',

    legendShadow: true,
    legendBgColor: '#ffffff',
    legendBgAlpha: '100',
    legendCaptionAlignment: 'center',

    centerLabelFontSize: 18,
    // centerLabelBold: true,
    centerLabelColor: '#8e98a2',

    legendNumColumns: 3,
    interactiveLegend: true,

    showToolTipShadow: true,
    toolTipBorderColor: '#eee',

    // captionPadding: 0,
    // chartLeftMargin: 0,
    // chartRightMargin: 0,
    // chartTopMargin: 0,
    //chartBottomMargin: 0,
};

const createDataSource = (data, otherChartOptions={}) => {
    return {
        chart: {
            ...commonDataSource,
            ...otherChartOptions
        },
        data: data,
    };
};

const getChartConfig = (data, height, chartType, otherChartOptions={}) => {
    return {
        type: chartType,
        width: '100%',
        height: height,
        dataFormat: 'json',
        dataSource: createDataSource(data, otherChartOptions),
    };
};

function FilterDateRange({
    dateRange,
    onDateRangeChanged,
}) {
    return (<MyDateRangePicker dateRange={dateRange}
                               onDateRangeChanged={onDateRangeChanged} />
    );
}

function SearchAssetsFilter({onSearchValueChanged, searchValue, assetsAddresses, handleApplyFilter}) {
    const initialInput = searchValue
                         ? (searchValue.hasOwnProperty('Description') ? searchValue.Description : searchValue)
                         : '';
    const [query, setQuery] = useState(initialInput);

    return (
            <Autocomplete
                options={assetsAddresses}
                getOptionLabel={(option) => option && option.hasOwnProperty('Description') ? option['Description'] : ''}
                autoComplete
                autoHighlight
                // freeSolo
                includeInputInList
                selectOnFocus
                // autoSelect
                onChange={(event, value) => {
                    onSearchValueChanged(value);
                }}
                value={searchValue}
                inputValue={query}
                onInputChange={(event, newInputValue) => {
                    setQuery(newInputValue);
                }}
                renderInput={(params) => {
                    return (
                        <Paper className="dashboard-chart-2d-doughnut-paper-filter-container"
                               ref={params.InputProps.ref}>
                            <IconButton aria-label="search">
                                <SearchIcon />
                            </IconButton>
                            <InputBase {...params.inputProps}
                                       type="text"
                                       onKeyDown={event => event.key === 'Enter' && handleApplyFilter()}
                                       placeholder="Filter by location"
                                       fullWidth
                            />
                        </Paper>
                    );
                }}
            />
    );
}

export default function Chart2DDoughnutPaper({
    data,
    loading,
    searchValue,
    onDateRangeChanged,
    dateRange,
    onSearchValueChanged,
    handleApplyFilter,
    title=null,
    showFilters=true,
    otherChartOptions={},
    customLegend=null,
    height=CHART_HEIGHT,
    assetsAddresses=[],
    chartType='doughnut2d',
}) {
  const chartConfigs = getChartConfig(data, height, chartType, otherChartOptions);
  const showSearchFilter = typeof onSearchValueChanged === 'function';

  return (
    <div className="dashboard-chart-2d-doughnut-paper"
         component={Paper}
         elevation={0}
         variant="outlined"
         direction="column"
         justifyContent="center"
         alignItems="center">

        {
            showFilters &&
            <Grid item
                  container
                  direction="row"
                  spacing={0}
                  className="dashboard-chart-2d-doughnut-paper-grid">
                {
                    showSearchFilter &&
                    <Grid container
                          item
                          xs={12}
                          sm={5}
                          md={5}
                          lg={5}>
                        <SearchAssetsFilter searchValue={searchValue}
                                            assetsAddresses={assetsAddresses}
                                            onSearchValueChanged={onSearchValueChanged}
                                            handleApplyFilter={handleApplyFilter} />
                    </Grid>
                }
                <Grid container
                      item
                      xs={12}
                      sm={5}
                      md={5}
                      lg={5}>
                    <FilterDateRange dateRange={dateRange} onDateRangeChanged={onDateRangeChanged} />
                </Grid>
                <Grid container
                      item
                      xs={12}
                      sm={2}
                      md={2}
                      lg={2}>
                    <Button variant="contained"
                            color="secondary"
                            onClick={handleApplyFilter}>Apply</Button>
                </Grid>
            </Grid>
        }

        {
            loading &&
            <CircularProgress />
        }

        {
            (!loading && data) && (
                <Grid item>
                    <ReactFC {...chartConfigs} />
                    {
                        customLegend &&
                        <Grid container
                              alignItems="center"
                              style={{justifyContent: 'center', marginBottom: '24px'}}>
                            <Grid item>
                                {customLegend}
                            </Grid>
                        </Grid>
                    }
                </Grid>
            )
        }

        {
            title &&
            <Grid item
                  container
                  alignItems="center"
                  style={{justifyContent: 'center'}}>
                <Grid item>
                    <Typography className="dashboard-chart-2d-doughnut-paper-bottom-title" variant="subtitle1" align="center">{title}</Typography>
                </Grid>
            </Grid>
        }
    </div>
  );
};
