import React, { useContext } from 'react';
import {
  Button,
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider, Grid,
  IconButton, MenuItem, TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { LoadingButton, DialogBox } from '../common';
import { useFormik } from 'formik';
import Swal from "sweetalert2";
import * as Yup from 'yup';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import { useToggleAdminCommonDialog } from '../admin/redux/toggleAdminCommonDialog';
import { useCreatePart } from './redux/hooks';
import { isEmpty } from 'underscore';
import Alert from '@material-ui/lab/Alert';
import AuthContext from "../authentication/AuthContext";
import {CONFIG} from './../../config';

const validationSchema = Yup.object({
  manufacturerId: Yup.string().required('Required'),
  categoryId: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
});

export default function CreatePartDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const user = useContext(AuthContext);

  const {manufacturersData, categoriesData} = props;

  const toggleDialog = useSelector(state => state.admin.toggleDialog);
  const {toggleAdminCommonDialog} = useToggleAdminCommonDialog();
  const {createPart, createPartPending, createPartError} = useCreatePart();

  const formik = useFormik({
    initialValues: {
      manufacturerId: user.roleId == CONFIG.ROLES.manufacturer.id ? user.manufacturerId : '',
      categoryId: '',
      description: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: values => {
      createPart(values)
        .then(res => {
          Swal.fire({
            icon: 'success',
            text: res.message,
            timer: 2000
          }).then(() => {
            toggleAdminCommonDialog('CreatePartDialog', false, {});
          });
        });
    },
  });

  return (
    <DialogBox
        fullScreen={fullScreen}
        open={toggleDialog.name === 'CreatePartDialog' && toggleDialog.flag === true}
        className="parts-create-part-dialog parts-part-details-dialog"
      >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          <Toolbar>
            <Typography variant="h5" display="inline">Create Asset</Typography>
            <span style={{flexGrow: 1}}></span>
            <IconButton
              edge="end"
              color="inherit" aria-label="menu"
              onClick={toggleAdminCommonDialog.bind(this, 'CreatePartDialog', false, {})}>
              <HighlightOffIcon />
            </IconButton>
          </Toolbar>
          <Divider />
        </DialogTitle>

        <DialogContent>
          <Container maxWidth="sm">
            <Grid item md={12}>
              {
                isEmpty(createPartError)
                  ? null
                  :
                  <Alert elevation={0} variant="standard" severity="error">
                    {createPartError.message}
                  </Alert>
              }
            </Grid>

            <Grid container
                  md={12}
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  id="manufacturerId"
                  name="manufacturerId"
                  select
                  label="Manufacturer"
                  value={formik.values.manufacturerId}
                  onChange={formik.handleChange}
                  fullWidth
                  InputProps={{disableUnderline: true}}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={formik.errors.manufacturerId ? formik.errors.manufacturerId : ''}
                  error={Boolean(formik.errors.manufacturerId)}
                  className="fob-filled-input-with-outside-label"
                >
                {
                  manufacturersData && manufacturersData.rows.map(item => <MenuItem value={item.manufacturerId}>{item.description}</MenuItem>)
                }
                </TextField>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                    id="categoryId"
                    name="categoryId"
                    select
                    label="Category"
                    value={formik.values.categoryId}
                    onChange={formik.handleChange}
                    fullWidth
                    InputProps={{disableUnderline: true}}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText={formik.errors.categoryId ? formik.errors.categoryId : ''}
                    error={Boolean(formik.errors.categoryId)}
                    className="fob-filled-input-with-outside-label"
                >
                  {
                    categoriesData && categoriesData.map(item => <MenuItem value={item.categoryID}>{item.description}</MenuItem>)
                  }
                </TextField>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  id="description"
                  label="Model Number"
                  InputProps={{disableUnderline: true}}
                  InputLabelProps={{ shrink: true }}
                  className="fob-filled-input-with-outside-label"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  helperText={formik.errors.description ? formik.errors.description : ''}
                  error={Boolean(formik.errors.description)}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Container>
        </DialogContent>

        <DialogActions>
          {
            createPartPending === true
              ? <LoadingButton/>
              : <Button variant="contained"
                        disableElevation
                        color="secondary"
                        type="submit"
                        className="rounded-btn"
              >
                Save
              </Button>
          }

          <Button variant="contained"
                  disableElevation
                  onClick={() => {
                    formik.resetForm();
                    toggleAdminCommonDialog('CreatePartDialog', false, {});
                  }}
                  className="rounded-btn dark-btn"
                  disabled={createPartPending === true}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </DialogBox>
  );
};

