import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  PARTS_GET_ALL_PARTS_BEGIN,
  PARTS_GET_ALL_PARTS_SUCCESS,
  PARTS_GET_ALL_PARTS_FAILURE,
  PARTS_GET_ALL_PARTS_DISMISS_ERROR,
} from './constants';
import ApiService from '../../common/services/ApiService';

export function getAllParts(manufacturerId) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: PARTS_GET_ALL_PARTS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      ApiService.getAllParts(manufacturerId).then(
        (res) => {
          dispatch({
            type: PARTS_GET_ALL_PARTS_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: PARTS_GET_ALL_PARTS_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissGetAllPartsError() {
  return {
    type: PARTS_GET_ALL_PARTS_DISMISS_ERROR,
  };
}

export function useGetAllParts(params) {
  const dispatch = useDispatch();

  const { getAllPartsPending, getAllPartsError, partsData } = useSelector(
    state => ({
        getAllPartsPending: state.parts.getAllPartsPending,
        getAllPartsError: state.parts.getAllPartsError,
        partsData: state.parts.partsData,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(getAllParts(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetAllPartsError());
  }, [dispatch]);

  return {
    partsData: partsData,
    getAllParts: boundAction,
    getAllPartsPending,
    getAllPartsError,
    dismissGetAllPartsError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case PARTS_GET_ALL_PARTS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getAllPartsPending: true,
        getAllPartsError: null,
      };

    case PARTS_GET_ALL_PARTS_SUCCESS:
      // The request is success
      return {
        ...state,
        getAllPartsPending: false,
        getAllPartsError: null,
        partsData: action.data,
      };

    case PARTS_GET_ALL_PARTS_FAILURE:
      // The request is failed
      return {
        ...state,
        getAllPartsPending: false,
        getAllPartsError: action.data.error,
      };

    case PARTS_GET_ALL_PARTS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getAllPartsError: null,
      };

    default:
      return state;
  }
}
