import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  EQUIPMENTS_TOGGLE_WARRANTY_DIALOG,
} from './constants';
import { isNull } from 'underscore';

export function toggleWarrantyDialog(flag=null) {
  return {
    type: EQUIPMENTS_TOGGLE_WARRANTY_DIALOG,
    flag: flag,
  };
}

export function useToggleWarrantyDialog() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => dispatch(toggleWarrantyDialog(...params)), [dispatch]);
  return { toggleWarrantyDialog: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case EQUIPMENTS_TOGGLE_WARRANTY_DIALOG:
      return {
        ...state,
        isWarrantyDialogVisible: isNull(action.flag) ? !state.isWarrantyDialogVisible : action.flag,
      };

    default:
      return state;
  }
}
