import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  COMMON_TOGGLE_SIDENAV,
} from './constants';

export function toggleSidenav() {
  return {
    type: COMMON_TOGGLE_SIDENAV,
  };
}

export function useToggleSidenav() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => dispatch(toggleSidenav(...params)), [dispatch]);
  return { toggleSidenav: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_TOGGLE_SIDENAV:
      return {
        ...state,
        drawSidenav: !state.drawSidenav,
      };

    default:
      return state;
  }
}
