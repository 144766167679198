import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import { useToggleAdminCommonDialog } from '../admin/redux/toggleAdminCommonDialog';
import {
  Button,
  Container,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  Divider, FormControl, Grid,
  IconButton, InputLabel, TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useFormik } from 'formik';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useCreateWarranty } from './redux/hooks';
import * as Yup from 'yup';
import {CONFIG} from './../../config';
import { LoadingButton, DialogBox } from '../common';
import {isEmpty, clone} from 'underscore';
import Swal from "sweetalert2";
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import {useGetEquipmentDetails} from "./redux/getEquipmentDetails";


const validationSchema = Yup.object({
  description: Yup.string().required('Required'),
  startDate: Yup.string().required('Required'),
  endDate: Yup.string().required('Required'),
});


export default function AddWarrantyDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const toggleDialog = useSelector(state => state.admin.toggleDialog);
  const {toggleAdminCommonDialog} = useToggleAdminCommonDialog();
  const {createWarranty, createWarrantyPending, createWarrantyError} = useCreateWarranty();
  const {getEquipmentDetails} = useGetEquipmentDetails();

  const formik = useFormik({
    initialValues: {
      description: (props.formik && props.formik.values.warranty.description) ? props.formik.values.warranty.description : '',
      startDate: (props.formik && props.formik.values.warranty.startDate) ? moment(props.formik.values.warranty.startDate).parseZone() : '',
      endDate: (props.formik && props.formik.values.warranty.endDate) ? moment(props.formik.values.warranty.endDate).parseZone() : ''
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      if(!props.formik) {
        let data = clone(values);
        data.assetId = props.assetId;
        createWarranty(data)
          .then(res => {
            formik.resetForm();
            getEquipmentDetails(props.assetId);
            Swal.fire({
              icon: 'success',
              text: res.message,
              timer: 2000
            });
            toggleAdminCommonDialog('AddWarrantyDialog', false, {});
          });
      } else {
        props.formik.setFieldValue('warranty', values);
        formik.resetForm();
      }
    },
  });

  return (
    <DialogBox
      fullScreen={fullScreen}
      open={toggleDialog.name === 'AddWarrantyDialog' && toggleDialog.flag === true}
      className="equipments-add-warranty-dialog fob-details-dialog fob-form-dialog">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          <Toolbar>
            <Typography variant="h5" display="inline">Add Warranty</Typography>
            <span style={{flexGrow: 1}}></span>
            <IconButton
              edge="end"
              color="inherit" aria-label="menu"
              onClick={toggleAdminCommonDialog.bind(this, 'AddWarrantyDialog', false, {})}>
              <HighlightOffIcon />
            </IconButton>
          </Toolbar>
          <Divider />
        </DialogTitle>

        <DialogContent>
          <Container maxWidth="sm">
            <Grid container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={2}>
              { !isEmpty(createWarrantyError) &&
                <Grid item container md={12} spacing={2}>
                  <Grid item md={12}>
                    <Alert elevation={0} variant="standard" severity="error">
                        {createWarrantyError.message}
                    </Alert>
                  </Grid>
                </Grid>
              }
              <Grid item container spacing={2}>
                <Grid item container sm={12} md={6}>
                  <TextField
                    id="description"
                    label="Description"
                    InputProps={{disableUnderline: true}}
                    InputLabelProps={{ shrink: true }}
                    className="fob-filled-input-with-outside-label"
                    value={formik.values.description}
                    helperText={formik.errors.description ? formik.errors.description : ''}
                    error={Boolean(formik.errors.description)}
                    onChange={formik.handleChange}
                    fullWidth
                  />
                </Grid>

                <Grid item container sm={12} md={6}>
                  <FormControl
                    fullWidth
                    className="fob-filled-input-with-outside-label">
                    <InputLabel shrink id="warranty-enddate-label">Warranty Date Start</InputLabel>
                    <KeyboardDatePicker
                      fullWidth
                      autoOk={true}
                      variant="inline"
                      inputVariant="filled"
                      format={CONFIG.DATE_DISPLAY_FORMAT}
                      margin="normal"
                      id="startDate"
                      labelId="warranty-startdate-label"
                      label={null}
                      className="fob-date-picker"
                      value={formik.values.startDate}
                      helperText={formik.errors.startDate ? formik.errors.startDate : ''}
                      error={Boolean(formik.errors.startDate)}
                      onChange={date => {
                        formik.setFieldValue('startDate', date);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item container spacing={2}>
                <Grid item container sm={12} md={6}>
                  <FormControl
                    fullWidth
                    className="fob-filled-input-with-outside-label">
                    <InputLabel shrink id="warranty-enddate-label">Warranty Date End</InputLabel>
                    <KeyboardDatePicker
                      autoOk={true}
                      variant="inline"
                      inputVariant="filled"
                      format={CONFIG.DATE_DISPLAY_FORMAT}
                      margin="normal"
                      id="endDate"
                      labelId="warranty-enddate-label"
                      label={null}
                      className="fob-date-picker"
                      value={formik.values.endDate}
                      helperText={formik.errors.endDate ? formik.errors.endDate : ''}
                      error={Boolean(formik.errors.endDate)}
                      onChange={date => {
                        formik.setFieldValue('endDate', date);
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>

            </Grid>
          </Container>
        </DialogContent>

        <DialogActions>
        <Grid container
            direction="row"
            justify="center"
            alignItems="center">
            <Grid item container xs={5} sm={5} md={4} spacing={3}>
              { createWarrantyPending === true ?
                <LoadingButton />
              :
                <Button variant="contained"
                      disableElevation
                      color="secondary"
                      type="submit"
                      className="rounded-btn"
                >
                  Save
                </Button>
              }
            </Grid>
            <Grid item container xs={5} sm={5} md={4} spacing={3}>
              <Button variant="contained"
                      disableElevation
                      onClick={() => {
                        formik.resetForm();
                        toggleAdminCommonDialog(this, 'AddWarrantyDialog', false, {});
                      }}
                      disabled={createWarrantyPending}
                      className="rounded-btn dark-btn"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </DialogBox>
  );
};

