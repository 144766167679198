import React, { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
// import * as Yup from 'yup';
import { useToggleManufacturerDetailsDialog } from './redux/toggleManufacturerDetailsDialog';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { ManufacturerDetailsForm } from './index';
import { useGetManufacturerDetails } from './redux/hooks';
import { isObject } from 'underscore';
import {useUpdateManufacturer} from "./redux/updateManufacturer";
import Swal from "sweetalert2";
import LoadingButton from "../common/LoadingButton";
import {useGetManufacturers} from "./redux/getManufacturers";


// const validationSchema = Yup.object({
//   file: Yup.string().required('Required'),
// });


export default function ManufacturerDetailsDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const {getManufacturerDetails} = useGetManufacturerDetails();
  const {updateManufacturer, updateManufacturerPending} = useUpdateManufacturer();
  const manufacturerId = useSelector(state => state.manufacturers.manufacturerId);
  const manufacturerDetails = useSelector(state => state.manufacturers.manufacturerDetails);
  const {toggleManufacturerDetailsDialog} = useToggleManufacturerDetailsDialog();
  const isManufacturerDetailsDialogVisible = useSelector(state => state.manufacturers.isManufacturerDetailsDialogVisible);
  const {getManufacturers} = useGetManufacturers();

  const formik = useFormik({
    initialValues: {
      description: manufacturerDetails && manufacturerDetails.description,
      address: {
          line1: manufacturerDetails && manufacturerDetails.address.line1,
          line2: manufacturerDetails && manufacturerDetails.address.line2,
          city: manufacturerDetails && manufacturerDetails.address.city,
          state: manufacturerDetails && manufacturerDetails.address.state,
          zipCode: manufacturerDetails && manufacturerDetails.address.zipCode,
          country: manufacturerDetails && manufacturerDetails.address.country,
      }
    },
    enableReinitialize: true,
    // validationSchema,
    onSubmit: values => {
      updateManufacturer(manufacturerDetails.manufacturerId, values.description, values.address)
          .then(res => {
            Swal.fire({
              icon: 'success',
              text: res.message,
              timer: 2000
            }).then(() => {
              toggleManufacturerDetailsDialog(false);
              getManufacturers(1, 1000);
            });
          });
    },
  });

  useEffect(() => {
    if (manufacturerId && !isObject(manufacturerId)) getManufacturerDetails(manufacturerId)
  }, [manufacturerId, getManufacturerDetails]);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isManufacturerDetailsDialogVisible}
      className="manufacturers-manufacturer-details-dialog fob-details-dialog">

      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          <Toolbar>
            <Typography variant="h5" display="inline">Manufacturer</Typography>
            <span style={{flexGrow: 1}}></span>
            <IconButton
              edge="end"
              color="inherit" aria-label="menu"
              onClick={toggleManufacturerDetailsDialog.bind(this, false)}>
              <HighlightOffIcon />
            </IconButton>
          </Toolbar>

          <Divider />

        </DialogTitle>

        <DialogContent>
            {
                props.countries
                ? <ManufacturerDetailsForm isEdit={true} formik={formik} countries={props.countries}/>
                : null
            }
        </DialogContent>

        <DialogActions>
            {
              updateManufacturerPending === true
              ? <LoadingButton/>
              : <Button variant="contained"
                            disableElevation
                            color="secondary"
                            type="submit"
                            className="rounded-btn"
                  >Save</Button>
            }

          <Button variant="contained"
                  disableElevation
                  onClick={toggleManufacturerDetailsDialog.bind(this, false)}
                  className="rounded-btn dark-btn"
                  disabled={updateManufacturerPending === true}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

