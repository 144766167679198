import React, {Fragment, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider, Grid,
  IconButton,
  Toolbar,
  Typography
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useToggleActivityDetailsDialog } from './redux/toggleActivityDetailsDialog';
import { useFormik } from 'formik';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
// import * as Yup from 'yup';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ActivityDetailsDialogContent from './ActivityDetailsDialogContent';
import { isEmpty, clone } from 'underscore';
import ActivityDetailsAddDialogContent from './ActivityDetailsAddDialogContent';
import { useGetActivityDetails, useGetActivityTypes, useGetShippers, useSaveActivity } from './redux/hooks';
import Swal from "sweetalert2";
import { LoadingButton } from '../common';
import Alert from '@material-ui/lab/Alert';
import { AddAddressDialog } from './index';
import StringHelper from "../common/services/StringHelper";


export default function ActivityDetailsDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {toggleActivityDetailsDialog} = useToggleActivityDetailsDialog();
  const isActivityDetailsDialogVisible = useSelector(state => state.equipments.isActivityDetailsDialogVisible);
  const selectedActivity = useSelector(state => state.equipments.selectedActivity);
  const {getActivityDetails} = useGetActivityDetails();
  const {getActivityTypes, activityTypeData} = useGetActivityTypes();
  const {getShippers, shippersListData} = useGetShippers();
  const {saveActivity, saveActivityPending, saveActivityError, dismissSaveActivityError} = useSaveActivity();
  const [isAddressDialogVisible, setIsAddressDialogVisible] = useState(false);
  const newOwners = useSelector(state => state.equipments.newOwners);

  console.log('selectedActivity: ',selectedActivity);

  let initialValues = {
    ownerId: selectedActivity && selectedActivity.equipmentDetails ? selectedActivity.equipmentDetails.ownerId : '',
    activity: '',
    notes: '',
    activityTypeId: '',
    date: '',
    authorizedBy: '',
    assetId: selectedActivity && selectedActivity.equipmentDetails ? selectedActivity.equipmentDetails.assetId : '',
    cost: '',
    repairTime: '',
    address: {
      line1: '',
      city: '',
      zipCode: '',
      state: '',
      country: 'United States',
    },
    files: []
  };

  const formik = useFormik({
      initialValues: initialValues,
      enableReinitialize: true,
      onSubmit: (values, {resetForm}) => {
        let data = clone(values);
        data.date = StringHelper.parseDateForSave(values.date);
        data.assetId = selectedActivity && selectedActivity.equipmentDetails ? selectedActivity.equipmentDetails.assetId : '';
        saveActivity(data)
            .then(res => {
                Swal.fire({
                    icon: 'success',
                    text: res.message,
                    timer: 2000
                }).then(() => {
                    resetForm(initialValues);
                    toggleActivityDetailsDialog(false, {}, {}, false, Date.now());
                });
            });
      },
  });

  useEffect(() => {
    getActivityTypes();
  }, [getActivityTypes]);

  useEffect(() => {
    getShippers();
  }, [getShippers]);

  useEffect(() => {
    getActivityDetails(selectedActivity);

    return () => {
      dismissSaveActivityError();
    };
  }, [getActivityDetails, selectedActivity, dismissSaveActivityError]);

  useEffect(() => {
      if (Array.isArray(newOwners) && newOwners.length && formik.values.ownerId !== newOwners[0].ownerId) {
          formik.setFieldValue('ownerId', newOwners[0].ownerId);
      }
      // else if (!isEmpty(selectedActivity)
      //     && selectedActivity.equipmentDetails.ownerId
      //     && formik.values.ownerId !== selectedActivity.equipmentDetails.ownerId)
      // {
      //     formik.setFieldValue('ownerId', selectedActivity.equipmentDetails.ownerId);
      // }
  }, [formik, newOwners]);

  useEffect(() => {
    return () => {
      toggleActivityDetailsDialog(false, {}, {}, false, null);
    }
  }, [toggleActivityDetailsDialog]);

  if (isEmpty(selectedActivity)) return null;

  if(!selectedActivity.isAdd) {
    if (isEmpty(selectedActivity.activity)) return null;
  }

  return (
    <Fragment>
      <AddAddressDialog formik={formik}
                        countries={props.countries}
                        visible={isAddressDialogVisible}
                        setVisible={setIsAddressDialogVisible} />
    <Dialog
      fullScreen={fullScreen}
      open={isActivityDetailsDialogVisible}
      className="equipments-activity-details-dialog fob-details-dialog"
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          <Toolbar>
            <Typography variant="h5" display="inline">{selectedActivity.isAdd ? 'Activity Details Add' : 'Activity Details View'}</Typography>
            <span style={{flexGrow: 1}}></span>
            <IconButton
              edge="end"
              color="inherit" aria-label="menu"
              onClick={toggleActivityDetailsDialog.bind(this, false)}>
              <HighlightOffIcon />
            </IconButton>
          </Toolbar>
          <Divider />
        </DialogTitle>

        <DialogContent>
          <Grid item md={12} spacing={1}>
            {
              isEmpty(saveActivityError)
                ? null
                :
                <Alert className="fob-alert-container" elevation={0} variant="standard" severity="error">
                  {saveActivityError.message}
                </Alert>
            }
          </Grid>

          {
            isEmpty(selectedActivity)
            ? null
            : (
               selectedActivity.isAdd
               ? <ActivityDetailsAddDialogContent
                       activityDetails={selectedActivity}
                       formik={formik}
                       user={props.user}
                       countries={props.countries}
                       activityTypeData={activityTypeData}
                       shippersListData={shippersListData}
                       setIsAddressDialogVisible={setIsAddressDialogVisible}
                       ownersData={props.ownersData}
                   />
               : <ActivityDetailsDialogContent
                       user={props.user}
                       activityDetails={selectedActivity}
                       shippersListData={shippersListData}
                   />
              )
          }
        </DialogContent>

        <DialogActions>
          {
            selectedActivity.isAdd
            ? (
              saveActivityPending === true
               ? <LoadingButton/>
               : <Button variant="contained"
                       disableElevation
                       color="secondary"
                       type="submit"
                       className="rounded-btn"
              >
                Save
              </Button>
              )
            : null
          }
          <Button variant="contained"
                  disableElevation
                  onClick={() => {
                    formik.resetForm();
                    toggleActivityDetailsDialog(false);
                  }}
                  disabled={saveActivityPending === true}
                  className="rounded-btn dark-btn"
          >{selectedActivity.isAdd ? "Cancel" : "Close"}</Button>
        </DialogActions>
      </form>
    </Dialog>


    </Fragment>
  );
};

