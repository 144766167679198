import React, { useEffect } from 'react';
import { PageHeader } from '../common';
import { useSelector } from 'react-redux';
import {
  useGetManufacturers,
  useToggleManufacturerDetailsDialog,
  useToggleUploadManufacturersDialog,
} from './redux/hooks';
import {
  ManufacturerDetailsDialog,
  ManufacturersTable,
  UploadManufacturersDialog,
  CreateManufacturerDialog
} from './index';
import {useGetCountries} from "../common/redux/getCountries";
import { useToggleAdminCommonDialog } from '../admin/redux/toggleAdminCommonDialog';
import { isEmpty } from 'underscore';


export default function ManufacturerList(props) {
  const {getManufacturers} = useGetManufacturers();
  const {toggleUploadManufacturersDialog} = useToggleUploadManufacturersDialog();
  const {toggleManufacturerDetailsDialog} = useToggleManufacturerDetailsDialog();
  const manufacturersData = useSelector(state => state.manufacturers.manufacturersData);
  const {getCountries, countries} = useGetCountries();
  const {toggleAdminCommonDialog} = useToggleAdminCommonDialog();
  const newManufacturers = useSelector(state => state.manufacturers.newManufacturers);

  useEffect(() => {
    getManufacturers(1, 1000);
  }, [getManufacturers]);

  useEffect(() => {
    if (!isEmpty(newManufacturers)) getManufacturers(1, 1000);
  }, [newManufacturers, getManufacturers]);

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  return (
    <div className="manufacturers-manufacturer-list">
      <PageHeader location={props.location} />

      <ManufacturersTable data={manufacturersData}
                          history={props.history}
                          toggleUploadManufacturersDialog={toggleUploadManufacturersDialog}
                          toggleManufacturerDetailsDialog={toggleManufacturerDetailsDialog}
                          onClickAddBtn={() => {
                            toggleAdminCommonDialog('CreateManufacturerDialog', true, {});
                          }}
      />

      <ManufacturerDetailsDialog countries={countries} />

      <UploadManufacturersDialog />
      <CreateManufacturerDialog countries={countries} />
    </div>
  );
};
