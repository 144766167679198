import React from 'react';
import {
  Button,
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider, Grid,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { isEmpty } from 'underscore';
import Alert from '@material-ui/lab/Alert';
import { LoadingButton , DialogBox } from '../common';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Swal from "sweetalert2";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useToggleAdminCommonDialog } from '../admin/redux/toggleAdminCommonDialog';
import { useSaveManufacturer } from './redux/hooks';
import { ManufacturerDetailsForm } from './index';


export default function CreateManufacturerDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const {toggleAdminCommonDialog, toggleDialog} = useToggleAdminCommonDialog();
  const {saveManufacturer, saveManufacturerError, saveManufacturerPending} = useSaveManufacturer();

  const validationSchema = Yup.object({
    description: Yup.string().required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      description: '',
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zipCode: '',
        country: 'United States',
        hasAddress: false,
      },
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: values => {
      console.log('onSubmit: ',values);
      saveManufacturer(values)
        .then(res => {
          formik.resetForm();
          Swal.fire({
            icon: 'success',
            text: res.message,
            timer: 2000
          }).then(() => {
            toggleAdminCommonDialog('CreateManufacturerDialog', false, {});
          });
        });
    },
  });

  return (
      <DialogBox
        fullScreen={fullScreen}
        open={toggleDialog.name === 'CreateManufacturerDialog' && toggleDialog.flag === true}
        className="manufacturers-create-manufacturer-dialog manufacturers-manufacturer-details-dialog fob-details-dialog">

        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>
            <Toolbar>
              <Typography variant="h5" display="inline">Manufacturer</Typography>
              <span style={{flexGrow: 1}}></span>
              <IconButton
                edge="end"
                color="inherit" aria-label="menu"
                onClick={toggleAdminCommonDialog.bind(this, 'CreateManufacturerDialog', false, {})}>
                <HighlightOffIcon />
              </IconButton>
            </Toolbar>
            <Divider />
          </DialogTitle>

          <DialogContent>

            <Container maxWidth="sm">
              <Grid item md={12}>
                {
                  isEmpty(saveManufacturerError)
                    ? null
                    :
                    <Alert elevation={0} variant="standard" severity="error">
                      {saveManufacturerError.message}
                    </Alert>
                }
              </Grid>

              <ManufacturerDetailsForm formik={formik} countries={props.countries} />
            </Container>
          </DialogContent>

          <DialogActions>
            {
              saveManufacturerPending === true
                ? <LoadingButton/>
                : <Button variant="contained"
                          disableElevation
                          color="secondary"
                          type="submit"
                          className="rounded-btn"
                >
                  Save
                </Button>
            }


            <Button variant="contained"
                    disableElevation
                    onClick={() => {
                      formik.resetForm();
                      toggleAdminCommonDialog('CreateManufacturerDialog', false, {});
                    }}
                    className="rounded-btn dark-btn"
                    disabled={saveManufacturerPending === true}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </DialogBox>
  );
};

