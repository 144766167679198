import React, {useState } from 'react';
import {Grid, MenuItem, Select, TextField, FormControl, InputLabel, Typography, Tooltip} from '@material-ui/core';
// import { useToggleAddManufacturerDialog } from '../manufacturers/redux/toggleAddManufacturerDialog';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { AddAddressDialog } from './index';
import { AddAddressButton } from '../common';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {CONFIG} from './../../config';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {isUndefined} from 'underscore';


export default function AddEquipmentForm(props) {
  // const {toggleAddManufacturerDialog} = useToggleAddManufacturerDialog();
  const [isAddressDialogVisible, setIsAddressDialogVisible] = useState(false);

  const handleDateChange = (date, type) => {
    props.formik.setFieldValue(type, date);
  };

  return (
    <div className="equipments-add-equipment-form">
        <Grid container
              lg={12}
              direction="row"
              justify="center"
              alignItems="center"
              spacing={2}>

            <Grid item container lg={12}>
                <FormControl
                    fullWidth
                    className="fob-filled-input-with-outside-label">
                    <InputLabel shrink id="manufacturerId-select-label" className="form-label-with-icon">Manufacturer <Tooltip
                        title="If the manufacture you need is not listed please contact support@blockchainfob.com"
                        placement="top" arrow leaveDelay={500}>
                        <InfoOutlinedIcon/>
                    </Tooltip> </InputLabel>
                    <Autocomplete
                        className="common-my-select-without-label"
                        options={[{description: 'Select Manufacturer', isLabel: true}].concat(props.manufacturersData.rows)}
                        getOptionLabel={(option) => typeof option === 'string' ? '' : option.description}
                        onChange={(e, option) => props.formik.setFieldValue('manufacturerId', option.manufacturerId)}
                        onBlur={() => props.formik.setTouched({'manufacturerId': true})}
                        getOptionDisabled={(option) => option && option.isLabel}
                        disableClearable
                        autoHighlight
                        fullWidth
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                className="fob-filled-input-with-outside-label common-my-select"
                                label={null}
                                color="secondary"
                                fullWidth
                                value={props.formik.values.manufacturerId}
                                placeholder="Select Manufacturer"
                            />
                        )}
                    />
                </FormControl>
            </Grid>

          <Grid item container lg={12}>
              <FormControl
                  fullWidth
                  className="fob-filled-input-with-outside-label">
                  <InputLabel shrink id="asset-select-label">Asset</InputLabel>
                  <Autocomplete
                      className="common-my-select-without-label"
                      options={[{
                          description: 'Select Asset',
                          isLabel: true
                      }].concat(props.partsData.rows)}
                      getOptionLabel={(option) => typeof option === 'string' ? '' : option.description}
                      onChange={(e, option) => props.formik.setFieldValue('partId', option.partId)}
                      onBlur={() => props.formik.setTouched({'partId': true})}
                      getOptionDisabled={(option) => option && option.isLabel}
                      disableClearable
                      autoHighlight
                      fullWidth
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              className="fob-filled-input-with-outside-label common-my-select"
                              label={null}
                              color="secondary"
                              fullWidth
                              value={props.formik.values.partId}
                              placeholder="Select Asset"
                          />
                      )}
                  />
              </FormControl>
          </Grid>

            <Grid item container lg={12}>
                <TextField
                    id="serialNumber"
                    label="Serial Number"
                    InputProps={{disableUnderline: true}}
                    InputLabelProps={{shrink: true}}
                    className="fob-filled-input-with-outside-label"
                    value={props.formik.values.serialNumber}
                    onChange={props.formik.handleChange}
                    fullWidth
                />
            </Grid>

            <Grid item container lg={12}>
                <TextField
                    id="latId"
                    label="LAT ID"
                    InputProps={{disableUnderline: true}}
                    InputLabelProps={{shrink: true}}
                    className="fob-filled-input-with-outside-label"
                    value={props.formik.values.latId}
                    onChange={props.formik.handleChange}
                    fullWidth
                />
            </Grid>

          <Grid item container lg={12}>
            <FormControl
              fullWidth
              className="fob-filled-input-with-outside-label">
              <InputLabel shrink id="manufacturedate-label">Manufacture Date</InputLabel>
              <KeyboardDatePicker
                autoOk={true}
                variant="inline"
                inputVariant="filled"
                format={CONFIG.DATE_DISPLAY_FORMAT}
                margin="normal"
                id="manufactureDate"
                labelId="manufacturedate-label"
                label={null}
                fullWidth
                className="fob-date-picker"
                value={props.formik.values.manufactureDate}
                onChange={(date) => {
                  handleDateChange(date, 'manufactureDate');
                }}
              />
            </FormControl>
          </Grid>

            <Grid item container lg={12}>
                <FormControl
                    fullWidth
                    className="fob-filled-input-with-outside-label">
                    <InputLabel shrink id="owner-select-label">Owner</InputLabel>
                    <Select
                        id="ownerId"
                        name="ownerId"
                        className="fob-filled-input-with-outside-label"
                        label="Owner"
                        placeholder="Select Owner"
                        color="secondary"
                        value={props.formik.values.ownerId}
                        onChange={props.formik.handleChange}
                        disabled={props.user.roleId !== 1}
                    >
                        {
                            props.ownersData && props.ownersData.rows.map(item => <MenuItem
                                value={item.ownerId}>{item.company}</MenuItem>)
                        }
                    </Select>
                </FormControl>
            </Grid>

            <Grid item container lg={12}>
                <TextField
                    id="notes"
                    label="Description"
                    multiline
                    rows={5}
                    InputProps={{disableUnderline: true}}
                    InputLabelProps={{shrink: true}}
                    className="fob-filled-input-with-outside-label"
                    value={props.formik.values.notes}
                    onChange={props.formik.handleChange}
                    fullWidth
                />
            </Grid>

            <Grid item container lg={12}>
              <FormControl
                fullWidth
                className="fob-filled-input-with-outside-label">
                <InputLabel shrink id="dateofinstall-label">Date of Install</InputLabel>
                <KeyboardDatePicker
                    autoOk={true}
                    variant="inline"
                    inputVariant="filled"
                    format={CONFIG.DATE_DISPLAY_FORMAT}
                    margin="normal"
                    id="dateOfInstall"
                    labelId="dateofinstall-label"
                    label={null}
                    fullWidth
                    className="fob-date-picker"
                    value={props.formik.values.dateOfInstall}
                    onChange={(date) => {
                      handleDateChange(date, 'dateOfInstall');
                    }}
                  />
              </FormControl>
            </Grid>

            <Grid item container lg={12} className="fob-input-btn-form-control">
                    <Typography variant="label" component="label" display="block" fullWidth>Location</Typography>
              {
                props.formik.values.address.hasAddress
                ?
                  <div>
                    <p>{props.formik.values.address.line1}</p>
                    <p>{props.formik.values.address.city} {props.formik.values.address.state} {props.formik.values.address.zipCode}</p>
                  </div>
                : <AddAddressButton onClick={() => {
                                setIsAddressDialogVisible(true);
                            }}
                    />
              }
            </Grid>
        </Grid>

        <AddAddressDialog formik={props.formik}
                          countries={props.countries}
                          visible={isAddressDialogVisible}
                          setVisible={setIsAddressDialogVisible}
        />
    </div>
  );
};

