import React from 'react';
import { Divider, Grid, TextField, Typography } from '@material-ui/core';
import ApartmentIcon from '@material-ui/icons/Apartment';


export default function OfficeAddressInfo(props) {
  return (
    <div className="profile-office-address-info my-profile-form-item-group">

      <Grid container
            direction="column"
      >
        <Grid container item className="my-profile-form-item-group-header">
          <ApartmentIcon />
          <Typography variant="h6" component="h6" color="primary">COMPANY INFO</Typography>
        </Grid>

        <Divider />

        <Grid container
              item
              direction="row"
              spacing={2}>
          <Grid container item lg={12}>
            <TextField
              id="owner.company"
              label="Company Name"
              value={props.formik.values.owner.company}
              onChange={props.formik.handleChange}
              fullWidth
              InputProps={{disableUnderline: true}}
              InputLabelProps={{
                shrink: true,
              }}
              className="fob-filled-input-with-outside-label"
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

