import countryList from './assets/country.json';

// Get a list of all countries.
const getAllCountries = () => {
    return countryList;
};

export {
    getAllCountries,
};
