import { App } from '../features/home';
import { PageNotFound } from '../features/common';
import homeRoute from '../features/home/route';
import commonRoute from '../features/common/route';
import _ from 'lodash';
import authenticationRoute from '../features/authentication/route';
import equipmentsRoute from '../features/equipments/route';
import adminRoute from '../features/admin/route';
import profileRoute from '../features/profile/route';
import helpRoute from '../features/help/route';
import searchRoute from '../features/search/route';
import manufacturersRoute from '../features/manufacturers/route';
import partsRoute from '../features/parts/route';
import rolesRoute from '../features/roles/route';
import categoriesRoute from '../features/categories/route';
import dashboardRoute from '../features/dashboard/route';

// NOTE: DO NOT CHANGE the 'childRoutes' name and the declaration pattern.
// This is used for Rekit cmds to register routes config for new features, and remove config when remove features, etc.
const childRoutes = [
  homeRoute,
  commonRoute,
  authenticationRoute,
  equipmentsRoute,
  adminRoute,
  profileRoute,
  helpRoute,
  searchRoute,
  manufacturersRoute,
  partsRoute,
  rolesRoute,
  categoriesRoute,
  dashboardRoute,
];

const routes = [{
  path: '/',
  component: App,
  childRoutes: [
    ...childRoutes,
    { path: '*', name: 'Page not found', component: PageNotFound },
  ].filter(r => r.component || (r.childRoutes && r.childRoutes.length > 0)),
}];

// Handle isIndex property of route config:
//  Dupicate it and put it as the first route rule.
function handleIndexRoute(route) {
  if (!route.childRoutes || !route.childRoutes.length) {
    return;
  }

  const indexRoute = _.find(route.childRoutes, (child => child.isIndex));
  if (indexRoute) {
    const first = { ...indexRoute };
    first.path = '';
    first.exact = true;
    first.autoIndexRoute = true; // mark it so that the simple nav won't show it.
    route.childRoutes.unshift(first);
  }
  route.childRoutes.forEach(handleIndexRoute);
}

routes.forEach(handleIndexRoute);
export default routes;
