import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';


export default function MySelect(props) {


  return (
      <Autocomplete
        id={props.id}
        options={props.options}
        getOptionLabel={props.getOptionLabel}
        renderOption={props.renderOption}
        onChange={props.onChange}
        value={props.value}
        getOptionDisabled={(option) => option && option.isLabel}
        freeSolo
        selectOnFocus
        clearOnBlur
        autoHighlight
        fullWidth
        renderInput={(params) => (
          <TextField
            {...params}
            autocomplete="off"
            type="text"
            className="fob-filled-input-with-outside-label common-my-select"
            label={props.label}
            InputLabelProps={{ shrink: true }}
            color={props.color}
            placeholder={props.placeholder}
          />
        )}
      />
  );
};

