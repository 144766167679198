export const MANUFACTURERS_GET_MANUFACTURERS_BEGIN = 'MANUFACTURERS_GET_MANUFACTURERS_BEGIN';
export const MANUFACTURERS_GET_MANUFACTURERS_SUCCESS = 'MANUFACTURERS_GET_MANUFACTURERS_SUCCESS';
export const MANUFACTURERS_GET_MANUFACTURERS_FAILURE = 'MANUFACTURERS_GET_MANUFACTURERS_FAILURE';
export const MANUFACTURERS_GET_MANUFACTURERS_DISMISS_ERROR = 'MANUFACTURERS_GET_MANUFACTURERS_DISMISS_ERROR';
export const MANUFACTURERS_TOGGLE_UPLOAD_MANUFACTURERS_DIALOG = 'MANUFACTURERS_TOGGLE_UPLOAD_MANUFACTURERS_DIALOG';
export const MANUFACTURERS_TOGGLE_MANUFACTURER_DETAILS_DIALOG = 'MANUFACTURERS_TOGGLE_MANUFACTURER_DETAILS_DIALOG';
export const MANUFACTURERS_GET_MANUFACTURER_DETAILS_BEGIN = 'MANUFACTURERS_GET_MANUFACTURER_DETAILS_BEGIN';
export const MANUFACTURERS_GET_MANUFACTURER_DETAILS_SUCCESS = 'MANUFACTURERS_GET_MANUFACTURER_DETAILS_SUCCESS';
export const MANUFACTURERS_GET_MANUFACTURER_DETAILS_FAILURE = 'MANUFACTURERS_GET_MANUFACTURER_DETAILS_FAILURE';
export const MANUFACTURERS_GET_MANUFACTURER_DETAILS_DISMISS_ERROR = 'MANUFACTURERS_GET_MANUFACTURER_DETAILS_DISMISS_ERROR';
export const MANUFACTURERS_TOGGLE_ADD_MANUFACTURER_DIALOG = 'MANUFACTURERS_TOGGLE_ADD_MANUFACTURER_DIALOG';
export const MANUFACTURERS_IMPORT_MANUFACTURERS_BEGIN = 'MANUFACTURERS_IMPORT_MANUFACTURERS_BEGIN';
export const MANUFACTURERS_IMPORT_MANUFACTURERS_SUCCESS = 'MANUFACTURERS_IMPORT_MANUFACTURERS_SUCCESS';
export const MANUFACTURERS_IMPORT_MANUFACTURERS_FAILURE = 'MANUFACTURERS_IMPORT_MANUFACTURERS_FAILURE';
export const MANUFACTURERS_IMPORT_MANUFACTURERS_DISMISS_ERROR = 'MANUFACTURERS_IMPORT_MANUFACTURERS_DISMISS_ERROR';
export const MANUFACTURERS_UPDATE_MANUFACTURER_BEGIN = 'MANUFACTURERS_UPDATE_MANUFACTURER_BEGIN';
export const MANUFACTURERS_UPDATE_MANUFACTURER_SUCCESS = 'MANUFACTURERS_UPDATE_MANUFACTURER_SUCCESS';
export const MANUFACTURERS_UPDATE_MANUFACTURER_FAILURE = 'MANUFACTURERS_UPDATE_MANUFACTURER_FAILURE';
export const MANUFACTURERS_UPDATE_MANUFACTURER_DISMISS_ERROR = 'MANUFACTURERS_UPDATE_MANUFACTURER_DISMISS_ERROR';
export const MANUFACTURERS_SAVE_MANUFACTURER_BEGIN = 'MANUFACTURERS_SAVE_MANUFACTURER_BEGIN';
export const MANUFACTURERS_SAVE_MANUFACTURER_SUCCESS = 'MANUFACTURERS_SAVE_MANUFACTURER_SUCCESS';
export const MANUFACTURERS_SAVE_MANUFACTURER_FAILURE = 'MANUFACTURERS_SAVE_MANUFACTURER_FAILURE';
export const MANUFACTURERS_SAVE_MANUFACTURER_DISMISS_ERROR = 'MANUFACTURERS_SAVE_MANUFACTURER_DISMISS_ERROR';
