import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Fab,
  Divider,
  TableRow,
  TableCell,
  TableSortLabel
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import BaseTable from '../common/BaseTable';
// import { SearchField } from '../common';
import EquipmentsHelper from '../common/services/EquipmentsHelper';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {isEmpty} from 'underscore';
import AuthContext from '../authentication/AuthContext';
import { useGetMyEquipments } from './redux/getMyEquipments';
import CacheService from "../common/services/CacheService";
// import { useSelector } from 'react-redux';


const CheckCircleOutlineIconChecked = () => {
  return <CheckCircleOutlineIcon color="secondary" />
};

const CheckCircleOutlineIconUnchecked = () => {
  return <CheckCircleOutlineIcon style={{opacity: 0.15}} />
};

const Header = (props) => {
  return (
    <AppBar position="relative" color="default" className="common-base-table-header">
      <Toolbar>
        <span className="user-profile-space">
           <Typography component="p">{props.user.email}</Typography>
           <Typography component="p">{props.user.owner && props.user.owner.company}</Typography>
        </span>

        <Divider orientation="vertical" flexItem />

        {/*<SearchField placeholder="Search here..."*/}
                     {/*value={null}*/}
                     {/*onChange={null}*/}
                     {/*iconPlacement="start"*/}
                     {/*className="fob-search-input-contrast"*/}
                     {/*flexItem />*/}

        <div style={{flexGrow: 1}} />

        <div className="header-actions">
          <Fab size="medium" color="secondary" aria-label="add"
               onClick={() => {
                props.history.push(`/equipment/add`);
              }}
          >
            <AddIcon />
          </Fab>
        </div>
      </Toolbar>
    </AppBar>
  )
};

const initialPage = 1,
      initialRowsPerPage = 5,
      initialOrder = 'desc',
      initialOrderColumn = 'Manufacturer';

export default function EquipmentTable(props) {
  const cachedTableInfo = CacheService.getMyEquipmentTableInfo();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  let _initialPage = isEmpty(cachedTableInfo) ? initialPage : cachedTableInfo.page;
  const [page, setPage] = useState(_initialPage);
  let _initialRowsPerPage = isEmpty(cachedTableInfo) ? initialRowsPerPage : cachedTableInfo.rowsPerPage;
  const [rowsPerPage, setRowsPerPage] = useState(_initialRowsPerPage);
  let _initialOrderColumn = isEmpty(cachedTableInfo) ? initialOrderColumn : cachedTableInfo.sort.orderColumn;
  const [orderColumn, setOrderColumn] = useState(_initialOrderColumn);
  let _initialOrder = isEmpty(cachedTableInfo) ? initialOrder : cachedTableInfo.sort.order;
  const [order, setOrder] = useState(_initialOrder);

  const user = useContext(AuthContext);
  const history = props.history;

  const {getMyEquipments, equipments} = useGetMyEquipments();
  const data = equipments;

  const handleChangePage = useCallback(
      (_page) => {
          let _newPage = _page + 1;
          CacheService.saveMyEquipmentTableInfo({
              sort: {order: order, orderColumn: orderColumn},
              page: _newPage,
              rowsPerPage: rowsPerPage
          });
          getMyEquipments(_newPage, rowsPerPage, order, orderColumn);
          setPage(_newPage);
      }, [getMyEquipments, rowsPerPage, order, orderColumn]);

  useEffect(() => {
      const cachedTableInfo = CacheService.getMyEquipmentTableInfo();
      let _initialPage = isEmpty(cachedTableInfo) ? initialPage : cachedTableInfo.page;
      let _initialRowsPerPage = isEmpty(cachedTableInfo) ? initialRowsPerPage : cachedTableInfo.rowsPerPage;
      let _initialOrderColumn = isEmpty(cachedTableInfo) ? initialOrderColumn : cachedTableInfo.sort.orderColumn;
      let _initialOrder = isEmpty(cachedTableInfo) ? initialOrder : cachedTableInfo.sort.order;

      getMyEquipments(_initialPage, _initialRowsPerPage, _initialOrder, _initialOrderColumn);
  }, [getMyEquipments]);

  useEffect(() => {
    setColumns(
      EquipmentsHelper.getMyEquipmentTableColumns()
    );
  }, []);

  useEffect(() => {
    if (!data || !data.rows) return;

    let transformedRows = data.rows.map(item => {
      item.location = item.address && item.address.city && item.address.state ? `${item.address.city}, ${item.address.state}` : '';
      item.manufacturerDescription = item.manufacturer && item.manufacturer.description ? item.manufacturer.description : '';
      item.partDescription = item.part && item.part.description ? item.part.description : '';
      item.hasWarranty = !!(Array.isArray(item.warranties) && item.warranties.length); // !isEmpty(item.warranty);
      item.hasSA = !!(Array.isArray(item.serviceAgreements) && item.serviceAgreements.length);
      return item;
    });

    setRows(transformedRows);
  }, [data]);

  const handleRowsPerPageChanged = useCallback(
      (_rowsPerPage) => {
          CacheService.saveMyEquipmentTableInfo({
              sort: {order: order, orderColumn: orderColumn},
              page: initialPage,
              rowsPerPage: _rowsPerPage
          });
          getMyEquipments(initialPage, _rowsPerPage, order, orderColumn);
          setRowsPerPage(_rowsPerPage);
          setPage(initialPage);
      }, [getMyEquipments, order, orderColumn]);

  const handleOrderChanged = useCallback(
      (order, orderColumn) => {
          const newOrder = (order === 'desc') ? 'asc' : 'desc';
          CacheService.saveMyEquipmentTableInfo({
              sort: {order: newOrder, orderColumn: orderColumn},
              page: page,
              rowsPerPage: rowsPerPage
          });
          getMyEquipments(page, rowsPerPage, newOrder, orderColumn);
          setOrder(newOrder);
          setOrderColumn(orderColumn);
      }, [getMyEquipments, page, rowsPerPage]);

  const handleRowClick = useCallback(
    (row) => {
      history.push(`/equipment/${row.assetId}`);
    }, [history]);

  const renderTableColumns = useCallback(
    () => {
      if (!columns.length) return [];

      let cols = columns.map((col, idx) => {
        return (
          <TableCell 
            key={idx} 
            align="center">
              { col.orderColumn ?
                <TableSortLabel
                  className={col.orderColumn === orderColumn ? "selected-column" : "not-selected-column"}
                  direction={col.orderColumn === orderColumn ? order : _initialOrder }
                  onClick={() => handleOrderChanged(col.orderColumn === orderColumn ? order : _initialOrder, col.orderColumn)}
                >
                  {col.label}
                </TableSortLabel>
                :
                  col.label
              }
          </TableCell>
        );
      });

      return cols;
    }, [columns, handleOrderChanged, order, orderColumn, _initialOrder]);

  const renderTableBody = useCallback(
    (rows, page, rowsPerPage, remote) => {
      const rowsOnPage = remote ? rows : rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      return rowsOnPage.map((row) => (
        <TableRow hover key={row.name} onClick={handleRowClick.bind(this, row)}>
          <TableCell align="center">{row.manufacturerDescription}</TableCell>
          <TableCell align="center">{row.serialNumber}</TableCell>
          <TableCell align="center">{row.latId}</TableCell>
          <TableCell align="center">{row.hasWarranty ? <CheckCircleOutlineIconChecked /> : <CheckCircleOutlineIconUnchecked />}</TableCell>
          <TableCell align="center">{row.hasSA ? <CheckCircleOutlineIconChecked /> : <CheckCircleOutlineIconUnchecked />}</TableCell>
          <TableCell align="center">{row.partDescription}</TableCell>
          <TableCell align="center">{row.location}</TableCell>
        </TableRow>
      ))
    }, [handleRowClick]);

  if (isEmpty(user)) return null;

  const MyHeader = () => <Header history={history} user={user} />;

  return (
    <div className="equipments-equipment-table fob-content-body">
      <BaseTable initialPage={_initialPage}
                 remote
                 initialRowsPerPage={_initialRowsPerPage}
                 headerComponent={MyHeader}
                 columns={columns}
                 rows={rows}
                 renderTableColumns={renderTableColumns}
                 renderTableBody={renderTableBody}
                 changePage={handleChangePage}
                 rowsPerPageChanged={handleRowsPerPageChanged}
                 count={data ? data.count : null} />
    </div>
  );
};

