import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
    PROFILE_UPDATE_PROFILE_PHOTO_BEGIN,
    PROFILE_UPDATE_PROFILE_PHOTO_SUCCESS,
    PROFILE_UPDATE_PROFILE_PHOTO_FAILURE,
    PROFILE_UPDATE_PROFILE_PHOTO_DISMISS_ERROR,
} from './constants';
import ApiService from '../../common/services/ApiService';
import AuthService from '../../authentication/services/AuthService';

export function updateProfilePhoto(file) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: PROFILE_UPDATE_PROFILE_PHOTO_BEGIN,
    });

    let formData = new FormData();
    formData.append('file', file);

    const promise = new Promise((resolve, reject) => {
      ApiService.updateProfilePhoto(formData).then(
        (res) => {
            AuthService.updateAuthUser(res);
            let successMessage = 'Successfully updated profile photo';
            dispatch({
              type: PROFILE_UPDATE_PROFILE_PHOTO_SUCCESS,
              data: res,
              message: successMessage
            });
            resolve({
              data: res,
              message: successMessage,
            });
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: PROFILE_UPDATE_PROFILE_PHOTO_FAILURE,
            data: {
              error: {
                message: err && err.response && err.response.data ? err.response.data.message : 'Unable to save profile photo.'
              }
            },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissUpdateProfilePhotoError() {
  return {
    type: PROFILE_UPDATE_PROFILE_PHOTO_DISMISS_ERROR,
  };
}

export function useUpdateProfilePhoto(params) {
  const dispatch = useDispatch();

  const { updateProfilePhotoPending, updateProfilePhotoError } = useSelector(
    state => ({
        updateProfilePhotoPending: state.profile.updateProfilePhotoPending,
        updateProfilePhotoError: state.profile.updateProfilePhotoError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(updateProfilePhoto(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissUpdateProfilePhotoError());
  }, [dispatch]);

  return {
    updateProfilePhoto: boundAction,
    updateProfilePhotoPending,
    updateProfilePhotoError,
    dismissUpdateProfilePhotoError: boundDismissError
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case PROFILE_UPDATE_PROFILE_PHOTO_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        updateProfilePhotoPending: true,
        updateProfilePhotoError: null
      };

    case PROFILE_UPDATE_PROFILE_PHOTO_SUCCESS:
      // The request is success
      return {
        ...state,
        updateProfilePhotoPending: false,
        updateProfilePhotoError: null,
        userProfile: action.data,
        updateProfilePhotoMessage: action.message,
      };

    case PROFILE_UPDATE_PROFILE_PHOTO_FAILURE:
      // The request is failed
      return {
        ...state,
        updateProfilePhotoPending: false,
        updateProfilePhotoError: action.data.error,
      };

    case PROFILE_UPDATE_PROFILE_PHOTO_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        updateProfilePhotoError: null,
      };

    default:
      return state;
  }
}
