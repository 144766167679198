import React, { useState } from 'react';
import { Grid, Typography, Fab, Avatar, CircularProgress } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import coverImg from './../../images/DefaultProfileBackground@2x.png';
import userAvatarImg from './../../images/user.png';
import Swal from "sweetalert2";


export default function MyProfileFormHeader(props) {

  const [updatingAvatar, setUpdatingAvatar] = useState(false);
  const [updatingCover, setUpdatingCover] = useState(false);

  const handleAvatarUploadClick = (event) => {
    var file = event.target.files[0];
    if(file) {
      setUpdatingAvatar(true);
      props.updatePhoto(event.target.files[0]).then(res => {
        setUpdatingAvatar(false);
        Swal.fire({
          icon: 'success',
          text: res.message,
          timer: 2000
        });
      });
    }
  };

  const handleCoverUploadClick = (event) => {
    var file = event.target.files[0];
    if(file) {
      setUpdatingCover(true);
      props.updateCover(event.target.files[0]).then(res => {
        setUpdatingCover(false);
        Swal.fire({
          icon: 'success',
          text: res.message,
          timer: 2000
        });
      });
    }
  };

  return (
    <div className="profile-my-profile-form-header">
      <Grid container
            item
      >
        {/*<input*/}
        {/*  accept="image/*"*/}
        {/*  id="change-cover-file"*/}
        {/*  multiple*/}
        {/*  type="file"*/}
        {/*/>*/}
        {/*<label htmlFor="change-cover-file">*/}
        {/*  <ButtonBase*/}
        {/*    focusRipple*/}
        {/*    className="change-cover-btn"*/}
        {/*    component="span"*/}
        {/*  >*/}
        {/*    <span>Change Cover</span>*/}
        {/*  </ButtonBase>*/}
        {/*</label>*/}

        <Typography variant="h6" component="h6" className="user-name">
          {`${props.user.firstName} ${props.user.lastName}`}
        </Typography>

        <div class="cover-photo">
            { updatingCover &&
              <div class="cover-loading">
                <CircularProgress color="primary" />
              </div>
            }
            <img src={props.user.backgroundImage ? props.user.backgroundImage : coverImg} alt="Cover"/>
            <input
              accept="image/*"
              id="backgroundFile"
              name="backgroundFile"
              type="file"
              onChange={(e) => { handleCoverUploadClick(e) }}
              className="user-bg-input"
            />
            { !updatingCover &&
              <label htmlFor="backgroundFile">
                <Fab component="span" className="user-bg-edit-btn" size="small" color="primary" aria-label="edit">
                  <EditIcon />
                </Fab>
              </label>
            }
        </div>

        <div className="user-avatar">
          <div className="user-avatar-img">
            { updatingAvatar &&
              <div class="avatar-loading">
                <CircularProgress color="primary" />
              </div>
            }
            <Avatar alt={props.user.firstName} src={props.user.profileImage ? props.user.profileImage : userAvatarImg} />
          </div>
          <input
              accept="image/*"
              id="avatarFile"
              name="avatarFile"
              type="file"
              onChange={(e) => { handleAvatarUploadClick(e) }}
              className="user-avatar-input"
          />
          { !updatingAvatar &&
            <label htmlFor="avatarFile">
              <Fab component="span" className="user-avatar-edit-btn" size="small" color="primary" aria-label="edit">
                <EditIcon />
              </Fab>
            </label>
          }
        </div>
      </Grid>
    </div>
  );
};

