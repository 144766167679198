import React, { useEffect, useState } from 'react';
import NavigationManager from './services/NavigationManager';
import {
  Breadcrumbs,
  Typography,
  Link
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { isEmpty } from 'underscore';


const LinkRouter = (props) => <Link {...props} component={RouterLink} />;


export default function PageHeader(props) {
  let location = props.location;
  let match = props.match;

  const [pageTitle, setPageTitle] = useState('');
  const [pathNames, setPathNames] = useState([]);

  useEffect(() => {
    let title = NavigationManager.getLabel(location.pathname, match);
    console.log('title: ',title);
    if (title) setPageTitle(title);

    let pathnames = location.pathname.split('/').filter((x) => x);
    setPathNames(pathnames);
    console.log('pathnames: ',pathnames);
  }, [location, match]);

  console.log('pathNames: ',pathNames);

  return (
    <div className="common-page-header">
      <section>
        <Typography variant="h4" component="h4">
          {pageTitle}
        </Typography>

        <Breadcrumbs separator="-" aria-label="breadcrumb">
          <LinkRouter color="inherit" to="/">
            Home
          </LinkRouter>


          {pathNames.map((value, index) => {
            const last = index === pathNames.length - 1;
            const to = `/${pathNames.slice(0, index + 1).join('/')}`;
            const label = NavigationManager.getLabel(to);

            if (isEmpty(label)) return null;

            return last ? (
              <Typography color="inherit" key={to}>
                {label}
              </Typography>
            ) : (
              <LinkRouter color="inherit" to={to} key={to}>
                {label}
              </LinkRouter>
            );
          })}
        </Breadcrumbs>
      </section>
    </div>
  );
};

