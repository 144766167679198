import React, { useCallback, useEffect, useState } from 'react';
import BaseTable from '../common/BaseTable';
import { TableCell, TableRow, Button, TableSortLabel } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import EquipmentsHelper from '../common/services/EquipmentsHelper';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {isEmpty} from 'underscore';
import {EquipmentDetailsHeader} from './index';
import { useToggleActivityDetailsDialog } from './redux/hooks';
import StringHelper from "../common/services/StringHelper";
import {useGetAssetActivities} from "./redux/getAssetActivities";
import {useSelector} from "react-redux";


const initialPage = 1,
      initialRowsPerPage = 5,
      initialOrder = 'desc',
      initialOrderColumn = 'Activity';

export default function EquipmentActivitiesTable(props) {
  const equipmentDetails = props.equipmentDetails;
  const countries = props.countries;
  const user = props.user;
  const ownersData = props.ownersData;
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const {toggleActivityDetailsDialog} = useToggleActivityDetailsDialog();
  const [orderColumn, setOrderColumn] = useState(initialOrderColumn);
  const [order, setOrder] = useState(initialOrder);
  const {getAssetActivities, assetActivities} = useGetAssetActivities()
  const isSm = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const newActivityAddedTimestamp = useSelector(state => state.equipments.newActivityAddedTimestamp);

  const equipmentId = props.equipmentId;

  useEffect(() => {
    if(!equipmentId) return;

    getAssetActivities(equipmentId, initialPage, initialRowsPerPage, initialOrder, initialOrderColumn);
  }, [getAssetActivities, equipmentId, newActivityAddedTimestamp]);

  useEffect(() => {
    setColumns(
      EquipmentsHelper.getEquipmentActivityColumns()
    );

      return () => {
          setColumns([]);
      };
  }, []);

  useEffect(() => {
    if (isEmpty(assetActivities) || !assetActivities.rows.length) return;

    setRows(assetActivities.rows);

    return () => {
        setRows([]);
    };
  }, [assetActivities]);

  const handleChangePage = useCallback(
    (_page) => {
      getAssetActivities(equipmentId, ++_page, rowsPerPage, order, orderColumn);
      setPage(_page);
    }, [getAssetActivities, equipmentId, rowsPerPage, order, orderColumn]);

  const handleRowsPerPageChanged = useCallback(
    (_rowsPerPage) => {
      getAssetActivities(equipmentId, initialPage, _rowsPerPage, order, orderColumn);
      setRowsPerPage(_rowsPerPage);
      setPage(initialPage);
    }, [getAssetActivities, equipmentId, order, orderColumn]);

  const handleOrderChanged = useCallback(
    (order, orderColumn) => {
      const newOrder = (order === 'desc') ? 'asc' : 'desc';
      getAssetActivities(equipmentId, page, rowsPerPage, newOrder, orderColumn);
      setOrder(newOrder);
      setOrderColumn(orderColumn);
    }, [getAssetActivities, equipmentId, page, rowsPerPage]);

  const renderTableColumns = useCallback(
    () => {
      if (!columns.length) return [];

      let cols = columns.map((col, idx) => {
        return (
          <TableCell 
            key={idx} 
            align="center">
              { col.orderColumn ?
                <TableSortLabel
                  direction={col.orderColumn === orderColumn ? order : initialOrder }
                  onClick={() => handleOrderChanged(col.orderColumn === orderColumn ? order : initialOrder, col.orderColumn)}
                >
                  {col.label}
                </TableSortLabel>
                :
                  col.label
              }
          </TableCell>
        );
      });

      if(!isSm) {
        cols.push(
          <TableCell align="center">
            <Button
                variant="contained"
                color="secondary"
                endIcon={<AddCircleIcon />}
                className="rounded-btn"
                style={{'minWidth': '250px'}}
                onClick={toggleActivityDetailsDialog.bind(this, true, equipmentDetails, {}, true, null)}
            >
              ADD ACTIVITY
            </Button>
          </TableCell>
        );
      }

      return cols;
    }, [columns, toggleActivityDetailsDialog, equipmentDetails, handleOrderChanged, order, orderColumn, isSm]);

  const renderTableBody = useCallback(
    (rows, page, rowsPerPage, remote) => {
      const rowsOnPage = remote ? rows : rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      return rowsOnPage.map((row) => (
        <TableRow hover key={row.name} onClick={toggleActivityDetailsDialog.bind(this, true, equipmentDetails, row, false, null)}>
          <TableCell align="center">{row.activtyType.description}</TableCell>
          <TableCell align="center">{StringHelper.formatDisplayDate(row.date)}</TableCell>
          <TableCell align="center">{row.user.firstName} {row.user.lastName}</TableCell>
          <TableCell align="center">{row.notes}</TableCell>
          <TableCell align="center">{}</TableCell>
        </TableRow>
      ))
    }, [toggleActivityDetailsDialog, equipmentDetails]);

  const MyHeader = (props) => equipmentDetails ? <EquipmentDetailsHeader equipmentDetails={equipmentDetails} user={user} countries={countries} ownersData={ownersData} /> : null;

  return (
    <div className="equipments-equipment-activities-table fob-content-body">
      <BaseTable
        remote
        initialRowsPerPage={initialRowsPerPage}
        headerComponent={MyHeader}
        columns={columns}
        rows={rows}
        renderTableColumns={renderTableColumns}
        renderTableBody={renderTableBody}
        changePage={handleChangePage}
        rowsPerPageChanged={handleRowsPerPageChanged}
        count={(assetActivities && assetActivities.count) ? assetActivities.count : rows.length}
      />
    </div>
  );
};

