import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  DASHBOARD_GET_ASSET_COST_OF_USE_AGGREGATION_BEGIN,
  DASHBOARD_GET_ASSET_COST_OF_USE_AGGREGATION_SUCCESS,
  DASHBOARD_GET_ASSET_COST_OF_USE_AGGREGATION_FAILURE,
  DASHBOARD_GET_ASSET_COST_OF_USE_AGGREGATION_DISMISS_ERROR,
} from './constants';
import ApiService from "../../common/services/ApiService";

export function getAssetCostOfUseAggregation(searchValue, startDate, endDate) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: DASHBOARD_GET_ASSET_COST_OF_USE_AGGREGATION_BEGIN,
    });

    const startDateStr = startDate ? startDate.format('YYYY-MM-DD') : '';
    const endDateStr = endDate ? endDate.format('YYYY-MM-DD') : '';

    const promise = new Promise((resolve, reject) => {
      ApiService.getAssetCostOfUseAggregation(searchValue, startDateStr, endDateStr).then(
        (res) => {
          dispatch({
            type: DASHBOARD_GET_ASSET_COST_OF_USE_AGGREGATION_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: DASHBOARD_GET_ASSET_COST_OF_USE_AGGREGATION_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissGetAssetCostOfUseAggregationError() {
  return {
    type: DASHBOARD_GET_ASSET_COST_OF_USE_AGGREGATION_DISMISS_ERROR,
  };
}

export function useGetAssetCostOfUseAggregation(params) {
  const dispatch = useDispatch();

  const { assetCostOfUseAggregationData, getAssetCostOfUseAggregationPending, getAssetCostOfUseAggregationError } = useSelector(
    state => ({
      assetCostOfUseAggregationData: state.dashboard.assetCostOfUseAggregationData,
      getAssetCostOfUseAggregationPending: state.dashboard.getAssetCostOfUseAggregationPending,
      getAssetCostOfUseAggregationError: state.dashboard.getAssetCostOfUseAggregationError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(getAssetCostOfUseAggregation(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetAssetCostOfUseAggregationError());
  }, [dispatch]);

  return {
    assetCostOfUseAggregationData,
    getAssetCostOfUseAggregation: boundAction,
    getAssetCostOfUseAggregationPending,
    getAssetCostOfUseAggregationError,
    dismissGetAssetCostOfUseAggregationError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case DASHBOARD_GET_ASSET_COST_OF_USE_AGGREGATION_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getAssetCostOfUseAggregationPending: true,
        getAssetCostOfUseAggregationError: null,
      };

    case DASHBOARD_GET_ASSET_COST_OF_USE_AGGREGATION_SUCCESS:
      // The request is success
      return {
        ...state,
        getAssetCostOfUseAggregationPending: false,
        getAssetCostOfUseAggregationError: null,
        assetCostOfUseAggregationData: action.data,
      };

    case DASHBOARD_GET_ASSET_COST_OF_USE_AGGREGATION_FAILURE:
      // The request is failed
      return {
        ...state,
        getAssetCostOfUseAggregationPending: false,
        getAssetCostOfUseAggregationError: action.data.error,
      };

    case DASHBOARD_GET_ASSET_COST_OF_USE_AGGREGATION_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getAssetCostOfUseAggregationError: null,
      };

    default:
      return state;
  }
}
