import React, { useCallback, useEffect, useState } from 'react';
import BaseTable from '../common/BaseTable';
import { AppBar, Fab, TableCell, TableRow, Toolbar } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';


const COLUMNS = ['Role'];


const Header = (props) => {
  return (
    <AppBar position="relative" color="default" className="common-base-table-header">
      <Toolbar>
        <div style={{flexGrow: 1}} />

        <div className="header-actions">
          <Fab size="medium"
                 color="secondary"
                 aria-label="add"
                 className="datatable-add-item-btn"
                 onClick={props.onClickAddBtn}
          >
            <AddIcon />
          </Fab>
        </div>
      </Toolbar>
    </AppBar>
  )
};


export default function RolesTable(props) {
  const toggleRoleDetailsDialog = props.toggleRoleDetailsDialog;
  const data = props.data;
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (!data || !data.rows) return;
    setRows(data.rows);
  }, [data]);

  const handleRowClick = useCallback(
    (row) => {
      toggleRoleDetailsDialog({roleDetails: row});
    }, [toggleRoleDetailsDialog]);

  const renderTableBody = useCallback(
    (rows, page, rowsPerPage) => {
      return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
        <TableRow hover key={row.name} onClick={handleRowClick.bind(this, row)}>
          <TableCell align="center">{row.description}</TableCell>
        </TableRow>
      ))
    }, [handleRowClick]);

  const MyHeader = () => <Header onClickAddBtn={props.onClickAddBtn} />;

  return (
    <div className="roles-roles-table fob-content-body">
      <BaseTable headerComponent={MyHeader}
                 columns={COLUMNS}
                 rows={rows}
                 renderTableBody={renderTableBody} />
    </div>
  );
};

