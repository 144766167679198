import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  PARTS_GET_PARTS_BEGIN,
  PARTS_GET_PARTS_SUCCESS,
  PARTS_GET_PARTS_FAILURE,
  PARTS_GET_PARTS_DISMISS_ERROR,
} from './constants';
import ApiService from '../../common/services/ApiService';

export function getParts(page=1, limit=3, order='ASC', manufacturerId=null) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: PARTS_GET_PARTS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      ApiService.getPartsWithAddress(manufacturerId, page, limit, order).then(
        (res) => {
          dispatch({
            type: PARTS_GET_PARTS_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: PARTS_GET_PARTS_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissGetPartsError() {
  return {
    type: PARTS_GET_PARTS_DISMISS_ERROR,
  };
}

export function useGetParts(params) {
  const dispatch = useDispatch();

  const { getPartsPending, getPartsError, partsData } = useSelector(
    state => ({
      getPartsPending: state.parts.getPartsPending,
      getPartsError: state.parts.getPartsError,
      partsData: state.parts.partsData,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(getParts(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetPartsError());
  }, [dispatch]);

  return {
    partsData: partsData,
    getParts: boundAction,
    getPartsPending,
    getPartsError,
    dismissGetPartsError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case PARTS_GET_PARTS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getPartsPending: true,
        getPartsError: null,
      };

    case PARTS_GET_PARTS_SUCCESS:
      // The request is success
      return {
        ...state,
        getPartsPending: false,
        getPartsError: null,
        partsData: action.data,
      };

    case PARTS_GET_PARTS_FAILURE:
      // The request is failed
      return {
        ...state,
        getPartsPending: false,
        getPartsError: action.data.error,
      };

    case PARTS_GET_PARTS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getPartsError: null,
      };

    default:
      return state;
  }
}
