import React from 'react';
import {useToggleServiceAgreementList} from "./redux/toggleServiceAgreementList";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Toolbar,
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme, withStyles} from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {isEmpty} from 'underscore';
import {useToggleServiceAgreementDialog} from "./redux/toggleServiceAgreementDialog";
import StringHelper from "../common/services/StringHelper";

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


export default function ServiceAgreementListDialog(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const {toggleServiceAgreementList, isServiceAgreementListVisible} = useToggleServiceAgreementList();
    const {toggleServiceAgreementDialog} = useToggleServiceAgreementDialog();


    return (
      <Dialog
          fullScreen={fullScreen}
          open={isServiceAgreementListVisible}
          className="equipments-service-agreement-list-dialog">
          <DialogTitle>
              <Toolbar>
                  <Typography variant="h5" display="inline">Service Agreements</Typography>
                  <span style={{flexGrow: 1}}></span>
                  <IconButton
                      edge="end"
                      color="inherit" aria-label="menu"
                      onClick={toggleServiceAgreementList.bind(this, false)}>
                      <HighlightOffIcon/>
                  </IconButton>
              </Toolbar>
              <Divider/>
          </DialogTitle>

          <DialogContent>
              <TableContainer>
                  <Table>
                      <TableHead>
                          <TableRow>
                              <TableCell>Service Agreement</TableCell>
                              <TableCell align="right">Company</TableCell>
                              <TableCell align="right">Start Date</TableCell>
                              <TableCell align="right">End Date</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {
                              props.equipmentDetails && props.equipmentDetails.serviceAgreements && props.equipmentDetails.serviceAgreements.length
                              ? props.equipmentDetails.serviceAgreements.map(row => (
                                  <StyledTableRow key={row.serviceAgreementId} onClick={() => {
                                      toggleServiceAgreementDialog(true, row);
                                      toggleServiceAgreementList(false);
                                  }}>
                                      <TableCell align="left">{row.contractNumber}</TableCell>
                                      <TableCell align="left">{row.company}</TableCell>
                                      <TableCell align="left">
                                        {StringHelper.formatDisplayDate(row.startDate)}
                                      </TableCell>
                                      <TableCell align="left">
                                          {StringHelper.formatDisplayDate(row.endDate)}
                                      </TableCell>
                                  </StyledTableRow>
                                ))
                              : null
                          }
                      </TableBody>
                  </Table>
              </TableContainer>
          </DialogContent>
      </Dialog>
  );
};

