import DashboardIcon from '@material-ui/icons/Dashboard';
import LocalDiningIcon from '@material-ui/icons/LocalDining';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import SearchIcon from '@material-ui/icons/Search';
import CenterFocusWeak from '@material-ui/icons/CenterFocusWeak';
import TuneIcon from '@material-ui/icons/Tune';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import React from 'react';
import _ from 'underscore';
import {CONFIG} from './../../../config';


const MENU_ITEMS = [
  {'label': 'DASHBOARD', 'icon': <DashboardIcon />, 'url': '/dashboard', role: '*'},
  {'label': 'MY EQUIPMENT', 'icon': <LocalDiningIcon />, 'url': '/equipment', role: '*'},
  {'label': 'MY PROFILE', 'icon': <PersonOutlineIcon />, 'url': '/profile', role: '*'},
  {'label': 'SCAN', 'icon': <CenterFocusWeak />, 'url': '/scan', role: '*'},
  {'label': 'SEARCH', 'icon': <SearchIcon />, 'url': '/search', role: '*'},
  {'label': 'ADMINISTRATIVE OPTION', 'sideNavLabel': 'ADMIN OPTIONS', 'icon': <TuneIcon />, 'url': '/admin', role: [CONFIG.ROLES.admin.id, CONFIG.ROLES.manufacturer.id]},
  {'label': 'HELP', 'icon': <HelpOutlineIcon />, 'url': '/help', role: '*'},
  {'label': 'LOG OUT', 'icon': <ExitToAppIcon />, 'url': '/logout', role: '*'},
];

const PARAMED_ROUTES = [
  {'label': 'EQUIPMENT DETAILS', 'icon': <LocalDiningIcon />, 'url': '/equipment/:id'},
  {'label': 'EQUIPMENT DETAILS', 'icon': <LocalDiningIcon />, 'url': '/search/equipment/:id'},
];

const ADMIN_ROUTES = [
    {
      url: '/admin/manufacturers',
      label: 'MANUFACTURERS',
    },
    {
      url: '/admin/parts',
      label: 'ASSETS',
    },
    {
      url: '/admin/roles',
      label: 'ROLES',
    }
];

const BREADCRUMBS_ROUTES = MENU_ITEMS.concat(ADMIN_ROUTES).concat([
  {'label': 'ADD EQUIPMENT', 'url': '/equipment/add'},
]);


export default class NavigationManager {

  static getMenuItems(user) {
    if (_.isEmpty(user)) return [];

    return MENU_ITEMS.filter(item => {
      if (Array.isArray(item.role) && !item.role.includes(user.roleId)) return false;
      return true;
    })
  }

  static getLabelWithMatch(match) {
    let result = _.findWhere(PARAMED_ROUTES, {url: match.path});

    if (!result) return null;

    return result['label'];

  }

  static getLabel(url, match) {
    if (match && match.path) return NavigationManager.getLabelWithMatch(match);

    let result = _.findWhere(BREADCRUMBS_ROUTES, {url: url});

    if (!result) return null;

    return result['label'];
  }
}