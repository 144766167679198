import React, {useContext} from 'react';
import {
  AppBar,
  Toolbar,
  Avatar,
  IconButton,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
// import SearchIcon from '@material-ui/icons/Search';
// import AdjustIcon from '@material-ui/icons/Adjust';
import companyIcon from './../../images/LATLogo@3x.png';
import userAvatar from './../../images/user.png';
// import { SearchField } from './index';
import AuthContext from '../authentication/AuthContext';
import { isEmpty } from 'underscore';
import useMediaQuery from '@material-ui/core/useMediaQuery';


export default function TopNav(props) {
  const user = useContext(AuthContext);
  const isSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

  console.log('user: ',user);

  const redirectToHome = () => {
    props.history.push('/');
  };

  if (isEmpty(user)) return null;

  return (
    <div className={!isSm ? "common-top-nav" : "common-top-nav common-top-nav-mobile"}>
      <AppBar position="static" color="default" elevation={3}>
        <Toolbar>
          <div className="company-logo-wrapper">
            <img alt="" src={companyIcon} className="common-top-nav-company-icon" onClick={redirectToHome} />
            {/*<AdjustIcon />*/}
          </div>


          <div className="common-top-nav-user-space">
            <Avatar alt={user.firstName} src={user.profileImage ? user.profileImage : userAvatar} />
            <span className="common-top-nav-user-space-labels" >
              <p>Welcome</p>
              <p>{user.firstName} {user.lastName}</p>
            </span>
          </div>

          {/*<SearchField placeholder="Search here..." value={null} onChange={null} iconPlacement="end" />*/}

          { !isSm ?
            <div style={{flexGrow: 1}} />
            :
            <div style={{display: 'none'}} />
          }

          {/*<IconButton color="inherit" className="common-top-nav-notifications-btn">*/}
          {/*  <Badge variant="dot" color="secondary">*/}
          {/*    <NotificationsIcon />*/}
          {/*  </Badge>*/}
          {/*</IconButton>*/}

        </Toolbar>
      </AppBar>
    </div>
  );
};
