import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  MANUFACTURERS_TOGGLE_UPLOAD_MANUFACTURERS_DIALOG,
} from './constants';
import { isNull } from 'underscore';

export function toggleUploadManufacturersDialog(flag=null) {
  return {
    type: MANUFACTURERS_TOGGLE_UPLOAD_MANUFACTURERS_DIALOG,
    flag: flag,
  };
}

export function useToggleUploadManufacturersDialog() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => dispatch(toggleUploadManufacturersDialog(...params)), [dispatch]);
  return { toggleUploadManufacturersDialog: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANUFACTURERS_TOGGLE_UPLOAD_MANUFACTURERS_DIALOG:
      return {
        ...state,
        isUploadManufacturersDialogVisible: isNull(action.flag) ? !state.isUploadManufacturersDialogVisible : action.flag,
      };

    default:
      return state;
  }
}
