import React from 'react';
import { HomeAddressInfo, MyProfileFormHeader, OfficeAddressInfo } from './index';
import { Grid, Button } from '@material-ui/core';
import MyProfileInfo from './MyProfileInfo';
import { LoadingButton } from '../common';
import MessagePrompt from '../common/MessagePrompt';
import { useUpdateProfile, useUpdateProfilePhoto, useUpdateBackgroundImage } from './redux/hooks';
import UserSettings from "./UserSettings";


export default function MyProfileForm(props) {
  const {updateProfilePending, updateProfileError} = useUpdateProfile();
  const {updateProfilePhoto, updateProfilePhotoError} = useUpdateProfilePhoto();
  const {updateBackgroundImage, updateBackgroundImageError} = useUpdateBackgroundImage();

  return (
    <Grid className="profile-my-profile-form"
          container
          direction="column"
          justify="center"
          alignItems="center">
      <Grid
        item
        container
      >
        <MyProfileFormHeader user={props.user} updatePhoto={ updateProfilePhoto } updateCover={ updateBackgroundImage } />
      </Grid>

      <Grid item
            container
            lg={11}
            spacing={2}
            className="profile-my-profile-form-container"
      >
        { updateProfileError && 
          <MessagePrompt message={updateProfileError}
            severity="error"
            autoHideDuration={6000}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          />
        }

        { updateProfilePhotoError && 
          <MessagePrompt message={updateProfilePhotoError}
            severity="error"
            autoHideDuration={6000}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          />
        }

        { updateBackgroundImageError && 
          <MessagePrompt message={updateBackgroundImageError}
            severity="error"
            autoHideDuration={6000}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          />
        }

        <Grid item container lg={6}>
          <MyProfileInfo formik={props.formik} />
        </Grid>

        <Grid item
              container
              lg={6}
              direction="column"
              justify="center"
              alignItems="center"
              className="address-grid-container">

          <Grid item container lg={6} className="company-info-container grid-item">
            <OfficeAddressInfo formik={props.formik} />
          </Grid>

          <Grid item container lg={6} className="grid-item">
            <HomeAddressInfo formik={props.formik} countries={props.countries} />
          </Grid>
        </Grid>

        <Grid item container lg={6}>
          <UserSettings />
        </Grid>

        <Grid item
              container
              lg={12}
              className="my-profile-primary-actions-container">
          <Grid item container xs={5}>
            {
              updateProfilePending === true
              ? <LoadingButton />
              : <Button variant="contained" color="secondary" className="rounded-btn" type="submit">
                  Save
                </Button>
            }
          </Grid>
          <Grid item container xs={2}></Grid>
          <Grid item container xs={5}>
            <Button variant="contained"
                    color="primary"
                    disabled={updateProfilePending === true}
                    className="rounded-btn">
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

