import React, { useState, useCallback } from 'react';
import {Divider, Grid, Switch, FormControlLabel, Typography} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import AuthService from "../authentication/services/AuthService";
import {isNull} from 'underscore';


export default function UserSettings() {
  const _isUserBiometricSet = AuthService.checkUserBiometricSettings();
  const flag = !isNull(_isUserBiometricSet) ? _isUserBiometricSet : false;
  const [isUserBiometricSet, setIsUserBiometricSet] = useState(flag);

  const handleSetUserBiometric = useCallback((event) => {
      setIsUserBiometricSet(event.target.checked);
      AuthService.setUserBiometricSettings(event.target.checked);
  }, []);

  return (
    <div className="profile-user-settings">
        <Grid container
              direction="column">
            <Grid container item className="my-profile-form-item-group-header">
                <SettingsIcon />
                <Typography variant="h6" component="h6" color="primary">USER SETTINGS</Typography>
            </Grid>
            <Divider/>
            <Grid container
                  item
                  direction="row"
                  spacing={2}>
                <Grid container item lg={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isUserBiometricSet}
                                onChange={handleSetUserBiometric}
                                name="isUserBiometricSet"
                                color="secondary"
                            />
                        }
                        label="Use Biometric Sign in"
                    />
                </Grid>
            </Grid>
        </Grid>
    </div>
  );
};
