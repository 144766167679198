import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AUTHENTICATION_TOGGLE_BIOMETRIC_USAGE_PROMPT_DIALOG,
} from './constants';
import {isNull} from 'underscore';


export function toggleBiometricUsagePromptDialog(flag) {
  return {
    type: AUTHENTICATION_TOGGLE_BIOMETRIC_USAGE_PROMPT_DIALOG,
    flag: flag,
  };
}

export function useToggleBiometricUsagePromptDialog() {
  const dispatch = useDispatch();
  const isBiometricUsagePromptDialogVisible = useSelector(state => state.authentication.isBiometricUsagePromptDialogVisible);
  const boundAction = useCallback((...params) => dispatch(toggleBiometricUsagePromptDialog(...params)), [dispatch]);
  return { isBiometricUsagePromptDialogVisible, toggleBiometricUsagePromptDialog: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case AUTHENTICATION_TOGGLE_BIOMETRIC_USAGE_PROMPT_DIALOG:
      return {
        ...state,
        isBiometricUsagePromptDialogVisible: isNull(action.flag) ? !state.isBiometricUsagePromptDialogVisible : action.flag,
      };

    default:
      return state;
  }
}
