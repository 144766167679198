export const PARTS_GET_PARTS_BEGIN = 'PARTS_GET_PARTS_BEGIN';
export const PARTS_GET_PARTS_SUCCESS = 'PARTS_GET_PARTS_SUCCESS';
export const PARTS_GET_PARTS_FAILURE = 'PARTS_GET_PARTS_FAILURE';
export const PARTS_GET_PARTS_DISMISS_ERROR = 'PARTS_GET_PARTS_DISMISS_ERROR';
export const PARTS_GET_PART_DETAILS_BEGIN = 'PARTS_GET_PART_DETAILS_BEGIN';
export const PARTS_GET_PART_DETAILS_SUCCESS = 'PARTS_GET_PART_DETAILS_SUCCESS';
export const PARTS_GET_PART_DETAILS_FAILURE = 'PARTS_GET_PART_DETAILS_FAILURE';
export const PARTS_GET_PART_DETAILS_DISMISS_ERROR = 'PARTS_GET_PART_DETAILS_DISMISS_ERROR';
export const PARTS_UPDATE_PART_BEGIN = 'PARTS_UPDATE_PART_BEGIN';
export const PARTS_UPDATE_PART_SUCCESS = 'PARTS_UPDATE_PART_SUCCESS';
export const PARTS_UPDATE_PART_FAILURE = 'PARTS_UPDATE_PART_FAILURE';
export const PARTS_UPDATE_PART_DISMISS_ERROR = 'PARTS_UPDATE_PART_DISMISS_ERROR';
export const PARTS_CREATE_PART_BEGIN = 'PARTS_CREATE_PART_BEGIN';
export const PARTS_CREATE_PART_SUCCESS = 'PARTS_CREATE_PART_SUCCESS';
export const PARTS_CREATE_PART_FAILURE = 'PARTS_CREATE_PART_FAILURE';
export const PARTS_CREATE_PART_DISMISS_ERROR = 'PARTS_CREATE_PART_DISMISS_ERROR';
export const PARTS_IMPORT_PARTS_BEGIN = 'PARTS_IMPORT_PARTS_BEGIN';
export const PARTS_IMPORT_PARTS_SUCCESS = 'PARTS_IMPORT_PARTS_SUCCESS';
export const PARTS_IMPORT_PARTS_FAILURE = 'PARTS_IMPORT_PARTS_FAILURE';
export const PARTS_IMPORT_PARTS_DISMISS_ERROR = 'PARTS_IMPORT_PARTS_DISMISS_ERROR';
export const PARTS_GET_ALL_PARTS_BEGIN = 'PARTS_GET_ALL_PARTS_BEGIN';
export const PARTS_GET_ALL_PARTS_SUCCESS = 'PARTS_GET_ALL_PARTS_SUCCESS';
export const PARTS_GET_ALL_PARTS_FAILURE = 'PARTS_GET_ALL_PARTS_FAILURE';
export const PARTS_GET_ALL_PARTS_DISMISS_ERROR = 'PARTS_GET_ALL_PARTS_DISMISS_ERROR';
