import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  EQUIPMENTS_SEARCH_EQUIPMENT_FROM_LAT_BEGIN,
  EQUIPMENTS_SEARCH_EQUIPMENT_FROM_LAT_SUCCESS,
  EQUIPMENTS_SEARCH_EQUIPMENT_FROM_LAT_FAILURE,
  EQUIPMENTS_SEARCH_EQUIPMENT_FROM_LAT_DISMISS_ERROR
} from './constants';
import ApiService from "../../common/services/ApiService";

export function searchEquipmentFromLat(latId) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: EQUIPMENTS_SEARCH_EQUIPMENT_FROM_LAT_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
        ApiService.searchAssetFromLat(latId).then(
        (res) => {
          dispatch({
            type: EQUIPMENTS_SEARCH_EQUIPMENT_FROM_LAT_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: EQUIPMENTS_SEARCH_EQUIPMENT_FROM_LAT_FAILURE,
            data: { error: err },
          });
          // reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissSearchEquipmentFromLatError() {
  return {
    type: EQUIPMENTS_SEARCH_EQUIPMENT_FROM_LAT_DISMISS_ERROR,
  };
}

export function useSearchEquipmentFromLat(params) {
  const dispatch = useDispatch();

  const { searchResultFromLat, searchEquipmentFromLatPending, searchEquipmentFromLatError } = useSelector(
    state => ({
      searchResultFromLat: state.equipments.searchResultFromLat,
      searchEquipmentFromLatPending: state.equipments.searchEquipmentFromLatPending,
      searchEquipmentFromLatError: state.equipments.searchEquipmentFromLatError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(searchEquipmentFromLat(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissSearchEquipmentFromLatError());
  }, [dispatch]);

  return {
    searchResultFromLat,
    searchEquipmentFromLat: boundAction,
    searchEquipmentFromLatPending,
    searchEquipmentFromLatError,
    dismissSearchEquipmentFromLatError: boundDismissError
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case EQUIPMENTS_SEARCH_EQUIPMENT_FROM_LAT_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        searchEquipmentFromLatPending: true,
        searchEquipmentFromLatError: null,
      };

    case EQUIPMENTS_SEARCH_EQUIPMENT_FROM_LAT_SUCCESS:
      // The request is success
      return {
        ...state,
        searchEquipmentFromLatPending: false,
        searchEquipmentFromLatError: null,
        searchResultFromLat: action.data,
      };

    case EQUIPMENTS_SEARCH_EQUIPMENT_FROM_LAT_FAILURE:
      // The request is failed
      return {
        ...state,
        searchEquipmentFromLatPending: false,
        searchEquipmentFromLatError: action.data.error,
      };

    case EQUIPMENTS_SEARCH_EQUIPMENT_FROM_LAT_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        searchEquipmentFromLatError: null,
      };

    default:
      return state;
  }
}
