import React from 'react';
import {Button, CircularProgress} from '@material-ui/core';


export default function LoadingButton({variant='contained', text='LOADING', color='primary', size='large', fullWidth=true}) {
  return (
    <Button
      variant={variant}
      size={size}
      color={color}
      className="common-loading-button"
      startIcon={<CircularProgress />}
      fullWidth={fullWidth}
      disabled
    >{text}</Button>
  );
};

