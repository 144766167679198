import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  PROFILE_UPDATE_PROFILE_BEGIN,
  PROFILE_UPDATE_PROFILE_SUCCESS,
  PROFILE_UPDATE_PROFILE_FAILURE,
  PROFILE_UPDATE_PROFILE_DISMISS_ERROR,
} from './constants';
import ApiService from '../../common/services/ApiService';
import AuthService from '../../authentication/services/AuthService';
import { isEmpty } from 'underscore';

export function updateProfile(args = {}) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: PROFILE_UPDATE_PROFILE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      ApiService.updateUserProfile(args).then(
        (res) => {
          if (!isEmpty(args.owner) && args.owner.ownerId) {
            ApiService.updateOwner(args.owner.ownerId, args.owner)
              .then(res1 => {
                console.log('res1: ',res1);
                AuthService.updateAuthUser({...res, owner: res1});
                let successMessage = 'Successfully updated profile';
                dispatch({
                  type: PROFILE_UPDATE_PROFILE_SUCCESS,
                  data: res,
                  message: successMessage,
                });
                resolve({
                  data: res,
                  message: successMessage,
                });
              }, err1 => {
                dispatch({
                  type: PROFILE_UPDATE_PROFILE_FAILURE,
                  data: { error: err1 },
                  message: err1.response.data.message,
                });
                reject(err1);
              })
          } else {
            AuthService.updateAuthUser(res);
            let successMessage = 'Successfully updated profile';
            dispatch({
              type: PROFILE_UPDATE_PROFILE_SUCCESS,
              data: res,
              message: successMessage,
            });
            resolve({
              data: res,
              message: successMessage,
            });
          }
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: PROFILE_UPDATE_PROFILE_FAILURE,
            data: { error: err },
            message: err.response.data.message,
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissUpdateProfileError() {
  return {
    type: PROFILE_UPDATE_PROFILE_DISMISS_ERROR,
  };
}

export function useUpdateProfile(params) {
  const dispatch = useDispatch();

  const { updateProfilePending, updateProfileError } = useSelector(
    state => ({
      updateProfilePending: state.profile.updateProfilePending,
      updateProfileError: state.profile.updateProfileError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(updateProfile(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissUpdateProfileError());
  }, [dispatch]);

  return {
    updateProfile: boundAction,
    updateProfilePending,
    updateProfileError,
    dismissUpdateProfileError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case PROFILE_UPDATE_PROFILE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        updateProfilePending: true,
        updateProfileError: null,
      };

    case PROFILE_UPDATE_PROFILE_SUCCESS:
      // The request is success
      return {
        ...state,
        updateProfilePending: false,
        updateProfileError: null,
        userProfile: action.data,
        updateProfileMessage: action.message,
      };

    case PROFILE_UPDATE_PROFILE_FAILURE:
      // The request is failed
      console.log('faiklee: ',action.data);
      return {
        ...state,
        updateProfilePending: false,
        updateProfileError: action.message,
      };

    case PROFILE_UPDATE_PROFILE_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        updateProfileError: null,
      };

    default:
      return state;
  }
}
