import React, { useContext, useEffect } from 'react';
import {
    Grid,
    Typography,
    Paper,
    Button
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AuthContext from '../authentication/AuthContext';
import { LoadingButton, PageHeader } from '../common';
import { AddEquipmentForm, AddWarrantyDialog } from './index';
import {useFormik} from 'formik';
// import * as Yup from 'yup';
import { useToggleAdminCommonDialog } from '../admin/redux/toggleAdminCommonDialog';
import { useGetCountries } from '../common/redux/getCountries';
import { useGetManufacturers } from '../manufacturers/redux/hooks';
import { AddManufacturerDialog } from '../manufacturers';
import { useGetParts } from '../parts/redux/hooks';
import { useCreateEquipment } from './redux/hooks';
import { clone, isEmpty } from 'underscore';
import {useGetOwners} from "./redux/getOwners";
import Alert from '@material-ui/lab/Alert';
import Swal from "sweetalert2";
import StringHelper from "../common/services/StringHelper";


// const validationSchema = Yup.object({
//     company: Yup.string().required('Required'),
//     serial: Yup.string().required('Required'),
//     owner: Yup.string().required('Required'),
// });


export default function AddEquipment(props) {
    const user = useContext(AuthContext);

    const queryParams = new URLSearchParams(props.history.location.search);
    const latId = queryParams.get('latid');

    const {toggleAdminCommonDialog} = useToggleAdminCommonDialog();
    const {createEquipment, createdEquipment, createEquipmentPending, createEquipmentError} = useCreateEquipment();

    const {getCountries, countries} = useGetCountries();
    const {getManufacturers, manufacturersData} = useGetManufacturers();
    const {getParts, partsData} = useGetParts();
    const {ownersData, getOwners} = useGetOwners();

    console.log('createdEquipment: ',createdEquipment);

    useEffect(() => {
        getManufacturers(1, 1000, 'ASC');
    }, [getManufacturers]);

    useEffect(() => {
        getCountries();
    }, [getCountries]);

    useEffect(() => {
        getOwners();
    }, [getOwners]);

    const parseEquipmentData = (values) => {
        let data = clone(values);

        delete data.address.hasAddress;
        delete data.assetId;
        delete data.owner;

        // dateOfInstall
        data.dateOfInstall = StringHelper.parseDateForSave(values.dateOfInstall);
        data.manufactureDate = StringHelper.parseDateForSave(values.manufactureDate);

        if (values.warranty.startDate) data.warranty.startDate = StringHelper.parseDateForSave(values.warranty.startDate);
        if (values.warranty.endDate) data.warranty.endDate = StringHelper.parseDateForSave(values.warranty.endDate);

        return data;
    };

    const formik = useFormik({
        initialValues: {
            manufacturerId: '',
            assetId: '',
            partId: '',
            serialNumber: '',
            latId: latId ? latId : '',
            ownerId: user.roleId !== 1 ? user.ownerId : '',
            notes: '',
            dateOfInstall: null,
            manufactureDate: null,

            address: {
                line1: '',
                line2: '',
                city: '',
                state: '',
                zipCode: '',
                country: 'United States',
                hasAddress: false,
            },

            warranty: {}
        },
        // validationSchema,
        enableReinitialize: true,
        onSubmit: values => {
            let data = parseEquipmentData(values);
            console.log('data: ',data);

            createEquipment(data)
              .then(res => {
                  Swal.fire({
                      icon: 'success',
                      text: res.message,
                      timer: 2000
                  }).then(() => {
                      props.history.push(`/equipment`);
                  });
              });
        },
    });

    useEffect(() => {
        if (formik.values.manufacturerId) {
            getParts(1, 1000, 'ASC', formik.values.manufacturerId);
        }
    }, [getParts, formik.values.manufacturerId]);

    return (
    <div className="equipments-add-equipment">
        <PageHeader location={props.location} />

        <form onSubmit={formik.handleSubmit}>
            <Paper className="equipments-add-equipment-body fob-content-body">
                <Grid item md={12}>
                    {
                        isEmpty(createEquipmentError)
                          ? null
                          :
                          <Alert elevation={0} variant="standard" severity="error">
                              {createEquipmentError.message}
                          </Alert>
                    }
                </Grid>

                <Grid container
                      direction="row"
                      justify="center"
                      alignItems="flex-start"
                      spacing={4}>

                    <Grid item
                          container
                          md={3}
                          className="fob-user-details-container"
                    >
                        <Grid item
                          container
                          md={12}
                        >
                            <Typography variant="p" display="block">{user.email}</Typography>
                        </Grid>
                        <Grid item
                          container
                          md={12}
                        >
                            <Typography variant="p" display="block">{user.owner && user.owner.company}</Typography>
                        </Grid>
                    </Grid>

                    {/*<Divider orientation="vertical"  flexItem />*/}

                    <Grid item md={5}>
                        <AddEquipmentForm
                          formik={formik}
                          toggleAdminCommonDialog={toggleAdminCommonDialog}
                          manufacturersData={manufacturersData}
                          partsData={partsData}
                          user={user}
                          createdEquipment={createdEquipment}
                          countries={countries}
                          ownersData={ownersData}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={3} className="equipments-add-equipment-warranty-service-section">
                        <div className="form-control-btn-group">
                            <Typography component="label" display="block" fullWidth>Warranty</Typography>
                            {
                                isEmpty(formik.values.warranty)
                                ? <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<AddCircleOutlineIcon />}
                                    className="rounded-btn"
                                    onClick={toggleAdminCommonDialog.bind(this, 'AddWarrantyDialog', true, {})}
                                  >Add Warranty</Button>
                                  : <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<CheckCircleOutlineIcon />}
                                    className="rounded-btn dark-blue-btn"
                                    onClick={toggleAdminCommonDialog.bind(this, 'AddWarrantyDialog', true, {})}
                                  >Warranty</Button>
                            }

                        </div>
                    </Grid>

                    <Grid container
                            justify="flex-end"
                            alignItems="center"
                            spacing={2}
                            className="equipments-add-equipment-action-btns"
                            sm={12}
                      >
                          <Grid item container xs={6} sm={6} md={2}>
                              {
                                  createEquipmentPending === true
                                  ? <LoadingButton />
                                  : <Button variant="contained"
                                                                     disableElevation
                                                                     color="secondary"
                                                                     type="submit"
                                                                     className="rounded-btn"
                                                                     fullWidth
                                    >
                                        Save
                                    </Button>
                              }
                          </Grid>

                          <Grid item container xs={6} sm={6} md={2}>
                              <Button variant="contained"
                                      disableElevation
                                      onClick={() => {
                                          props.history.push(`/equipment`);
                                      }}
                                      className="rounded-btn dark-btn"
                                      fullWidth
                                      disabled={createEquipmentPending === true}
                              >
                                  Cancel
                              </Button>
                          </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </form>

        <AddWarrantyDialog formik={formik} />

        <AddManufacturerDialog formik={formik} />
    </div>
  );
};
