import React, {useContext, useEffect, useState} from 'react';
import {PageHeader} from "../common";
import AuthContext from "../authentication/AuthContext";
import BaseTable from "../common/BaseTable";
import {AppBar, Button, Fab, Toolbar} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

const ASSET_TABLE_COLUMNS = [
    {key: 'description', label:'Model Number', order: 1, orderColumn: 'Model Number'},
    {key: 'manufacturer.description', label: 'Manufacturer', order: 2, orderColumn: 'Manufacturer'},
    {key: 'category.description', label:  'Category', order: 3, orderColumn: 'Category'},
    {key: 'manufacturer.address', label:'Location', order: 4, orderColumn: 'Location'},
];

const Header = (props) => {
    return (
        <AppBar position="relative" color="default" className="common-base-table-header">
            <Toolbar>
                <div style={{flexGrow: 1}} />

                <div className="header-actions">
                    <Button variant="contained" color="secondary" onClick={props.toggleUploadDialog}>
                        {props.uploadButtonText}
                    </Button>
                    {
                        props.handleAddItem
                            ? <Fab size="medium"
                                   color="secondary"
                                   aria-label="add"
                                   className="datatable-add-item-btn"
                                   onClick={props.handleAddItem}
                            >
                                <AddIcon />
                            </Fab>
                            : null
                    }
                </div>
            </Toolbar>
        </AppBar>
    )
};


function PartsDataTable(props) {
    const user = useContext(AuthContext);
    const data = props.data;

    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (!data || !data.rows) return;

        setRows(
            data.rows
        );
    }, [data]);

    const MyHeader = () => <Header user={user}
                                   toggleUploadDialog={props.toggleUploadDialog}
                                   uploadButtonText={props.uploadButtonText}
                                   handleAddItem={props.handleAddItem} />;


    return (
        <div className="common-common-data-table fob-content-body">
            <BaseTable headerComponent={MyHeader}
                       columns={props.columns}
                       rows={rows}
                       renderTableBody={props.renderTableBody}
                       onUpdateTableParamsCallback={props.onUpdateTableParamsCallback}
            />
        </div>
    );
};

export default function PartsDataDashboard(props) {
    const {location} = props;
    return (
        <div className="common-common-data-dashboard parts-data-dashboard">
            <PageHeader location={location} />
            <PartsDataTable columns={ASSET_TABLE_COLUMNS}
                             data={props.data}
                             renderTableBody={props.renderTableBody}
                             toggleUploadDialog={props.toggleUploadDialog}
                             uploadButtonText={props.uploadButtonText}
                             handleAddItem={props.handleAddItem}
                             onUpdateTableParamsCallback={props.onUpdateTableParamsCallback}
            />
        </div>
    );
};
