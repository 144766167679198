import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  EQUIPMENTS_GET_ASSET_ACTIVITIES_BEGIN,
  EQUIPMENTS_GET_ASSET_ACTIVITIES_SUCCESS,
  EQUIPMENTS_GET_ASSET_ACTIVITIES_FAILURE,
  EQUIPMENTS_GET_ASSET_ACTIVITIES_DISMISS_ERROR,
} from './constants';
import ApiService from "../../common/services/ApiService";

export function getAssetActivities(assetId, page, rowsPerPage, order, orderColumn) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: EQUIPMENTS_GET_ASSET_ACTIVITIES_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      ApiService.getActivities(assetId, page, rowsPerPage, order, orderColumn).then(
        (res) => {
          dispatch({
            type: EQUIPMENTS_GET_ASSET_ACTIVITIES_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: EQUIPMENTS_GET_ASSET_ACTIVITIES_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissGetAssetActivitiesError() {
  return {
    type: EQUIPMENTS_GET_ASSET_ACTIVITIES_DISMISS_ERROR,
  };
}

export function useGetAssetActivities(params) {
  const dispatch = useDispatch();

  const { assetActivities, getAssetActivitiesPending, getAssetActivitiesError } = useSelector(
    state => ({
      assetActivities: state.equipments.assetActivities,
      getAssetActivitiesPending: state.equipments.getAssetActivitiesPending,
      getAssetActivitiesError: state.equipments.getAssetActivitiesError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(getAssetActivities(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetAssetActivitiesError());
  }, [dispatch]);

  return {
    assetActivities,
    getAssetActivities: boundAction,
    getAssetActivitiesPending,
    getAssetActivitiesError,
    dismissGetAssetActivitiesError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case EQUIPMENTS_GET_ASSET_ACTIVITIES_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getAssetActivitiesPending: true,
        getAssetActivitiesError: null,
      };

    case EQUIPMENTS_GET_ASSET_ACTIVITIES_SUCCESS:
      // The request is success
      return {
        ...state,
        getAssetActivitiesPending: false,
        getAssetActivitiesError: null,
        assetActivities: action.data,
      };

    case EQUIPMENTS_GET_ASSET_ACTIVITIES_FAILURE:
      // The request is failed
      return {
        ...state,
        getAssetActivitiesPending: false,
        getAssetActivitiesError: action.data.error,
      };

    case EQUIPMENTS_GET_ASSET_ACTIVITIES_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getAssetActivitiesError: null,
      };

    default:
      return state;
  }
}
