import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
  DialogActions,
  Button,
  Toolbar,
  IconButton,
  Typography,
  Grid,
  CircularProgress
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
// import QrReader from 'react-qr-reader';
import QrReader from 'react-camera-qr';
import { useToggleQRCodeScannerDialog } from './redux/toggleQRCodeScannerDialog';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import StringHelper from "../common/services/StringHelper";
import {useSearchEquipmentFromQRCode} from "../equipments/redux/searchEquipmentFromQRCode";

export default function QRCodeScannerDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {toggleQRCodeScannerDialog} = useToggleQRCodeScannerDialog();
  const isQRCodeScannerDialogVisible = useSelector(state => state.common.isQRCodeScannerDialogVisible);
  const [scanResult, setScanResult] = useState('N/A');
  const {searchEquipmentFromQRCodePending, searchResultFromQRCode, searchEquipmentFromQRCode, searchEquipmentFromQRCodeError} = useSearchEquipmentFromQRCode();
  const [isQRCodeScannerVisible, setQRCodeScannerVisible] = useState(true);

  useEffect(() => {
    if(searchEquipmentFromQRCodePending) {
      setQRCodeScannerVisible(false);
    } else if(!searchEquipmentFromQRCodePending && searchResultFromQRCode && searchResultFromQRCode.assetId) {
      window.location.pathname = `/search/equipment/${searchResultFromQRCode.assetId}`;
    } else {
      if(searchEquipmentFromQRCodeError) {
        setScanResult(false);
      } else {
        setScanResult('N/A');
      }
      setQRCodeScannerVisible(true);
    }
  }, [searchEquipmentFromQRCodePending, searchResultFromQRCode, searchEquipmentFromQRCodeError]);

  const handleQRCodeScan = data => {
    if(!searchEquipmentFromQRCodePending) {
      if (data) {
        setScanResult(data);
        if(StringHelper.isUrlValid(data)) {
          if (StringHelper.isLatUrl(data)) {
            const latId = StringHelper.getLatIdFromUrl(data);
            window.location.pathname = `/search/lat/${latId}`;
          } else {
            searchEquipmentFromQRCode({manufacturerId: "1"}, "Manufacturer", data);
          }
        }
      }
    }
  }

  const handleQRCodeScanError = err => {
    console.error(err);
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isQRCodeScannerDialogVisible}
      className="common-qr-code-scanner-dialog fob-details-dialog"
    >
          <DialogTitle>
            <Toolbar>
              <Typography variant="h5" display="inline">Scan QR Code</Typography>
              <span style={{flexGrow: 1}}></span>
              <IconButton
                edge="end"
                color="inherit" aria-label="menu"
                onClick={toggleQRCodeScannerDialog.bind(this, false)}>
                <HighlightOffIcon />
              </IconButton>
            </Toolbar>

            <Divider />

          </DialogTitle>

          <DialogContent>
            <Grid container
                    direction="row"
                    justify="center"
                    alignItems="center">

                <Grid item xs={12} md={12}>
                    <p>Scan a QR Code for searching equipments.</p>
                    { isQRCodeScannerVisible ?
                      <QrReader
                        delay={300}
                        onError={handleQRCodeScanError}
                        onScan={handleQRCodeScan}
                        style={{ width: '100%' }}
                      />
                      :
                      <div className="loading-overlay qr-code-loading">
                        <CircularProgress color="primary" />
                      </div>
                    } 
                    { scanResult ?
                      <p>Result: { scanResult }</p>
                      :
                      <p>Serial Number not found within the system. Please request the equipment owner to add this asset to the Blockchain FOB - Lifecycle Asset Tracker.</p>
                    }
                </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button variant="contained"
                    disableElevation
                    onClick={toggleQRCodeScannerDialog.bind(this, false)}
                    className="rounded-btn dark-btn"
            >
              Close
            </Button>
          </DialogActions>
    </Dialog>
  );
};
