import React, { useState } from 'react';
import {
  Button, Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider, Grid,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useToggleAdminCommonDialog } from '../admin/redux/toggleAdminCommonDialog';
import { LoadingButton, UploadBox, DialogBox } from '../common';
import { useImportParts } from './redux/hooks';
import { isEmpty } from 'underscore';
import Alert from '@material-ui/lab/Alert';



export default function UploadPartsDialog() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDialog = useSelector(state => state.admin.toggleDialog);
  const {toggleAdminCommonDialog} = useToggleAdminCommonDialog();
  const [progress, setProgress] = useState(0);
  const {importParts, importPartsPending, importPartsError} = useImportParts();

  const handleFileOnDrop = (files) => {
    console.log('handleFileOnDrop',files);

    if (!files.length) return;

    importParts(files[0], (progressEvent) => {
      console.log('progressEvent: ',progressEvent);
      let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

      console.log("onUploadProgress", percentCompleted);

      setProgress(percentCompleted);
    });
  };

  return (
    <DialogBox
      fullScreen={fullScreen}
      open={toggleDialog.name === 'UploadPartsDialog' && toggleDialog.flag === true}
      className="parts-upload-parts-dialog fob-upload-dialog"
    >
        <DialogTitle>
          <Toolbar>
            <Typography variant="h5" display="inline">Upload Assets CSV File Format</Typography>
            <span style={{flexGrow: 1}}></span>
            <IconButton
              edge="end"
              color="inherit" aria-label="menu"
              onClick={toggleAdminCommonDialog.bind(this, 'UploadPartsDialog', false, {})}>
              <HighlightOffIcon />
            </IconButton>
          </Toolbar>
          <Divider />
        </DialogTitle>

        <DialogContent>
          <Container maxWidth="sm">
            <Grid item md={12}>
              {
                isEmpty(importPartsError)
                  ? null
                  :
                  <Alert elevation={0} variant="standard" severity="error">
                    {importPartsError.message}
                  </Alert>
              }
            </Grid>
          </Container>

          <section className="fob-dropzone-container">
            <UploadBox
              filesLimit={1}
              dropzoneClass="fob-dropzone"
              showFileNamesInPreview={true}
              useChipsForPreview={true}
              showPreviewsInDropzone={true}
              dropzoneText="Drag and drop or browse your files"
              onDrop={handleFileOnDrop}
              progress={progress}
            />
          </section>
        </DialogContent>

        <DialogActions>
          {
            importPartsPending === true
            ? <LoadingButton />
            : <Button variant="contained"
                      disableElevation
                      color="secondary"
                      className="rounded-btn"
                      onClick={toggleAdminCommonDialog.bind(this, 'UploadPartsDialog', false, {})}
              >
                Done
              </Button>
          }


        </DialogActions>

    </DialogBox>
  );
};

