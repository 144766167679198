export const AUTHENTICATION_REGISTER_BEGIN = 'AUTHENTICATION_REGISTER_BEGIN';
export const AUTHENTICATION_REGISTER_SUCCESS = 'AUTHENTICATION_REGISTER_SUCCESS';
export const AUTHENTICATION_REGISTER_FAILURE = 'AUTHENTICATION_REGISTER_FAILURE';
export const AUTHENTICATION_REGISTER_DISMISS_ERROR = 'AUTHENTICATION_REGISTER_DISMISS_ERROR';
export const AUTHENTICATION_SIGN_IN_BEGIN = 'AUTHENTICATION_SIGN_IN_BEGIN';
export const AUTHENTICATION_SIGN_IN_SUCCESS = 'AUTHENTICATION_SIGN_IN_SUCCESS';
export const AUTHENTICATION_SIGN_IN_FAILURE = 'AUTHENTICATION_SIGN_IN_FAILURE';
export const AUTHENTICATION_SIGN_IN_DISMISS_ERROR = 'AUTHENTICATION_SIGN_IN_DISMISS_ERROR';
export const AUTHENTICATION_FORGOT_PASSWORD_BEGIN = 'AUTHENTICATION_FORGOT_PASSWORD_BEGIN';
export const AUTHENTICATION_FORGOT_PASSWORD_SUCCESS = 'AUTHENTICATION_FORGOT_PASSWORD_SUCCESS';
export const AUTHENTICATION_FORGOT_PASSWORD_FAILURE = 'AUTHENTICATION_FORGOT_PASSWORD_FAILURE';
export const AUTHENTICATION_FORGOT_PASSWORD_DISMISS_ERROR = 'AUTHENTICATION_FORGOT_PASSWORD_DISMISS_ERROR';
export const AUTHENTICATION_SET_PASS_BEGIN = 'AUTHENTICATION_SET_PASS_BEGIN';
export const AUTHENTICATION_SET_PASS_SUCCESS = 'AUTHENTICATION_SET_PASS_SUCCESS';
export const AUTHENTICATION_SET_PASS_FAILURE = 'AUTHENTICATION_SET_PASS_FAILURE';
export const AUTHENTICATION_SET_PASS_DISMISS_ERROR = 'AUTHENTICATION_SET_PASS_DISMISS_ERROR';
export const AUTHENTICATION_GET_USER_FROM_TEMP_TOKEN_BEGIN = 'AUTHENTICATION_GET_USER_FROM_TEMP_TOKEN_BEGIN';
export const AUTHENTICATION_GET_USER_FROM_TEMP_TOKEN_SUCCESS = 'AUTHENTICATION_GET_USER_FROM_TEMP_TOKEN_SUCCESS';
export const AUTHENTICATION_GET_USER_FROM_TEMP_TOKEN_FAILURE = 'AUTHENTICATION_GET_USER_FROM_TEMP_TOKEN_FAILURE';
export const AUTHENTICATION_GET_USER_FROM_TEMP_TOKEN_DISMISS_ERROR = 'AUTHENTICATION_GET_USER_FROM_TEMP_TOKEN_DISMISS_ERROR';
export const AUTHENTICATION_TOGGLE_BIOMETRIC_USAGE_PROMPT_DIALOG = 'AUTHENTICATION_TOGGLE_BIOMETRIC_USAGE_PROMPT_DIALOG';

