import stateList from './assets/state.json';
import {compare} from './utils';

// Get a list of states belonging to a specific country.
const getCountryStates = (countryCode) => {
    const states = stateList.filter((value) => {
        return value.countryCode === countryCode;
    });
    return states.sort(compare);
};

export {
    getCountryStates,
};