import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  ROLES_CREATE_ROLE_BEGIN,
  ROLES_CREATE_ROLE_SUCCESS,
  ROLES_CREATE_ROLE_FAILURE,
  ROLES_CREATE_ROLE_DISMISS_ERROR,
} from './constants';
import ApiService from '../../common/services/ApiService';

export function createRole(data) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: ROLES_CREATE_ROLE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      ApiService.createRole(data).then(
        (res) => {
          dispatch({
            type: ROLES_CREATE_ROLE_SUCCESS,
            data: res,
          });
          resolve({...res, message: 'Successfully created role.'});
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: ROLES_CREATE_ROLE_FAILURE,
            data: {
              error: {
                message: err && err.response && err.response.data ? err.response.data.message : 'Unable to create role.'
              }
            },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissCreateRoleError() {
  return {
    type: ROLES_CREATE_ROLE_DISMISS_ERROR,
  };
}

export function useCreateRole(params) {
  const dispatch = useDispatch();

  const { createRolePending, createRoleError } = useSelector(
    state => ({
      createRolePending: state.roles.createRolePending,
      createRoleError: state.roles.createRoleError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(createRole(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissCreateRoleError());
  }, [dispatch]);

  return {
    createRole: boundAction,
    createRolePending,
    createRoleError,
    dismissCreateRoleError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case ROLES_CREATE_ROLE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        createRolePending: true,
        createRoleError: null,
      };

    case ROLES_CREATE_ROLE_SUCCESS:
      // The request is success
      return {
        ...state,
        createRolePending: false,
        createRoleError: null,
        newRoles: [action.data],
      };

    case ROLES_CREATE_ROLE_FAILURE:
      // The request is failed
      return {
        ...state,
        createRolePending: false,
        createRoleError: action.data.error,
      };

    case ROLES_CREATE_ROLE_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        createRoleError: null,
      };

    default:
      return state;
  }
}
