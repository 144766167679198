import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  EQUIPMENTS_TOGGLE_ACTIVITY_IMAGE_DIALOG,
} from './constants';

export function toggleActivityImageDialog(flag=false, fileName=null, activityFiles=[]) {
  let filteredFiles = [];
  if (activityFiles.length) {
    filteredFiles = activityFiles.filter(f => {
      return f.split('.').pop().toLowerCase() !== 'pdf';
    });
  }
  return {
    type: EQUIPMENTS_TOGGLE_ACTIVITY_IMAGE_DIALOG,
    flag: flag,
    fileName: fileName,
    activityFiles: filteredFiles,
  };
}

export function useToggleActivityImageDialog() {
  const dispatch = useDispatch();
  const isActivityImageDialogVisible = useSelector(state => state.equipments.isActivityImageDialogVisible);
  const activityImage = useSelector(state => state.equipments.activityImage);
  const activityFiles = useSelector(state => state.equipments.activityFiles);
  const boundAction = useCallback((...params) => dispatch(toggleActivityImageDialog(...params)), [dispatch]);
  return { isActivityImageDialogVisible, toggleActivityImageDialog: boundAction, activityImage: activityImage, activityFiles: activityFiles };
}

export function reducer(state, action) {
  switch (action.type) {
    case EQUIPMENTS_TOGGLE_ACTIVITY_IMAGE_DIALOG:
      return {
        ...state,
        isActivityImageDialogVisible: action.flag,
        activityImage: action.fileName,
        activityFiles: action.activityFiles,
      };

    default:
      return state;
  }
}
