import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  EQUIPMENTS_GET_ACTIVITY_TYPES_BEGIN,
  EQUIPMENTS_GET_ACTIVITY_TYPES_SUCCESS,
  EQUIPMENTS_GET_ACTIVITY_TYPES_FAILURE,
  EQUIPMENTS_GET_ACTIVITY_TYPES_DISMISS_ERROR,
} from './constants';
import ApiService from '../../common/services/ApiService';

export function getActivityTypes(args = {}) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: EQUIPMENTS_GET_ACTIVITY_TYPES_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      ApiService.getActivityTypes().then(
        (res) => {
          dispatch({
            type: EQUIPMENTS_GET_ACTIVITY_TYPES_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: EQUIPMENTS_GET_ACTIVITY_TYPES_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissGetActivityTypesError() {
  return {
    type: EQUIPMENTS_GET_ACTIVITY_TYPES_DISMISS_ERROR,
  };
}

export function useGetActivityTypes(params) {
  const dispatch = useDispatch();

  const { activityTypeData, getActivityTypesPending, getActivityTypesError } = useSelector(
    state => ({
      activityTypeData: state.equipments.activityTypeData,
      getActivityTypesPending: state.equipments.getActivityTypesPending,
      getActivityTypesError: state.equipments.getActivityTypesError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(getActivityTypes(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetActivityTypesError());
  }, [dispatch]);

  return {
    activityTypeData,
    getActivityTypes: boundAction,
    getActivityTypesPending,
    getActivityTypesError,
    dismissGetActivityTypesError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case EQUIPMENTS_GET_ACTIVITY_TYPES_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getActivityTypesPending: true,
        getActivityTypesError: null,
      };

    case EQUIPMENTS_GET_ACTIVITY_TYPES_SUCCESS:
      // The request is success
      return {
        ...state,
        getActivityTypesPending: false,
        getActivityTypesError: null,
        activityTypeData: action.data,
      };

    case EQUIPMENTS_GET_ACTIVITY_TYPES_FAILURE:
      // The request is failed
      return {
        ...state,
        getActivityTypesPending: false,
        getActivityTypesError: action.data.error,
      };

    case EQUIPMENTS_GET_ACTIVITY_TYPES_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getActivityTypesError: null,
      };

    default:
      return state;
  }
}
