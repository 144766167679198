import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  DASHBOARD_GET_ASSET_MOVEMENT_BY_STATUS_CATEGORY_AGGREGATION_BEGIN,
  DASHBOARD_GET_ASSET_MOVEMENT_BY_STATUS_CATEGORY_AGGREGATION_SUCCESS,
  DASHBOARD_GET_ASSET_MOVEMENT_BY_STATUS_CATEGORY_AGGREGATION_FAILURE,
  DASHBOARD_GET_ASSET_MOVEMENT_BY_STATUS_CATEGORY_AGGREGATION_DISMISS_ERROR,
} from './constants';
import ApiService from "../../common/services/ApiService";

const TEST_DATA = [
  { label: 'Onhand - Suppliers', value: '5430', valuePosition: 'outside' },
  { label: 'Shipped - In Transit', value: '2545', valuePosition: 'outside' },
  { label: 'Delivered', value: '1055', valuePosition: 'outside' },
  { label: 'Installed', value: '115', valuePosition: 'outside' },
];

export function getAssetMovementByStatusCategoryAggregation(searchValue, startDate, endDate) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: DASHBOARD_GET_ASSET_MOVEMENT_BY_STATUS_CATEGORY_AGGREGATION_BEGIN,
    });

    const startDateStr = startDate ? startDate.format('YYYY-MM-DD') : '';
    const endDateStr = endDate ? endDate.format('YYYY-MM-DD') : '';

    const promise = new Promise((resolve, reject) => {
      ApiService.getAssetMovementByStatusCategoryAggregation(searchValue, startDateStr, endDateStr).then(
        (res) => {
          dispatch({
            type: DASHBOARD_GET_ASSET_MOVEMENT_BY_STATUS_CATEGORY_AGGREGATION_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: DASHBOARD_GET_ASSET_MOVEMENT_BY_STATUS_CATEGORY_AGGREGATION_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissGetAssetMovementByStatusCategoryAggregationError() {
  return {
    type: DASHBOARD_GET_ASSET_MOVEMENT_BY_STATUS_CATEGORY_AGGREGATION_DISMISS_ERROR,
  };
}

export function useGetAssetMovementByStatusCategoryAggregation(params) {
  const dispatch = useDispatch();

  const { getAssetMovementByStatusCategoryAggregationPending, getAssetMovementByStatusCategoryAggregationError, getAssetMovementByStatusCategoryAggregationData } = useSelector(
    state => ({
      getAssetMovementByStatusCategoryAggregationPending: state.dashboard.getAssetMovementByStatusCategoryAggregationPending,
      getAssetMovementByStatusCategoryAggregationError: state.dashboard.getAssetMovementByStatusCategoryAggregationError,
      getAssetMovementByStatusCategoryAggregationData: state.dashboard.getAssetMovementByStatusCategoryAggregationData,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((searchValue, startDate, endDate) => {
    return dispatch(getAssetMovementByStatusCategoryAggregation(searchValue, startDate, endDate));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetAssetMovementByStatusCategoryAggregationError());
  }, [dispatch]);

  return {
    getAssetMovementByStatusCategoryAggregation: boundAction,
    getAssetMovementByStatusCategoryAggregationPending,
    getAssetMovementByStatusCategoryAggregationError,
    getAssetMovementByStatusCategoryAggregationData,
    dismissGetAssetMovementByStatusCategoryAggregationError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case DASHBOARD_GET_ASSET_MOVEMENT_BY_STATUS_CATEGORY_AGGREGATION_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getAssetMovementByStatusCategoryAggregationPending: true,
        getAssetMovementByStatusCategoryAggregationError: null,
      };

    case DASHBOARD_GET_ASSET_MOVEMENT_BY_STATUS_CATEGORY_AGGREGATION_SUCCESS:
      // The request is success
      return {
        ...state,
        getAssetMovementByStatusCategoryAggregationPending: false,
        getAssetMovementByStatusCategoryAggregationError: null,
        getAssetMovementByStatusCategoryAggregationData: action.data,
      };

    case DASHBOARD_GET_ASSET_MOVEMENT_BY_STATUS_CATEGORY_AGGREGATION_FAILURE:
      // The request is failed
      return {
        ...state,
        getAssetMovementByStatusCategoryAggregationPending: false,
        getAssetMovementByStatusCategoryAggregationError: action.data.error,
      };

    case DASHBOARD_GET_ASSET_MOVEMENT_BY_STATUS_CATEGORY_AGGREGATION_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getAssetMovementByStatusCategoryAggregationError: null,
      };

    default:
      return state;
  }
}
