import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  MANUFACTURERS_IMPORT_MANUFACTURERS_BEGIN,
  MANUFACTURERS_IMPORT_MANUFACTURERS_SUCCESS,
  MANUFACTURERS_IMPORT_MANUFACTURERS_FAILURE,
  MANUFACTURERS_IMPORT_MANUFACTURERS_DISMISS_ERROR,
} from './constants';
import ApiService from '../../common/services/ApiService';

export function importManufacturers(file, onUploadProgress) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: MANUFACTURERS_IMPORT_MANUFACTURERS_BEGIN,
    });

    let formdata = new FormData();
    formdata.append('csv', file);

    const promise = new Promise((resolve, reject) => {
      ApiService.importManufacturers(formdata, onUploadProgress).then(
        (res) => {
          dispatch({
            type: MANUFACTURERS_IMPORT_MANUFACTURERS_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: MANUFACTURERS_IMPORT_MANUFACTURERS_FAILURE,
            data: {
              error: {
                message: err && err.response && err.response.data ? err.response.data.message : 'Unable to import data.'
              }
            },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissImportManufacturersError() {
  return {
    type: MANUFACTURERS_IMPORT_MANUFACTURERS_DISMISS_ERROR,
  };
}

export function useImportManufacturers(params) {
  const dispatch = useDispatch();

  const { successMessage, importManufacturersPending, importManufacturersError } = useSelector(
    state => ({
      successMessage: state.manufacturers.successMessage,
      importManufacturersPending: state.manufacturers.importManufacturersPending,
      importManufacturersError: state.manufacturers.importManufacturersError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(importManufacturers(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissImportManufacturersError());
  }, [dispatch]);

  return {
    successMessage,
    importManufacturers: boundAction,
    importManufacturersPending,
    importManufacturersError,
    dismissImportManufacturersError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANUFACTURERS_IMPORT_MANUFACTURERS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        importManufacturersPending: true,
        importManufacturersError: null,
      };

    case MANUFACTURERS_IMPORT_MANUFACTURERS_SUCCESS:
      // The request is success
      return {
        ...state,
        importManufacturersPending: false,
        importManufacturersError: null,
        newManufacturers: action.data,
      };

    case MANUFACTURERS_IMPORT_MANUFACTURERS_FAILURE:
      // The request is failed
      return {
        ...state,
        importManufacturersPending: false,
        importManufacturersError: action.data.error,
      };

    case MANUFACTURERS_IMPORT_MANUFACTURERS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        importManufacturersError: null,
      };

    default:
      return state;
  }
}
