import _ from 'underscore';


const EQUIPMENT_KEY_COLS = [
  {key: 'manufacture', label: 'Manufacturer', order: 1, orderColumn: 'Manufacturer'},
  {key: 'serialNumber', label: 'Serial Number', order: 2, orderColumn: 'SerialNumber'},
  {key: 'latId', label: 'LATID', order: 3, orderColumn: 'LATID'},
  {key: 'warranty', label: 'Warranty', order: 4, orderColumn: 'Warranty'},
  {key: 'serviceAgreement', label: 'Service Agreement', order: 5, orderColumn: 'ServiceAgreement'},
  {key: 'description', label: 'Description', order: 6, orderColumn: 'Description'},
  {key: 'location', label: 'Location', order: 7, orderColumn: 'Location'},
];

const EQUIPMENT_ACTIVITY_KEY_COLS = [
  {key: 'activity', label: 'Activity', order: 1, orderColumn: 'Activity'},
  {key: 'activityDate', label: 'Date', order: 2, orderColumn: 'Date'},
  {key: 'authorizedBy', label: 'Authorized By', order: 3, orderColumn: 'AuthorizedBy'},
  {key: 'notes', label: 'Notes', order: 4, orderColumn: 'Notes'},
];

export default class EquipmentsHelper {
  static getMyEquipmentTableColumns() {
    return _.sortBy(EQUIPMENT_KEY_COLS, item => {
      return item.order
    }).map(item => {
      return {label: item.label, orderColumn: item.orderColumn};
    });
  }

  static getMyEquipmentColumns() {
    return _.sortBy(EQUIPMENT_KEY_COLS, item => {
      return item.order
    }).map(item => {
      return item.label;
    });
  }

  static getEquipmentActivityColumns() {
    return _.sortBy(EQUIPMENT_ACTIVITY_KEY_COLS, item => {
      return item.order
    }).map(item => {
      return {label: item.label, orderColumn: item.orderColumn};
    });
  }
}