import React from 'react';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';


export default function AuthenticationIndex(props) {
  const isXs = useMediaQuery(theme => theme.breakpoints.down('xs'));
  console.log('props: ',props);
  const pathname = props.location.pathname.split('/')[2];
  const [page, setPage] = React.useState(pathname);

  const handlePageChange = (e, val) => {
    setPage(val);
    props.history.replace(`/auth/${val}`);
  };

  return (
    <Grid container
          direction="column"
          justify="center"
          alignItems="center"
          className="authentication-authentication-index">

      <Grid container
            item
            xs={12}
            lg={12}
            direction="column"
            className="authentication-grid-item"
      >
        <div className="authentication-content">
          <Grid container item>
            <Grid item md={7} xs={0}></Grid>

            <Grid item md={5} xs={12} className="authentication-content-right">
              <section className={`${isXs ? "authentication-btn-group authentication-btn-group-xs" : "authentication-btn-group"}`}>
                <ToggleButtonGroup
                  exclusive
                  value={page}
                  onChange={handlePageChange}
                >
                  <ToggleButton value="register" selected={page === 'register'}>
                    Register
                  </ToggleButton>
                  <ToggleButton value="signin" selected={page !== 'register'}>
                    Log in
                  </ToggleButton>
                </ToggleButtonGroup>
              </section>

              {props.children}
            </Grid>
          </Grid>
        </div>

        <Typography variant="p" component="p" className="authentication-copyright">Copyright © 2022 Blockchain FOB</Typography>
      </Grid>
    </Grid>
  
  );
};
