import Cookies from 'js-cookie';
import {isEmpty} from 'underscore';


const SEARCH_PAGE_SEARCH_CRITERIA = 'SEARCH_CRITERIA';
const MY_EQUIPMENT_TABLE_INFO = 'MY_EQUIPMENT_TABLE_INFO';


export default class CacheService {

    static set(key, data) {
        Cookies.set(key, data);
    }

    static get(key) {
        let data = Cookies.get(key);
        if (!isEmpty(data)) data = JSON.parse(data);
        return data;
    }

    static remove(key) {
        Cookies.remove(key);
    }

    static saveSearchPageFilterCriteria(values, tableInfo) {
        let criteria = {values: values, sort: tableInfo.sort, page: tableInfo.page, rowsPerPage: tableInfo.rowsPerPage, urlParam: tableInfo.urlParam};
        CacheService.set(SEARCH_PAGE_SEARCH_CRITERIA, criteria);
    }

    static getSearchPageSavedFilterCriteria() {
        return CacheService.get(SEARCH_PAGE_SEARCH_CRITERIA);
    }

    static resetSearchPageSavedFilterCriteria() {
        CacheService.remove(SEARCH_PAGE_SEARCH_CRITERIA);
    }

    static saveMyEquipmentTableInfo(tableInfo) {
        CacheService.set(MY_EQUIPMENT_TABLE_INFO, tableInfo);
    }

    static getMyEquipmentTableInfo() {
        return CacheService.get(MY_EQUIPMENT_TABLE_INFO);
    }

    static resetMyEquipmentTableInfo() {
        CacheService.remove(MY_EQUIPMENT_TABLE_INFO);
    }
}