import React, {useCallback, useState, useEffect} from 'react';
import {CircularProgress, Grid, Typography, Paper} from "@material-ui/core";
import {GREEN_HEX, LIFECYCLE_TRACKING_HEADER_TEXT, RED_HEX, YELLOW_HEX} from "./redux/constants";
import Chart2DDoughnutPaper from "./charts/widgets/Chart2DDoughnutPaper";
import {debounce} from "underscore";
import {useGetAssetCostOfUseAggregation} from "./redux/getAssetCostOfUseAggregation";
import {useGetAssetWarrantyRemaining} from "./redux/hooks";
import {useGetAssetsAddressByUser} from "../equipments/redux/getAssetsAddressByUser";

function AssetCostOfUse({assetsAddresses}) {
    const {getAssetCostOfUseAggregation, getAssetCostOfUseAggregationPending, assetCostOfUseAggregationData} = useGetAssetCostOfUseAggregation();
    const [searchValue, setSearchValue] = useState(null);
    const [dateRange, setDateRange] = useState({startDate: null, endDate: null});

    useEffect(() => {
        getAssetCostOfUseAggregation('', null, null);
    }, [getAssetCostOfUseAggregation]);

    const handleSearchValueChanged = useCallback((value) => {
        setSearchValue(value);
    }, []);

    const handleDateRangeChanged = useCallback(({startDate, endDate}) => {
        setDateRange({startDate, endDate});
    }, []);

    const handleApplyFilter = useCallback(() => {
        const address = searchValue && searchValue.hasOwnProperty('Description') ? searchValue.Description : searchValue;
        getAssetCostOfUseAggregation(address, dateRange.startDate, dateRange.endDate);
    }, [getAssetCostOfUseAggregation, searchValue, dateRange]);

    return <Chart2DDoughnutPaper title="Cost of Use"
                                 loading={getAssetCostOfUseAggregationPending}
                                 data={assetCostOfUseAggregationData}
                                 searchValue={searchValue}
                                 dateRange={dateRange}
                                 assetsAddresses={assetsAddresses}
                                 onSearchValueChanged={handleSearchValueChanged}
                                 onDateRangeChanged={handleDateRangeChanged}
                                 handleApplyFilter={handleApplyFilter}
                                 otherChartOptions={{formatNumber: '1', numberPrefix: '$', formatNumberScale: '0'}} />
}

function WarrantyRemainingLegend() {
    return (
      <Paper className="warranty-legend">
          <ul>
              <li>
                  <span className="warranty-legend-color"
                        style={{backgroundColor: GREEN_HEX}}></span>
                  <Typography variant="body2" component="span">90+ days</Typography>
              </li>

              <li>
                  <span className="warranty-legend-color"
                        style={{backgroundColor: YELLOW_HEX}}></span>
                  <Typography variant="body2" component="span">60-31 days</Typography>
              </li>

              <li>
                  <span className="warranty-legend-color"
                        style={{backgroundColor: RED_HEX}}></span>
                  <Typography variant="body2" component="span">30 and below days</Typography>
              </li>
          </ul>
      </Paper>
    );
}

function AssetWarrantyRemaining() {
    const {getAssetWarrantyRemaining, warrantyRemainingData, getAssetWarrantyRemainingPending} = useGetAssetWarrantyRemaining();
    const [searchValue, setSearchValue] = useState('');
    const [dateRange, setDateRange] = useState({startDate: null, endDate: null});

    useEffect(() => {
        getAssetWarrantyRemaining();
    }, [getAssetWarrantyRemaining]);

    return (
        <Chart2DDoughnutPaper title="Warranty Remaining"
                              loading={getAssetWarrantyRemainingPending}
                              data={warrantyRemainingData}
                              searchValue={searchValue}
                              dateRange={dateRange}
                              showFilters={false}
                              height={450}
                              otherChartOptions={{
                                  showLegend: false,
                                  showToolTip: 0,
                                  showValues: 0,
                                  chartBottomMargin: 0,
                                  chartTopMargin: 0,
                                  captionPadding: 0
                              }}
                              customLegend={<WarrantyRemainingLegend />}
       />
    );
}

export default function LifecycleTracking() {
  const {getAssetsAddressByUser, assetsAddresses} = useGetAssetsAddressByUser();

  useEffect(() => {
      getAssetsAddressByUser();
  }, [getAssetsAddressByUser]);

  return (
    <Grid className="dashboard-lifecycle-tracking"
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center">
        <Grid item>
            <Typography color="primary" className="dashboard-dashboard-content-header-title" variant="h6">{LIFECYCLE_TRACKING_HEADER_TEXT}</Typography>
        </Grid>

        <Grid item
              container
              direction="row"
              alignItems="center"
              spacing={2}
              style={{justifyContent: 'space-around'}}>
            <Grid item
                  container
                  alignItems="center"
                  style={{justifyContent: 'center'}}
                  md={12}
                  lg={6}>
                <AssetCostOfUse assetsAddresses={assetsAddresses} />
            </Grid>

            <Grid item
                  container
                  alignItems="center"
                  style={{justifyContent: 'center'}}
                  md={12}
                  lg={6}>
                <AssetWarrantyRemaining />
            </Grid>
        </Grid>
    </Grid>
  );
};
