export default class WebviewCommunication {

    static checkReactNativeWebview() {
        if (!window.ReactNativeWebView) {
            throw new Error('Undefined ReactNativeWebView');
        }
    }

    static postMessage(action, payload) {
        try {
            WebviewCommunication.checkReactNativeWebview();
            let data = {
                'action': action,
                'payload': payload,
            };
            window.ReactNativeWebView.postMessage(JSON.stringify(data));
        } catch(err) {
            console.error(err);
        }
    }
}