import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  PARTS_IMPORT_PARTS_BEGIN,
  PARTS_IMPORT_PARTS_SUCCESS,
  PARTS_IMPORT_PARTS_FAILURE,
  PARTS_IMPORT_PARTS_DISMISS_ERROR,
} from './constants';
import ApiService from '../../common/services/ApiService';

export function importParts(file, onUploadProgress) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: PARTS_IMPORT_PARTS_BEGIN,
    });

    let formdata = new FormData();
    formdata.append('csv', file);

    const promise = new Promise((resolve, reject) => {
      ApiService.importParts(formdata, onUploadProgress).then(
        (res) => {
          dispatch({
            type: PARTS_IMPORT_PARTS_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: PARTS_IMPORT_PARTS_FAILURE,
            data: {
              error: {
                message: err && err.response && err.response.data ? err.response.data.message : 'Unable to import data.'
              }
            },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissImportPartsError() {
  return {
    type: PARTS_IMPORT_PARTS_DISMISS_ERROR,
  };
}

export function useImportParts(params) {
  const dispatch = useDispatch();

  const { newParts, importPartsPending, importPartsError } = useSelector(
    state => ({
      newParts: state.parts.newParts,
      importPartsPending: state.parts.importPartsPending,
      importPartsError: state.parts.importPartsError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(importParts(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissImportPartsError());
  }, [dispatch]);

  return {
    newParts,
    importParts: boundAction,
    importPartsPending,
    importPartsError,
    dismissImportPartsError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case PARTS_IMPORT_PARTS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        importPartsPending: true,
        importPartsError: null,
      };

    case PARTS_IMPORT_PARTS_SUCCESS:
      // The request is success
      return {
        ...state,
        importPartsPending: false,
        importPartsError: null,
        newParts: action.data,
      };

    case PARTS_IMPORT_PARTS_FAILURE:
      // The request is failed
      return {
        ...state,
        importPartsPending: false,
        importPartsError: action.data.error,
      };

    case PARTS_IMPORT_PARTS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        importPartsError: null,
      };

    default:
      return state;
  }
}
