import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  MANUFACTURERS_SAVE_MANUFACTURER_BEGIN,
  MANUFACTURERS_SAVE_MANUFACTURER_SUCCESS,
  MANUFACTURERS_SAVE_MANUFACTURER_FAILURE,
  MANUFACTURERS_SAVE_MANUFACTURER_DISMISS_ERROR,
} from './constants';
import ApiService from '../../common/services/ApiService';

export function saveManufacturer(data) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: MANUFACTURERS_SAVE_MANUFACTURER_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      ApiService.createManufacturer(data).then(
        (res) => {
          dispatch({
            type: MANUFACTURERS_SAVE_MANUFACTURER_SUCCESS,
            data: res,
          });
          resolve({...res, message: 'Successfully created manufacturer.'});
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: MANUFACTURERS_SAVE_MANUFACTURER_FAILURE,
            data: {
              error: {
                message: err && err.response && err.response.data ? err.response.data.message : 'Unable to create manufacturer.'
              }
            },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissSaveManufacturerError() {
  return {
    type: MANUFACTURERS_SAVE_MANUFACTURER_DISMISS_ERROR,
  };
}

export function useSaveManufacturer(params) {
  const dispatch = useDispatch();

  const { saveManufacturerPending, saveManufacturerError } = useSelector(
    state => ({
      saveManufacturerPending: state.manufacturers.saveManufacturerPending,
      saveManufacturerError: state.manufacturers.saveManufacturerError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(saveManufacturer(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissSaveManufacturerError());
  }, [dispatch]);

  return {
    saveManufacturer: boundAction,
    saveManufacturerPending,
    saveManufacturerError,
    dismissSaveManufacturerError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANUFACTURERS_SAVE_MANUFACTURER_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        saveManufacturerPending: true,
        saveManufacturerError: null,
      };

    case MANUFACTURERS_SAVE_MANUFACTURER_SUCCESS:
      // The request is success
      return {
        ...state,
        saveManufacturerPending: false,
        saveManufacturerError: null,
        newManufacturers: [action.data],
      };

    case MANUFACTURERS_SAVE_MANUFACTURER_FAILURE:
      // The request is failed
      return {
        ...state,
        saveManufacturerPending: false,
        saveManufacturerError: action.data.error,
      };

    case MANUFACTURERS_SAVE_MANUFACTURER_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        saveManufacturerError: null,
      };

    default:
      return state;
  }
}
