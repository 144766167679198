// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { HelpDashboard } from './';

export default {
  path: 'help',
  childRoutes: [
    { path: '', component: HelpDashboard },
  ],
};
