import {
  drawerWidth,
  drawerWidthClosed,
  topNavHeight,
  transition,
  container
} from "./global-styles.js";

const appStyle = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },
  sidePanel: {
    [theme.breakpoints.down("sm")]: {
      width: `${drawerWidthClosed}px`,
      marginLeft: `-${drawerWidthClosed}px`,
      ...transition,
      backgroundColor: "#ffffff",
      height: "100%",
      overflowY: "hidden",
    },
    width: `${drawerWidth}px`,
    overflowY: "auto",
    overflowX: "hidden",
    overflowScrolling: "touch",
    maxHeight: "100%",
  },
  sidePanelClosed: {
    // [theme.breakpoints.up("md")]: {
    // },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
      ...transition,
    },
    width: `${drawerWidthClosed}px`,
    overflowX: "hidden",
    maxHeight: "100%",
    ul: {
      li: {
        backgroundColor: 'red'

      }
    }
  },
  fullscreenPanel: {
    height: "inherit",
    overflow: "auto",
    // position: "relative",
    ...transition,
    // maxHeight: "100%",
    width: "100%",
    maxWidth: "100%",
    padding: "0",
    overflowScrolling: "touch"
  },
  mainPanelExpanded: {
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
    // [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidthClosed}px) !important`
    // },
  },
  mainPanel: {
    [theme.breakpoints.down("sm")]: {
      marginTop: `${topNavHeight}px`,
      width: "100%",
    },
    width: `calc(100% - ${drawerWidth}px)`,
    height: `calc(100vh - ${topNavHeight}px)`,
    marginTop: "0px",
    overflow: "hidden",
    // position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    overflowScrolling: "touch"
  },
  content: {
    marginTop: "70px",
    padding: "30px 15px",
    minHeight: "calc(100vh - 123px)"
  },
  container,
  map: {
    marginTop: "70px"
  }
});

export default appStyle;
