import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  MANUFACTURERS_TOGGLE_MANUFACTURER_DETAILS_DIALOG,
} from './constants';
import { isNull } from 'underscore';

export function toggleManufacturerDetailsDialog(flag=null, manufacturerId=null) {
  return {
    type: MANUFACTURERS_TOGGLE_MANUFACTURER_DETAILS_DIALOG,
    flag: flag,
    manufacturerId: manufacturerId,
  };
}

export function useToggleManufacturerDetailsDialog() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => dispatch(toggleManufacturerDetailsDialog(...params)), [dispatch]);
  return { toggleManufacturerDetailsDialog: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANUFACTURERS_TOGGLE_MANUFACTURER_DETAILS_DIALOG:
      return {
        ...state,
        isManufacturerDetailsDialogVisible: isNull(action.flag) ? !state.isManufacturerDetailsDialogVisible : action.flag,
        manufacturerId: action.manufacturerId,
      };

    default:
      return state;
  }
}
