export const ROLES_MANUFACTURER_ROLE_ID = 2;

export const ROLES_GET_ROLES_BEGIN = 'ROLES_GET_ROLES_BEGIN';
export const ROLES_GET_ROLES_SUCCESS = 'ROLES_GET_ROLES_SUCCESS';
export const ROLES_GET_ROLES_FAILURE = 'ROLES_GET_ROLES_FAILURE';
export const ROLES_GET_ROLES_DISMISS_ERROR = 'ROLES_GET_ROLES_DISMISS_ERROR';
export const ROLES_UPDATE_ROLE_BEGIN = 'ROLES_UPDATE_ROLE_BEGIN';
export const ROLES_UPDATE_ROLE_SUCCESS = 'ROLES_UPDATE_ROLE_SUCCESS';
export const ROLES_UPDATE_ROLE_FAILURE = 'ROLES_UPDATE_ROLE_FAILURE';
export const ROLES_UPDATE_ROLE_DISMISS_ERROR = 'ROLES_UPDATE_ROLE_DISMISS_ERROR';
export const ROLES_CREATE_ROLE_BEGIN = 'ROLES_CREATE_ROLE_BEGIN';
export const ROLES_CREATE_ROLE_SUCCESS = 'ROLES_CREATE_ROLE_SUCCESS';
export const ROLES_CREATE_ROLE_FAILURE = 'ROLES_CREATE_ROLE_FAILURE';
export const ROLES_CREATE_ROLE_DISMISS_ERROR = 'ROLES_CREATE_ROLE_DISMISS_ERROR';
