// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { RoleList } from './';

export default {
  path: 'admin',
  childRoutes: [
    { path: 'roles', component: RoleList },
  ],
};
