import React from 'react';
import Typography from '@material-ui/core/Typography';


export default function Copyright() {
  return (
    <div className="common-copyright">
      <Typography variant="p" component="p">
        Copyright © 2022 Blockchain FOB
      </Typography>
    </div>
  );
};
