import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  PARTS_CREATE_PART_BEGIN,
  PARTS_CREATE_PART_SUCCESS,
  PARTS_CREATE_PART_FAILURE,
  PARTS_CREATE_PART_DISMISS_ERROR,
} from './constants';
import ApiService from '../../common/services/ApiService';

export function createPart(data) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: PARTS_CREATE_PART_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      ApiService.createPart(data).then(
        (res) => {
          dispatch({
            type: PARTS_CREATE_PART_SUCCESS,
            data: res,
          });
          resolve({...res, message: 'Successfully created asset.'});
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: PARTS_CREATE_PART_FAILURE,
            data: {
              error: {
                message: err && err.response && err.response.data ? err.response.data.message : 'Unable to create asset.'
              }
            },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissCreatePartError() {
  return {
    type: PARTS_CREATE_PART_DISMISS_ERROR,
  };
}

export function useCreatePart(params) {
  const dispatch = useDispatch();

  const { createPartPending, createPartError } = useSelector(
    state => ({
      createPartPending: state.parts.createPartPending,
      createPartError: state.parts.createPartError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(createPart(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissCreatePartError());
  }, [dispatch]);

  return {
    createPart: boundAction,
    createPartPending,
    createPartError,
    dismissCreatePartError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case PARTS_CREATE_PART_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        createPartPending: true,
        createPartError: null,
      };

    case PARTS_CREATE_PART_SUCCESS:
      // The request is success
      return {
        ...state,
        createPartPending: false,
        createPartError: null,
        newParts: [action.data],
      };

    case PARTS_CREATE_PART_FAILURE:
      // The request is failed
      return {
        ...state,
        createPartPending: false,
        createPartError: action.data.error,
      };

    case PARTS_CREATE_PART_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        createPartError: null,
      };

    default:
      return state;
  }
}
