import React from 'react';
import {useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Toolbar,
    Typography,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LoadingButton from "../common/LoadingButton";
import {useToggleAddOwnerDialog} from "./redux/toggleAddOwnerDialog";
import OwnerDetailsForm from "./OwnerDetailsForm";


export default function AddOwnerDialog(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const {isAddOwnerDialogVisible, toggleAddOwnerDialog} = useToggleAddOwnerDialog();

    return (
        <Dialog
            fullScreen={fullScreen}
            open={isAddOwnerDialogVisible}
            className="equipments-add-owner-dialog fob-details-dialog">
            <form onSubmit={props.formik.handleSubmit}>
                <DialogTitle>
                    <Toolbar>
                        <Typography variant="h5" display="inline">Add Owner</Typography>
                        <span style={{flexGrow: 1}}></span>
                        <IconButton
                            edge="end"
                            color="inherit" aria-label="menu"
                            onClick={toggleAddOwnerDialog.bind(this, false)}>
                            <HighlightOffIcon/>
                        </IconButton>
                    </Toolbar>
                    <Divider/>
                </DialogTitle>

                <DialogContent>
                    <OwnerDetailsForm formik={props.formik} countries={props.countries} />
                </DialogContent>

                <DialogActions>
                    {
                        props.formik.isSubmitting
                            ? <LoadingButton/>
                            : <Button variant="contained"
                                      disableElevation
                                      color="secondary"
                                      type="submit"
                                      className="rounded-btn"
                            >
                                Save
                            </Button>
                    }

                    <Button variant="contained"
                            disableElevation
                            disabled={props.formik.isSubmitting}
                            onClick={() => {
                                toggleAddOwnerDialog(false, 'CANCELLED');
                            }}
                            className="rounded-btn dark-btn"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

