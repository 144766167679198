import manufacturerIcon from './../../../images/factory@2x.png';
import partsIcon from './../../../images/water-pump@2x.png';
import roleIcon from './../../../images/edit@2x.png';


export const ADMIN_CATEGORIES = [
    {
        name: 'admin-manufacturers',
        label: 'Add/Edit Manufacturers',
        imgSrc: manufacturerIcon,
        url: '/admin/manufacturers',
    },
    {
        name: 'admin-assets',
        label: 'Add/Edit Assets',
        imgSrc: partsIcon,
        url: '/admin/parts',
    },
    {
        name: 'admin-roles',
        label: 'Add/Edit Role',
        imgSrc: roleIcon,
        url: '/admin/roles',
    },
];