export const COMMON_TOGGLE_SIDENAV = 'COMMON_TOGGLE_SIDENAV';
export const COMMON_TOGGLE_QR_CODE_SCANNER_DIALOG = 'COMMON_TOGGLE_QR_CODE_SCANNER_DIALOG';
export const COMMON_GET_COUNTRIES_BEGIN = 'COMMON_GET_COUNTRIES_BEGIN';
export const COMMON_GET_COUNTRIES_SUCCESS = 'COMMON_GET_COUNTRIES_SUCCESS';
export const COMMON_GET_COUNTRIES_FAILURE = 'COMMON_GET_COUNTRIES_FAILURE';
export const COMMON_GET_COUNTRIES_DISMISS_ERROR = 'COMMON_GET_COUNTRIES_DISMISS_ERROR';
export const COMMON_GET_STATES_OF_COUNTRY_BEGIN = 'COMMON_GET_STATES_OF_COUNTRY_BEGIN';
export const COMMON_GET_STATES_OF_COUNTRY_SUCCESS = 'COMMON_GET_STATES_OF_COUNTRY_SUCCESS';
export const COMMON_GET_STATES_OF_COUNTRY_FAILURE = 'COMMON_GET_STATES_OF_COUNTRY_FAILURE';
export const COMMON_GET_STATES_OF_COUNTRY_DISMISS_ERROR = 'COMMON_GET_STATES_OF_COUNTRY_DISMISS_ERROR';
