import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  PARTS_UPDATE_PART_BEGIN,
  PARTS_UPDATE_PART_SUCCESS,
  PARTS_UPDATE_PART_FAILURE,
  PARTS_UPDATE_PART_DISMISS_ERROR,
} from './constants';
import ApiService from "../../common/services/ApiService";

export function updatePart(partId, description) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: PARTS_UPDATE_PART_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      ApiService.updatePart(partId, description).then(
        (res) => {
          dispatch({
            type: PARTS_UPDATE_PART_SUCCESS,
            data: res,
          });
          resolve({...res, message: 'Successfully updated asset.'});
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: PARTS_UPDATE_PART_FAILURE,
              data: {
                  error: {
                      message: err && err.response && err.response.data ? err.response.data.message : 'Unable to save asset.'
                  }
              },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissUpdatePartError() {
  return {
    type: PARTS_UPDATE_PART_DISMISS_ERROR,
  };
}

export function useUpdatePart(params) {
  const dispatch = useDispatch();

  const { updatePartPending, updatePartError } = useSelector(
    state => ({
      updatePartPending: state.parts.updatePartPending,
      updatePartError: state.parts.updatePartError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(updatePart(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissUpdatePartError());
  }, [dispatch]);

  return {
    updatePart: boundAction,
    updatePartPending,
    updatePartError,
    dismissUpdatePartError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case PARTS_UPDATE_PART_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        updatePartPending: true,
        updatePartError: null,
      };

    case PARTS_UPDATE_PART_SUCCESS:
      // The request is success
      return {
        ...state,
        updatePartPending: false,
        updatePartError: null,
      };

    case PARTS_UPDATE_PART_FAILURE:
      // The request is failed
      return {
        ...state,
        updatePartPending: false,
        updatePartError: action.data.error,
      };

    case PARTS_UPDATE_PART_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        updatePartError: null,
      };

    default:
      return state;
  }
}
