import React, { useContext } from 'react';
import { PageHeader } from '../common';
import {
  Card,
  Grid,
  ButtonBase,
  Paper,
  Button
} from '@material-ui/core';
import ManageRoleService from "../roles/services/ManageRoleService";
import AuthContext from "../authentication/AuthContext";


const AdminCategoryButton = (props) => {
  return (
    <ButtonBase
      href={props.url}
      focusRipple
      className="admin-category-btn"
    >
      <div>
        <Paper>
          <div>
            <img src={props.imgSrc} alt=""/>
          </div>
        </Paper>
        <Button variant="contained" color="secondary">{props.label}</Button>
      </div>
    </ButtonBase>
  );
};


export default function AdminOptionDashboard(props) {
  const user = useContext(AuthContext);

  if (!user) return null;

  const ADMIN_CATEGORIES = ManageRoleService.getAdminCategoriesByRole(user.roleId);

  return (
    <div className="admin-admin-option-dashboard">
      <PageHeader location={props.location} />

      <Card className="fob-content-body">
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={4}
        >
          {
            ADMIN_CATEGORIES.map(category => {
              return (
                <Grid item lg={3}>
                  <AdminCategoryButton {...category} />
                </Grid>
              );
            })
          }
        </Grid>
      </Card>
    </div>
  );
};

