import React, { useEffect } from 'react';
import { Divider, Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import { useGetStatesOfCountry } from '../common/redux/hooks';
import { findWhere } from 'underscore';
// import ApartmentIcon from '@material-ui/icons/Apartment';


export default function HomeAddressInfo(props) {
  const {getStatesOfCountry, states} = useGetStatesOfCountry();

  useEffect(() => {
    if (!props.formik.values.owner.address.country) return;

    console.log('country changed: ',props.formik.values.owner.address.country);

    let country = findWhere(props.countries, {name: props.formik.values.owner.address.country});

    if (!country) return;

    getStatesOfCountry(country.id);
  }, [props.formik.values.owner.address.country, getStatesOfCountry, props.countries]);

  return (
    <div className="profile-home-address-info my-profile-form-item-group">

      <Grid container
            direction="column"
      >
        <Grid container item className="my-profile-form-item-group-header">
          <HomeIcon />
          <Typography variant="h6" component="h6" color="primary">HOME ADDRESS INFO</Typography>
        </Grid>

        <Divider />

        <Grid container
              item
              direction="row"
              spacing={2}>

          <Grid container item lg={6}>
            <TextField
              id="owner.address.line1"
              label="Address Line 1"
              value={props.formik.values.owner.address.line1}
              onChange={props.formik.handleChange}
              fullWidth
              InputProps={{disableUnderline: true}}
              InputLabelProps={{
                shrink: true,
              }}
              className="fob-filled-input-with-outside-label"
            />
          </Grid>

          <Grid container item lg={6}>
            <TextField
              id="owner.address.line2"
              label="Address Line 2"
              value={props.formik.values.owner.address.line2}
              onChange={props.formik.handleChange}
              fullWidth
              InputProps={{disableUnderline: true}}
              InputLabelProps={{
                shrink: true,
              }}
              className="fob-filled-input-with-outside-label"
            />
          </Grid>

          <Grid container
                item
                direction="row"
                spacing={2}>
            <Grid container item lg={6}>
              <TextField
                id="owner.address.city"
                label="City"
                value={props.formik.values.owner.address.city}
                onChange={props.formik.handleChange}
                fullWidth
                InputProps={{disableUnderline: true}}
                InputLabelProps={{
                  shrink: true,
                }}
                className="fob-filled-input-with-outside-label"
              />
            </Grid>

            <Grid container item lg={6}>
              <TextField
                id="owner.address.state"
                name="owner.address.state"
                select
                label="State"
                value={props.formik.values.owner.address.state}
                onChange={props.formik.handleChange}
                fullWidth
                InputProps={{disableUnderline: true}}
                InputLabelProps={{
                  shrink: true,
                }}
                className="fob-filled-input-with-outside-label"
              >
                {states.map(item => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <Grid container
                item
                direction="row"
                spacing={2}>
            <Grid container item lg={6}>
              <TextField
                id="owner.address.zipCode"
                label="Zip"
                value={props.formik.values.owner.address.zipCode}
                onChange={props.formik.handleChange}
                fullWidth
                InputProps={{disableUnderline: true}}
                InputLabelProps={{
                  shrink: true,
                }}
                className="fob-filled-input-with-outside-label"
              />
            </Grid>

            <Grid container item lg={6}>
              <TextField
                id="owner.address.country"
                name="owner.address.country"
                select
                label="Country"
                value={props.formik.values.owner.address.country}
                onChange={props.formik.handleChange}
                fullWidth
                InputProps={{disableUnderline: true}}
                InputLabelProps={{
                  shrink: true,
                }}
                className="fob-filled-input-with-outside-label"
              >
                {props.countries.map(item => (
                  <MenuItem key={item.name} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
