import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  EQUIPMENTS_SEARCH_EQUIPMENT_FROM_QR_CODE_BEGIN,
  EQUIPMENTS_SEARCH_EQUIPMENT_FROM_QR_CODE_SUCCESS,
  EQUIPMENTS_SEARCH_EQUIPMENT_FROM_QR_CODE_FAILURE,
  EQUIPMENTS_SEARCH_EQUIPMENT_FROM_QR_CODE_DISMISS_ERROR
} from './constants';
import ApiService from "../../common/services/ApiService";
import AuthService from "../../authentication/services/AuthService";
import {isEmpty} from 'underscore';

export function searchEquipmentFromQRCode(filters, orderColumn, urlParam) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: EQUIPMENTS_SEARCH_EQUIPMENT_FROM_QR_CODE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
        let user = AuthService.getAuthUser();
        let ownerId = isEmpty(user.owner)
                      ? null
                      : user.owner.ownerId;

        ApiService.searchAssetFromQRCode(filters, user.userId, ownerId, orderColumn, urlParam).then(
        (res) => {
          dispatch({
            type: EQUIPMENTS_SEARCH_EQUIPMENT_FROM_QR_CODE_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: EQUIPMENTS_SEARCH_EQUIPMENT_FROM_QR_CODE_FAILURE,
            data: { error: err },
          });
          // reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissSearchEquipmentFromQRCodeError() {
  return {
    type: EQUIPMENTS_SEARCH_EQUIPMENT_FROM_QR_CODE_DISMISS_ERROR,
  };
}

export function useSearchEquipmentFromQRCode(params) {
  const dispatch = useDispatch();

  const { searchResultFromQRCode, searchEquipmentFromQRCodePending, searchEquipmentFromQRCodeError } = useSelector(
    state => ({
      searchResultFromQRCode: state.equipments.searchResultFromQRCode,
      searchEquipmentFromQRCodePending: state.equipments.searchEquipmentFromQRCodePending,
      searchEquipmentFromQRCodeError: state.equipments.searchEquipmentFromQRCodeError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(searchEquipmentFromQRCode(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissSearchEquipmentFromQRCodeError());
  }, [dispatch]);

  return {
    searchResultFromQRCode,
    searchEquipmentFromQRCode: boundAction,
    searchEquipmentFromQRCodePending,
    searchEquipmentFromQRCodeError,
    dismissSearchEquipmentFromQRCodeError: boundDismissError
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case EQUIPMENTS_SEARCH_EQUIPMENT_FROM_QR_CODE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        searchEquipmentFromQRCodePending: true,
        searchEquipmentFromQRCodeError: null,
      };

    case EQUIPMENTS_SEARCH_EQUIPMENT_FROM_QR_CODE_SUCCESS:
      // The request is success
      return {
        ...state,
        searchEquipmentFromQRCodePending: false,
        searchEquipmentFromQRCodeError: null,
        searchResultFromQRCode: action.data,
      };

    case EQUIPMENTS_SEARCH_EQUIPMENT_FROM_QR_CODE_FAILURE:
      // The request is failed
      return {
        ...state,
        searchEquipmentFromQRCodePending: false,
        searchEquipmentFromQRCodeError: action.data.error,
      };

    case EQUIPMENTS_SEARCH_EQUIPMENT_FROM_QR_CODE_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        searchEquipmentFromQRCodeError: null,
      };

    default:
      return state;
  }
}
