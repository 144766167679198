import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  PROFILE_GET_PROFILE_BEGIN,
  PROFILE_GET_PROFILE_SUCCESS,
  PROFILE_GET_PROFILE_FAILURE,
  PROFILE_GET_PROFILE_DISMISS_ERROR,
} from './constants';
import ApiService from '../../common/services/ApiService';

export function getProfile(accessToken = null) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: PROFILE_GET_PROFILE_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      ApiService.getUserProfile(accessToken).then(
        (res) => {
          dispatch({
            type: PROFILE_GET_PROFILE_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: PROFILE_GET_PROFILE_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissGetProfileError() {
  return {
    type: PROFILE_GET_PROFILE_DISMISS_ERROR,
  };
}

export function useGetProfile(params) {
  const dispatch = useDispatch();

  const { getProfilePending, getProfileError, userProfile } = useSelector(
    state => ({
      userProfile: state.profile.userProfile,
      getProfilePending: state.profile.getProfilePending,
      getProfileError: state.profile.getProfileError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(getProfile(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetProfileError());
  }, [dispatch]);

  return {
    userProfile: userProfile,
    getProfile: boundAction,
    getProfilePending,
    getProfileError,
    dismissGetProfileError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case PROFILE_GET_PROFILE_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getProfilePending: true,
        getProfileError: null,
      };

    case PROFILE_GET_PROFILE_SUCCESS:
      // The request is success
      return {
        ...state,
        getProfilePending: false,
        getProfileError: null,
        userProfile: action.data,
      };

    case PROFILE_GET_PROFILE_FAILURE:
      // The request is failed
      return {
        ...state,
        getProfilePending: false,
        getProfileError: action.data.error,
      };

    case PROFILE_GET_PROFILE_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getProfileError: null,
      };

    default:
      return state;
  }
}
