import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const BASE_COLOR = {
  primary: '#3f3833',
  secondary: '#222A3E'
};

const FONT_COLOR = {
  primary: '#222A3E',
  secondary: '#3f3833'
};

const _theme = createMuiTheme({
  palette: {
    primary: {
      main: BASE_COLOR.primary
    },
    // secondary: BASE_COLOR.secondary,
    text: {
      primary: FONT_COLOR.primary,
      secondary: FONT_COLOR.secondary,
      // disabled: styles.ttt,
      // hint: styles.tttt,
    }
  },
  status: {
    danger: 'orange',
  },
});

const theme = responsiveFontSizes(_theme);

export default theme;
