import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider, Grid,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useToggleServiceAgreementDialog, useUpdateServiceAgreement } from './redux/hooks';
import { useFormik } from 'formik';
import { clone, isEmpty, isObject } from 'underscore';
import * as Yup from 'yup';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { KeyboardDatePicker } from '@material-ui/pickers';
import {CONFIG} from './../../config';
import StringHelper from "../common/services/StringHelper";
import moment from 'moment';
import Swal from "sweetalert2";
import Alert from '@material-ui/lab/Alert';

const ServiceAgreementContent = (props) => {
  const handleDateChange = (date, type) => {
    props.formik.setFieldValue(type, date);
  };

  return (
    <Grid container
          direction="row"
          justify="center"
          alignItems="center">

      <Grid item xs={12} md={5}>
        <table>
          <tbody>
          <tr>
            <td>
              <Typography variant="p" display="block" color="secondary">{props.user.email}</Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Typography variant="p" display="block" color="secondary">{props.user.owner && props.user.owner.company}</Typography>
            </td>
          </tr>
          </tbody>
        </table>
      </Grid>

      <Grid item xs={12} md={7}>
          <table>
            <tbody>
            <tr><td>Manufacturer</td><td>{props.equipmentDetails.manufacturer.description}</td></tr>
            <tr><td>Serial Number</td><td>{props.equipmentDetails.serialNumber}</td></tr>
            <tr><td>Description</td><td>{props.serviceAgreement.description}</td></tr>
            <tr><td>Contact</td><td>{props.serviceAgreement.contact}</td></tr>
            <tr>
                <td>Date of Install</td>
                <td>{StringHelper.formatDisplayDate(props.equipmentDetails.dateOfInstall)}</td>
            </tr>
            <tr><td>Location</td><td>{StringHelper.formatAddress(props.serviceAgreement.address)}</td></tr>

            <tr>
              <td>Start Date</td>
              <td>
                <KeyboardDatePicker
                  autoOk={true}
                  variant="inline"
                  inputVariant="filled"
                  format={CONFIG.DATE_DISPLAY_FORMAT}
                  margin="normal"
                  id="startDate"
                  label={null}
                  className="rounded-input fob-date-picker"
                  value={props.formik.values.startDate}
                  onChange={(date) => {
                    handleDateChange(date, 'startDate');
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>End Date</td>
              <td>
                <KeyboardDatePicker
                  autoOk={true}
                  variant="inline"
                  inputVariant="filled"
                  format={CONFIG.DATE_DISPLAY_FORMAT}
                  margin="normal"
                  id="endDate"
                  label={null}
                  className="rounded-input fob-date-picker"
                  value={props.formik.values.endDate}
                  onChange={(date) => {
                    handleDateChange(date, 'endDate');
                  }}
                />
              </td>
            </tr>

            </tbody>
          </table>
      </Grid>
    </Grid>
  )
};

const validationSchema = Yup.object({
  startDate: Yup.string().required('Required'),
  endDate: Yup.string().required('Required'),
});

export default function ServiceAgreementDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {toggleServiceAgreementDialog, serviceAgreement, isServiceAgreementDialogVisible} = useToggleServiceAgreementDialog();
  const {updateServiceAgreement, updateServiceAgreementError} = useUpdateServiceAgreement();

  const formik = useFormik({
    initialValues: {
      startDate: !serviceAgreement || isEmpty(serviceAgreement.startDate) ? '' : moment(serviceAgreement.startDate).parseZone(),
      endDate: !serviceAgreement || isEmpty(serviceAgreement.endDate) ? '' : moment(serviceAgreement.endDate).parseZone(),
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: values => {
      let data = clone(values);
      data.startDate = isObject(values.startDate) ? values.startDate.toISOString(true) : moment(values.startDate).toISOString(true);
      data.endDate = isObject(values.endDate) ? values.endDate.toISOString(true) : moment(values.endDate).toISOString(true);
      console.log('onSubmit: ', serviceAgreement.serviceAgreementId, data);
      updateServiceAgreement(serviceAgreement.serviceAgreementId, data)
        .then(res => {
          formik.resetForm();
          Swal.fire({
            icon: 'success',
            text: res.message,
            timer: 2000
          }).then(() => {
            toggleServiceAgreementDialog(false);
          });
        });
    },
  });

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isServiceAgreementDialogVisible}
      className="equipments-service-agreement-dialog fob-details-dialog"
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          <Toolbar>
            <Typography variant="h5" display="inline">Service Agreement Information</Typography>
            <span style={{flexGrow: 1}}></span>
            <IconButton
              edge="end"
              color="inherit" aria-label="menu"
              onClick={toggleServiceAgreementDialog.bind(this, false)}>
              <HighlightOffIcon />
            </IconButton>
          </Toolbar>

          <Divider />

        </DialogTitle>

        <DialogContent>
          <Grid item md={12}>
            {
              isEmpty(updateServiceAgreementError)
                ? null
                :
                <Alert elevation={0} variant="standard" severity="error">
                  {updateServiceAgreementError.message}
                </Alert>
            }
          </Grid>

            {
              isEmpty(serviceAgreement)
              ? null
              : <ServiceAgreementContent
                      equipmentDetails={props.equipmentDetails}
                      formik={formik}
                      user={props.user}
                      serviceAgreement={serviceAgreement}
                  />
            }

        </DialogContent>

        <DialogActions>
          <Button variant="contained"
                  disableElevation
                  color="secondary"
                  type="submit"
                  className="rounded-btn"
          >
            Save
          </Button>

          <Button variant="contained"
                  disableElevation
                  onClick={toggleServiceAgreementDialog.bind(this, false)}
                  className="rounded-btn dark-btn"
          >
            Cancel
          </Button>
        </DialogActions>
      </form>

    </Dialog>
  );
};

