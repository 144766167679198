import React from 'react';
import { Divider, Grid, TextField, Typography, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// import AuthTextField from '../authentication/AuthTextField';
import { SALUTATIONS } from './redux/constants';


export default function MyProfileInfo(props) {
  return (
    <div className="profile-my-profile-info my-profile-form-item-group">

      <Grid container
            direction="column"
      >
        <Grid container item className="my-profile-form-item-group-header">
          <AccountCircleIcon />
          <Typography variant="h6" component="h6" color="primary">PROFILE INFO</Typography>
        </Grid>

        <Divider />

        <Grid container
              item
              direction="row"
              spacing={2}>
          <Grid container item lg={6}>
            <TextField
              id="firstName"
              label="First Name"
              value={props.formik.values.firstName}
              onChange={props.formik.handleChange}
              fullWidth
              InputProps={{disableUnderline: true}}
              InputLabelProps={{
                shrink: true,
              }}
              className="fob-filled-input-with-outside-label"
            />
          </Grid>

          <Grid container item lg={6}>
            <TextField
              id="lastName"
              label="Last Name"
              value={props.formik.values.lastName}
              onChange={props.formik.handleChange}
              fullWidth
              InputProps={{disableUnderline: true}}
              InputLabelProps={{
                shrink: true,
              }}
              className="fob-filled-input-with-outside-label"
            />
          </Grid>

          <Grid container
                item
                direction="row"
                spacing={2}>
            <Grid container item lg={6}>
              <TextField
                id="password"
                label="Password"
                type="password"
                value={props.formik.values.password}
                onChange={props.formik.handleChange}
                fullWidth
                InputProps={{disableUnderline: true}}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={props.formik.errors.password ? props.formik.errors.password : ''}
                error={Boolean(props.formik.errors.password)}
                className="fob-filled-input-with-outside-label"
              />
            </Grid>

            <Grid container item lg={6}>
              <TextField
                id="confirmPassword"
                label="Confirm Password"
                type="password"
                value={props.formik.values.confirmPassword}
                onChange={props.formik.handleChange}
                fullWidth
                InputProps={{disableUnderline: true}}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={props.formik.errors.confirmPassword ? props.formik.errors.confirmPassword : ''}
                error={Boolean(props.formik.errors.confirmPassword)}
                className="fob-filled-input-with-outside-label"
              />
            </Grid>
          </Grid>

          <Grid container
                item
                direction="row"
                spacing={2}>
            <Grid container item lg={6}>
              <TextField
                id="email"
                label="Email"
                type="email"
                value={props.formik.values.email}
                disabled
                fullWidth
                InputProps={{disableUnderline: true}}
                InputLabelProps={{
                  shrink: true,
                }}
                className="fob-filled-input-with-outside-label"
              />
            </Grid>

            <Grid container item lg={6}>
              <TextField
                id="phoneNumber"
                label="Phone Number"
                type="phone"
                value={props.formik.values.phoneNumber}
                onChange={props.formik.handleChange}
                fullWidth
                InputProps={{disableUnderline: true}}
                InputLabelProps={{
                  shrink: true,
                }}
                className="fob-filled-input-with-outside-label"
              />
            </Grid>
          </Grid>


          <Grid container item lg={12}>
                {/* <FormControl
                    fullWidth
                    className="fob-filled-input-with-outside-label">
                    <InputLabel shrink id="title-label">Title</InputLabel>
                    <Select
                        id="title"
                        name="title"
                        className="fob-filled-input-with-outside-label"
                        placeholder="Select Title"
                        color="secondary"
                        value={props.formik.values.title}
                        onChange={props.formik.handleChange}
                    >
                      <MenuItem value={''}></MenuItem>
                      {
                        SALUTATIONS.map(value => <MenuItem value={value}>{value}</MenuItem>)
                      }
                    </Select>
                </FormControl> */}

                <TextField
                  id="title"
                  label="Title"
                  value={props.formik.values.title}
                  onChange={props.formik.handleChange}
                  fullWidth
                  InputProps={{disableUnderline: true}}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="fob-filled-input-with-outside-label"
                />
          </Grid>

        </Grid>
      </Grid>
    </div>
  );
};

