import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  EQUIPMENTS_CREATE_WARRANTY_BEGIN,
  EQUIPMENTS_CREATE_WARRANTY_SUCCESS,
  EQUIPMENTS_CREATE_WARRANTY_FAILURE,
  EQUIPMENTS_CREATE_WARRANTY_DISMISS_ERROR,
} from './constants';
import ApiService from '../../common/services/ApiService';

export function createWarranty(data) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: EQUIPMENTS_CREATE_WARRANTY_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      ApiService.createWarranty(data).then(
        (res) => {
          let resolveData = {
            data: res,
            message: 'Warranty Created.',
          };
          dispatch({
            type: EQUIPMENTS_CREATE_WARRANTY_SUCCESS,
            ...resolveData,
          });
          resolve(resolveData);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: EQUIPMENTS_CREATE_WARRANTY_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissCreateWarrantyError() {
  return {
    type: EQUIPMENTS_CREATE_WARRANTY_DISMISS_ERROR,
  };
}

export function useCreateWarranty(params) {
  const dispatch = useDispatch();

  const { createdWarranty, createWarrantyPending, createWarrantyError } = useSelector(
    state => ({
      createdWarranty: state.equipments.createdWarranty,
      createWarrantyPending: state.equipments.createWarrantyPending,
      createWarrantyError: state.equipments.createWarrantyError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(createWarranty(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissCreateWarrantyError());
  }, [dispatch]);

  return {
    createdWarranty,
    createWarranty: boundAction,
    createWarrantyPending,
    createWarrantyError,
    dismissCreateWarrantyError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case EQUIPMENTS_CREATE_WARRANTY_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        createWarrantyPending: true,
        createWarrantyError: null,
      };

    case EQUIPMENTS_CREATE_WARRANTY_SUCCESS:
      // The request is success
      return {
        ...state,
        createWarrantyPending: false,
        createWarrantyError: null,
        createWarrantySuccessMessage:  action.createWarrantySuccessMessage,
      };

    case EQUIPMENTS_CREATE_WARRANTY_FAILURE:
      // The request is failed
      return {
        ...state,
        createWarrantyPending: false,
        createWarrantyError: action.data.error,
      };

    case EQUIPMENTS_CREATE_WARRANTY_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        createWarrantyError: null,
      };

    default:
      return state;
  }
}
