// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { Search } from './';
import { EquipmentDetails } from '../equipments';

export default {
  path: 'search',
  childRoutes: [
    { path: '', component: Search },
    { path: 'equipment/:id', component: EquipmentDetails },
    { path: 'lat/:latId', component: Search },
  ],
};
