import {useEffect, useCallback, useContext} from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  MANUFACTURERS_GET_MANUFACTURERS_BEGIN,
  MANUFACTURERS_GET_MANUFACTURERS_SUCCESS,
  MANUFACTURERS_GET_MANUFACTURERS_FAILURE,
  MANUFACTURERS_GET_MANUFACTURERS_DISMISS_ERROR,
} from './constants';
import ApiService from '../../common/services/ApiService';
import AuthContext from "../../authentication/AuthContext";
import ManageRoleService from "../../roles/services/ManageRoleService";


export function getManufacturers(page= 1, limit= 5, order= 'ASC', manufacturerId=null) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: MANUFACTURERS_GET_MANUFACTURERS_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      ApiService.getManufacturers(page, limit, order, manufacturerId).then(
        (res) => {
          dispatch({
            type: MANUFACTURERS_GET_MANUFACTURERS_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: MANUFACTURERS_GET_MANUFACTURERS_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissGetManufacturersError() {
  return {
    type: MANUFACTURERS_GET_MANUFACTURERS_DISMISS_ERROR,
  };
}

export function useGetManufacturers(params) {
  const dispatch = useDispatch();
  const user = useContext(AuthContext);

  const { getManufacturersPending, getManufacturersError, manufacturersData } = useSelector(
    state => ({
      getManufacturersPending: state.manufacturers.getManufacturersPending,
      getManufacturersError: state.manufacturers.getManufacturersError,
      manufacturersData: state.manufacturers.manufacturersData,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((page= 1, limit= 5, order= 'ASC', manufacturerId=null) => {
    const isManufacturer = ManageRoleService.isManufacturer(user.roleId);
    const _manufacturerId = manufacturerId ? manufacturerId : (isManufacturer ? user.manufacturerId : null);
    return dispatch(getManufacturers(page, limit, order, _manufacturerId));
  }, [dispatch, user]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetManufacturersError());
  }, [dispatch]);

  return {
    manufacturersData,
    getManufacturers: boundAction,
    getManufacturersPending,
    getManufacturersError,
    dismissGetManufacturersError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MANUFACTURERS_GET_MANUFACTURERS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getManufacturersPending: true,
        getManufacturersError: null,
      };

    case MANUFACTURERS_GET_MANUFACTURERS_SUCCESS:
      // The request is success
      return {
        ...state,
        getManufacturersPending: false,
        getManufacturersError: null,
        manufacturersData: action.data,
      };

    case MANUFACTURERS_GET_MANUFACTURERS_FAILURE:
      // The request is failed
      return {
        ...state,
        getManufacturersPending: false,
        getManufacturersError: action.data.error,
      };

    case MANUFACTURERS_GET_MANUFACTURERS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getManufacturersError: null,
      };

    default:
      return state;
  }
}
