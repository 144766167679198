import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  EQUIPMENTS_SAVE_OWNER_BEGIN,
  EQUIPMENTS_SAVE_OWNER_SUCCESS,
  EQUIPMENTS_SAVE_OWNER_FAILURE,
  EQUIPMENTS_SAVE_OWNER_DISMISS_ERROR,
} from './constants';
import ApiService from "../../common/services/ApiService";

export function saveOwner(data) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: EQUIPMENTS_SAVE_OWNER_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      ApiService.createOwner(data).then(
        (res) => {
          dispatch({
            type: EQUIPMENTS_SAVE_OWNER_SUCCESS,
            data: res,
          });
          resolve({...res, message: 'Successfully created owner.'});
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: EQUIPMENTS_SAVE_OWNER_FAILURE,
            data: {
                error: {
                    message: err && err.response && err.response.data ? err.response.data.message : 'Unable to create owner.'
                }
            },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissSaveOwnerError() {
  return {
    type: EQUIPMENTS_SAVE_OWNER_DISMISS_ERROR,
  };
}

export function useSaveOwner(params) {
  const dispatch = useDispatch();

  const { newOwners, saveOwnerPending, saveOwnerError } = useSelector(
    state => ({
      newOwners: state.equipments.newOwners,
      saveOwnerPending: state.equipments.saveOwnerPending,
      saveOwnerError: state.equipments.saveOwnerError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(saveOwner(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissSaveOwnerError());
  }, [dispatch]);

  return {
    newOwners,
    saveOwner: boundAction,
    saveOwnerPending,
    saveOwnerError,
    dismissSaveOwnerError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case EQUIPMENTS_SAVE_OWNER_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        saveOwnerPending: true,
        saveOwnerError: null,
      };

    case EQUIPMENTS_SAVE_OWNER_SUCCESS:
      // The request is success
      return {
        ...state,
        saveOwnerPending: false,
        saveOwnerError: null,
        newOwners: [action.data],
      };

    case EQUIPMENTS_SAVE_OWNER_FAILURE:
      // The request is failed
      return {
        ...state,
        saveOwnerPending: false,
        saveOwnerError: action.data.error,
      };

    case EQUIPMENTS_SAVE_OWNER_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        saveOwnerError: null,
      };

    default:
      return state;
  }
}
