import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import { useToggleAdminCommonDialog } from '../admin/redux/toggleAdminCommonDialog';
// import { useUpdateRole } from './redux/updateRole';
import { useFormik } from 'formik';
import Swal from "sweetalert2";
import {
  Button,
  Container,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  Divider, Grid,
  IconButton, TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LoadingButton from '../common/LoadingButton';
import * as Yup from 'yup';
import { useCreateRole } from './redux/hooks';
import { isEmpty } from 'underscore';
import Alert from '@material-ui/lab/Alert';


export default function CreateRoleDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDialog = useSelector(state => state.admin.toggleDialog);
  const {toggleAdminCommonDialog} = useToggleAdminCommonDialog();
  const {createRole, createRolePending, createRoleError} = useCreateRole();

  const validationSchema = Yup.object({
    description: Yup.string().required('Required'),
  });

 const formik = useFormik({
    initialValues: {
      description: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: values => {
      createRole(values)
        .then(res => {
          formik.resetForm();
          Swal.fire({
            icon: 'success',
            text: res.message,
            timer: 2000
          }).then(() => {
            toggleAdminCommonDialog('CreateRoleDialog', false, {});
          });
        });
    },
  });

  return (
    <Dialog
      fullScreen={fullScreen}
      open={toggleDialog.name === 'CreateRoleDialog' && toggleDialog.flag === true}
      className="roles-create-role-dialog roles-role-details-dialog fob-details-dialog">

      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          <Toolbar>
            <Typography variant="h5" display="inline">Role</Typography>
            <span style={{flexGrow: 1}}></span>
            <IconButton
              edge="end"
              color="inherit" aria-label="menu"
              onClick={toggleAdminCommonDialog.bind(this, 'CreateRoleDialog', false, {})}>
              <HighlightOffIcon />
            </IconButton>
          </Toolbar>
          <Divider />
        </DialogTitle>

        <DialogContent>

          <Container maxWidth="sm">
            <Grid item md={12}>
              {
                isEmpty(createRoleError)
                  ? null
                  :
                  <Alert elevation={0} variant="standard" severity="error">
                    {createRoleError.message}
                  </Alert>
              }
            </Grid>

            <Grid container
                  lg={12}
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={2}>
              <Grid item lg={12}>
                <TextField
                  id="description"
                  label="Role Description"
                  InputProps={{disableUnderline: true}}
                  InputLabelProps={{ shrink: true }}
                  className="fob-filled-input-with-outside-label"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Container>
        </DialogContent>

        <DialogActions>
          {
            createRolePending === true
              ? <LoadingButton/>
              : <Button variant="contained"
                        disableElevation
                        color="secondary"
                        type="submit"
                        className="rounded-btn"
              >
                Save
              </Button>
          }


          <Button variant="contained"
                  disableElevation
                  onClick={() => {
                    formik.resetForm();
                    toggleAdminCommonDialog('CreateRoleDialog', false, {});
                  }}
                  className="rounded-btn dark-btn"
                  disabled={createRolePending === true}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
