import React, {useEffect, useState} from 'react';
import {
  Card, CardContent, CardMedia,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ReactPlayer from 'react-player'
import {useToggleActivityImageDialog} from "./redux/toggleActivityImageDialog";
import * as mimeTypes from "mime-types";
import ReactCardCarousel from "react-card-carousel";
import {findIndex} from 'underscore';


function ActivityMedia(props) {
  const mimeType = mimeTypes.lookup(props.src);

  const MediaComponent = mimeType.includes('video')
               ? <ReactPlayer url={props.src} controls={true} light={true} />
               : <img alt="" src={props.src} />

  return (
      <Card className="activity-media-container">
        <CardContent>
          {MediaComponent}
        </CardContent>
      </Card>
  );
}

export default function ActivityImageDialog() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {toggleActivityImageDialog, isActivityImageDialogVisible, activityImage, activityFiles} = useToggleActivityImageDialog();
  const [initialIndex, setInitialIndex] = useState(0);

  useEffect(() => {
      if (activityImage && activityFiles.length) {
        const foundIndex = findIndex(activityFiles, (f) => {
          return f == activityImage
        });
        if (foundIndex > -1) {
          setInitialIndex(foundIndex);
        }
      }
  }, [activityImage, activityFiles]);

  if (!activityImage) return null;

  return (
    <Dialog
      fullScreen={fullScreen}
      open={isActivityImageDialogVisible}
      className="activity-image-dialog fob-details-dialog fob-form-dialog">
        <DialogTitle>
          <Toolbar>
            <Typography variant="h5" display="inline">Activity Media</Typography>
            <span style={{flexGrow: 1}}></span>
            <IconButton
              edge="end"
              color="inherit" aria-label="menu"
              onClick={toggleActivityImageDialog.bind(this, false, null)}
            >
              <HighlightOffIcon />
            </IconButton>
          </Toolbar>
          <Divider />
        </DialogTitle>

        <DialogContent>
          <ReactCardCarousel autoplay={false} initial_index={initialIndex} spread="medium">
            {
              activityFiles.map(fileSrc => <ActivityMedia src={fileSrc} />)
            }
          </ReactCardCarousel>
        </DialogContent>
    </Dialog>
  );
};

