import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  EQUIPMENTS_TOGGLE_SERVICE_AGREEMENT_DIALOG,
} from './constants';
import { isNull } from 'underscore';

export function toggleServiceAgreementDialog(flag=null, serviceAgreement=null) {
  return {
    type: EQUIPMENTS_TOGGLE_SERVICE_AGREEMENT_DIALOG,
    flag: flag,
    serviceAgreement: serviceAgreement,
  };
}

export function useToggleServiceAgreementDialog() {
  const dispatch = useDispatch();
  const isServiceAgreementDialogVisible = useSelector(state => state.equipments.isServiceAgreementDialogVisible);
  const serviceAgreement = useSelector(state => state.equipments.serviceAgreement);
  const boundAction = useCallback((...params) => dispatch(toggleServiceAgreementDialog(...params)), [dispatch]);
  return { serviceAgreement, isServiceAgreementDialogVisible, toggleServiceAgreementDialog: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case EQUIPMENTS_TOGGLE_SERVICE_AGREEMENT_DIALOG:
      return {
        ...state,
        isServiceAgreementDialogVisible: isNull(action.flag) ? !state.isServiceAgreementDialogVisible : action.flag,
        serviceAgreement: action.serviceAgreement,
      };

    default:
      return state;
  }
}
