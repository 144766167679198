import React, { useEffect, useContext } from 'react';
import { PageHeader } from '../common';
import {
    ActivityDetailsDialog,
    EquipmentActivitiesTable,
    ServiceAgreementDialog,
    WarrantyDetailsDialog,
    ServiceAgreementListDialog,
    AddWarrantyDialog, AddOwnerDialog
} from './index';
import { useSelector } from 'react-redux';
import { useGetEquipmentDetails, useSaveActivity } from './redux/hooks';
import {isEmpty, isNull} from 'underscore';
import AuthContext from '../authentication/AuthContext';
import MessagePrompt from '../common/MessagePrompt';
import { useGetManufacturers } from '../manufacturers/redux/getManufacturers';
import { useGetCountries } from '../common/redux/getCountries';
import AddServiceAgreementDialog from './AddServiceAgreementDialog';
import {useUpdateServiceAgreement} from "./redux/updateServiceAgreement";
import {useFormik} from "formik";
import Swal from "sweetalert2";
import {useGetOwners} from "./redux/getOwners";
import {useToggleAddOwnerDialog} from "./redux/toggleAddOwnerDialog";
import {useSaveOwner} from "./redux/saveOwner";
import {ManufacturersInformationDialog} from "../manufacturers/index";
import {useGetAssetManufacturerInfo} from "./redux/getAssetManufacturerInfo";


export default function EquipmentDetails(props) {
  const {getEquipmentDetails} = useGetEquipmentDetails();
  const equipmentDetails = useSelector(state => state.equipments.equipmentDetails);
  const updateWarrantyMessage = useSelector(state => state.equipments.updateWarrantyMessage);
  const {getManufacturers, manufacturersData} = useGetManufacturers();
  const {getCountries, countries} = useGetCountries();
  const {updateServiceAgreementResult} = useUpdateServiceAgreement();
  const {saveActivityPending} = useSaveActivity();
  const {saveOwner} = useSaveOwner();
  const {toggleAddOwnerDialog} = useToggleAddOwnerDialog();
  const {getOwners, ownersData} = useGetOwners();
  const {getAssetManufacturerInfo, assetManufacturerInfo} = useGetAssetManufacturerInfo();
  const user = useContext(AuthContext);
  let equipmentId;

  const formikAddOwner = useFormik({
      initialValues: {
          company: '',
          address: {
              line1: '',
              line2: '',
              city: '',
              state: '',
              zipCode: '',
              country: 'United States',
              hasAddress: false,
          },
      },
      onSubmit: (values, {resetForm, setSubmitting}) => {
          saveOwner(values)
              .then(res => {
                  resetForm();
                  getOwners(1, 1000, 'ASC');
                  Swal.fire({
                      icon: 'success',
                      text: res.message,
                      timer: 2000
                  }).then(() => {
                      toggleAddOwnerDialog(false);
                  });
              }).finally(() => {
                 setSubmitting(false);
              });
      }
  });

  // redirect here if no id
  if (!isEmpty(props.match.params) && props.match.params.id) {
    equipmentId = props.match.params.id;
  } else {
    props.history.replace('/');
  }

  useEffect(() => {
    if (!equipmentId) return;
    if(saveActivityPending) return;

    getEquipmentDetails(equipmentId);

  }, [getEquipmentDetails, equipmentId, updateServiceAgreementResult, saveActivityPending]);

  useEffect(() => {
    getManufacturers(1, 1000, 'ASC');
  }, [getManufacturers]);

  useEffect(() => {
      getOwners(1, 1000);
  }, [getOwners]);

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  useEffect(() => {
      if (equipmentDetails && equipmentDetails.manufacturerInfo === true) {
          getAssetManufacturerInfo(equipmentDetails.assetId);
      }
  }, [equipmentDetails, getAssetManufacturerInfo]);

  return (
    <div className="equipments-equipment-details">
      <PageHeader location={props.location} match={props.match}/>

      {
        updateWarrantyMessage
          ? <MessagePrompt message={updateWarrantyMessage}
                           severity="success"
                           autoHideDuration={4000}
          />
          : null
      }

      <EquipmentActivitiesTable equipmentId={props.match.params.id}
                                equipmentDetails={equipmentDetails}
                                history={props.history}
                                user={user}
                                countries={countries}
                                ownersData={ownersData} />

      <WarrantyDetailsDialog equipmentDetails={equipmentDetails} user={user}/>

      <ServiceAgreementDialog equipmentDetails={equipmentDetails} user={user} />
      <ServiceAgreementListDialog equipmentDetails={equipmentDetails} user={user}/>
      {
        !isEmpty(equipmentDetails)
        ? <AddServiceAgreementDialog user={user}
                                   equipmentDetails={equipmentDetails}
                                   manufacturersData={manufacturersData}
                                   countries={countries} />
        : null
      }

      { (!isEmpty(equipmentDetails) && equipmentDetails.assetId) &&
        <AddWarrantyDialog assetId={equipmentDetails.assetId} />
      }

      <ActivityDetailsDialog  user={user}
                              ownersData={ownersData}
                              countries={countries} />
      <AddOwnerDialog formik={formikAddOwner} equipmentDetails={equipmentDetails} countries={countries} />

      {
          (equipmentDetails && equipmentDetails.manufacturerInfo === true && !isEmpty(assetManufacturerInfo)) && <ManufacturersInformationDialog assetManufacturerInfo={assetManufacturerInfo} />
      }
    </div>
  );
};

