import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  PARTS_GET_PART_DETAILS_BEGIN,
  PARTS_GET_PART_DETAILS_SUCCESS,
  PARTS_GET_PART_DETAILS_FAILURE,
  PARTS_GET_PART_DETAILS_DISMISS_ERROR,
} from './constants';
// import AuthService from '../../authentication/services/AuthService';

export function getPartDetails(params = {}) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: PARTS_GET_PART_DETAILS_BEGIN,
    });

    // let accesstoken = AuthService._getToken();

    const promise = new Promise((resolve, reject) => {
          dispatch({
            type: PARTS_GET_PART_DETAILS_SUCCESS,
            data: params.partDetails,
          });
          resolve(params.partDetails);
      // ApiService.getPartDetails(params.partId, accesstoken).then(
      //   (res) => {
      //     dispatch({
      //       type: PARTS_GET_PART_DETAILS_SUCCESS,
      //       data: res,
      //     });
      //     resolve(res);
      //   },
      //   // Use rejectHandler as the second argument so that render errors won't be caught.
      //   (err) => {
      //     dispatch({
      //       type: PARTS_GET_PART_DETAILS_FAILURE,
      //       data: { error: err },
      //     });
      //     reject(err);
      //   },
      // );
    });

    return promise;
  };
}

export function dismissGetPartDetailsError() {
  return {
    type: PARTS_GET_PART_DETAILS_DISMISS_ERROR,
  };
}

export function useGetPartDetails(params) {
  const dispatch = useDispatch();

  const { getPartDetailsPending, getPartDetailsError } = useSelector(
    state => ({
      getPartDetailsPending: state.parts.getPartDetailsPending,
      getPartDetailsError: state.parts.getPartDetailsError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(getPartDetails(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetPartDetailsError());
  }, [dispatch]);

  return {
    getPartDetails: boundAction,
    getPartDetailsPending,
    getPartDetailsError,
    dismissGetPartDetailsError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case PARTS_GET_PART_DETAILS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getPartDetailsPending: true,
        getPartDetailsError: null,
      };

    case PARTS_GET_PART_DETAILS_SUCCESS:
      // The request is success
      return {
        ...state,
        getPartDetailsPending: false,
        getPartDetailsError: null,
        partDetails: action.data,
      };

    case PARTS_GET_PART_DETAILS_FAILURE:
      // The request is failed
      return {
        ...state,
        getPartDetailsPending: false,
        getPartDetailsError: action.data.error,
      };

    case PARTS_GET_PART_DETAILS_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getPartDetailsError: null,
      };

    default:
      return state;
  }
}
