import React from 'react';
import { PageHeader } from '../common';
import HelpDashboardContent from './HelpDashboardContent';


export default function HelpDashboard(props) {
  return (
    <div className="help-help-dashboard">
      <PageHeader location={props.location} />

      <HelpDashboardContent />
    </div>
  );
};

