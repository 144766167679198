import React, { useEffect, useCallback, useRef } from 'react';
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  TextField,
  InputAdornment, FormControl,
} from '@material-ui/core';
import { findWhere, isNull } from 'underscore';
import { useGetStatesOfCountry } from '../common/redux/getStatesOfCountry';
import { KeyboardDatePicker } from '@material-ui/pickers';
import AddAddressButton from '../common/AddAddressButton';
import StringHelper from "../common/services/StringHelper";
import {CONFIG} from './../../config';
import AddIcon from '@material-ui/icons/Add';
import {useToggleAddOwnerDialog} from "./redux/toggleAddOwnerDialog";
import {useSelector} from "react-redux";
import UploadActivityImages from "./UploadActivityImages";


export default function ActivityDetailsAddDialogContent(props) {
  const {getStatesOfCountry} = useGetStatesOfCountry();
  const {toggleAddOwnerDialog} = useToggleAddOwnerDialog();
  const addOwnerDialogStatus = useSelector(state => state.equipments.addOwnerDialogStatus);
  const formikRef = useRef(props.formik);

  useEffect(() => {
    if (!props.formik.values.address.country) return;

    let country = findWhere(props.countries, {name: props.formik.values.address.country});

    if (!country) return;

    getStatesOfCountry(country.id);
  }, [props.formik.values.address.country, getStatesOfCountry, props.countries]);

  useEffect(() => {
    if (!isNull(addOwnerDialogStatus) && addOwnerDialogStatus === 'CANCELLED' && props.formik.values.ownerId !== props.activityDetails.equipmentDetails.ownerId) {
        props.formik.setFieldValue('ownerId', props.activityDetails.equipmentDetails.ownerId);
    }
  }, [addOwnerDialogStatus, props.formik, props.activityDetails]);

  const handleActivityImgUploadClick = useCallback((files) => {
    formikRef.current.setFieldValue('files', files);
  }, []);

  return (
    <div className="equipments-activity-details-add-dialog-content">
      <Grid container
            direction="row"
            alignItems="flex-start">
        <Grid item lg={5}>
          <table>
            <tbody>
            <tr>
              <td colSpan={2}>
                <Typography variant="p" display="block" color="secondary">{props.user.email}</Typography>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <Typography variant="p" display="block" color="secondary">{props.user.owner && props.user.owner.company}</Typography>
              </td>
            </tr>

            <tr><td colSpan={2}></td></tr>

            <tr><td width="60%">Manufacturer</td><td>{props.activityDetails.equipmentDetails.manufacturer.description}</td></tr>
            <tr><td width="60%">Serial Number</td><td>{props.activityDetails.equipmentDetails.serialNumber}</td></tr>
            <tr>
              <td colSpan={2}>
                <UploadActivityImages
                    accept="image/*, video/*, application/pdf"
                    onChange={handleActivityImgUploadClick}
                />
              </td>
            </tr>
            </tbody>
          </table>
        </Grid>

        <Grid item lg={6}>
          <table>
            <tbody>
            <tr><td>Description</td><td>{props.activityDetails.equipmentDetails.part ? props.activityDetails.equipmentDetails.part.description : ""}</td></tr>
            <tr>
              <td>Owner</td>
              <td>
                  {
                    props.formik.values.activityTypeId !== 9
                    ? (props.activityDetails.equipmentDetails.owner ? props.activityDetails.equipmentDetails.owner.company : "")
                    : <Select /* if (Ownership Changed (9) */
                            fullWidth
                            id="ownerId"
                            name="ownerId"
                            className="fob-filled-input-with-outside-label"
                            label={null}
                            placeholder="Select Owner"
                            color="secondary"
                            value={props.formik.values.ownerId}
                            onChange={props.formik.handleChange}
                        >
                            <MenuItem
                                value={null}
                                onClick={toggleAddOwnerDialog.bind(this, true)}
                            >
                              <AddIcon color="secondary" /><Typography component="p" color="secondary">New Owner</Typography>
                            </MenuItem>
                            {
                                props.ownersData.rows.map(item => <MenuItem
                                    value={item.ownerId}>{item.company}</MenuItem>)
                            }
                        </Select>
                  }
              </td>
            </tr>
            <tr><td>Date of Install</td>
                <td>{StringHelper.formatDisplayDate(props.activityDetails.equipmentDetails.dateOfInstall)}</td>
            </tr>
            <tr>
              <td>Activity</td>
              <td>
                <Select
                  fullWidth
                  id="activityTypeId"
                  name="activityTypeId"
                  className="input-select"
                  value={props.formik.values.activityTypeId}
                  color="secondary"
                  onChange={props.formik.handleChange}
                >
                  <MenuItem value="" disabled>
                    Select Activity
                  </MenuItem>
                  {
                    props.activityTypeData && props.activityTypeData.map(activity => <MenuItem value={activity.activityTypeId}>{activity.description}</MenuItem> )
                  }
                </Select>
              </td>
            </tr>

            <tr>
              <td>Date of Activity</td>
              <td>
                <FormControl
                  fullWidth
                  className="fob-filled-input-with-outside-label">
                  <KeyboardDatePicker
                    autoOk={true}
                    variant="inline"
                    inputVariant="filled"
                    format={CONFIG.DATE_DISPLAY_FORMAT}
                    margin="normal"
                    id="date"
                    labelId="date-label"
                    label={null}
                    className="fob-date-picker"
                    value={props.formik.values.date}
                    onChange={date => {
                      props.formik.setFieldValue('date', date);
                    }}
                  />
                </FormControl>
              </td>
            </tr>

            {/** Shipped(2) */}
            { props.formik.values.activityTypeId === 2 &&
              <>
              <tr>
                <td>Shipper</td>
                <td>
                  <Select
                    fullWidth
                    id="shipperId"
                    name="shipperId"
                    className="input-select"
                    value={props.formik.values.shipperId}
                    color="secondary"
                    onChange={props.formik.handleChange}
                  >
                    <MenuItem value="" disabled>
                      Select Shipper
                    </MenuItem>
                    {
                      props.shippersListData && props.shippersListData.map(shipper => <MenuItem value={shipper.shipperId}>{shipper.name}</MenuItem> )
                    }
                  </Select>
                </td>
              </tr>
              <tr>
                <td>ProNumber</td>
                <td>
                  <TextField
                    fullWidth
                    id="proNumber"
                    variant="filled"
                    label={null}
                    InputProps={{
                      disableUnderline: true
                    }}
                    className="rounded-input"
                    value={props.formik.values.proNumber}
                    onChange={props.formik.handleChange}
                  />
                </td>
              </tr>
              </>
            }

            {/* Storage(3), Warehouse Entry(4), Warehouse Exit(5), 
              *  Maintenance Scheduled(10), Maintenance Unscheduled(11),
              *  Warranty Work(12), Service Agreement Work(13) */}
            { ( props.formik.values.activityTypeId === 3
              || props.formik.values.activityTypeId === 4
              || props.formik.values.activityTypeId === 5
              || props.formik.values.activityTypeId === 10
              || props.formik.values.activityTypeId === 11
              || props.formik.values.activityTypeId === 12
              || props.formik.values.activityTypeId === 13 ) &&
              <tr>
                <td>Cost</td>
                <td>
                  <TextField
                    fullWidth
                    id="cost"
                    variant="filled"
                    label={null}
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                    className="rounded-input"
                    value={props.formik.values.cost}
                    onChange={props.formik.handleChange}
                  />
                </td>
              </tr>
            }

            {/* Maintenance Scheduled(10), Maintenance Unscheduled(11), 
              * Warranty Work(12), Service Agreement Work(13) */}
            { ( props.formik.values.activityTypeId === 10
              || props.formik.values.activityTypeId === 11
              || props.formik.values.activityTypeId === 12
              || props.formik.values.activityTypeId === 13 ) &&
              <tr>
                <td>Repair Time</td>
                <td>
                  <TextField
                    fullWidth
                    id="repairTime"
                    variant="filled"
                    label={null}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: <InputAdornment position="end">minutes</InputAdornment>
                    }}
                    className="rounded-input"
                    value={props.formik.values.repairTime}
                    onChange={props.formik.handleChange}
                  />
                </td>
              </tr>
            }

            <tr>
              <td>Location</td>
              <td>
                {
                  props.formik.values.address.line1 && props.formik.values.address.city && props.formik.values.address.state && props.formik.values.address.country && props.formik.values.address.zipCode
                  ? <div onClick={() => {
                      props.setIsAddressDialogVisible(true);
                    }}>
                      <p>{props.formik.values.address.line1}</p>
                      <p>{props.formik.values.address.city}, {props.formik.values.address.state} {props.formik.values.address.zipCode}</p>
                      <p>{props.formik.values.address.country}</p>
                    </div>
                    : <AddAddressButton onClick={() => {
                                props.setIsAddressDialogVisible(true);
                              }}
                      />
                }
              </td>
            </tr>
            </tbody>
          </table>
        </Grid>
      </Grid>

      <Grid container
            direction="column"
            justify="flex-start"
            alignItems="flex-start"
      >
        <Grid item>
          <Typography variant="p" display="block">Notes</Typography>
        </Grid>
        <Grid item container lg={12}>
          <TextField
            multiline
            fullWidth
            id="notes"
            variant="filled"
            label={null}
            InputProps={{disableUnderline: true}}
            className="rounded-input"
            value={props.formik.values.notes}
            onChange={props.formik.handleChange}
          />
        </Grid>
      </Grid>
    </div>
  );
};


