import React from 'react';
import {
  Button, Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider, Grid,
  IconButton, TextField,
  Toolbar,
  Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import { useToggleAdminCommonDialog } from '../admin/redux/toggleAdminCommonDialog';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {useUpdateRole} from "./redux/updateRole";
import Swal from "sweetalert2";
import LoadingButton from "../common/LoadingButton";


const validationSchema = Yup.object({
  description: Yup.string().required('Required'),
});


export default function RoleDetailsDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const toggleDialog = useSelector(state => state.admin.toggleDialog);
  const {toggleAdminCommonDialog} = useToggleAdminCommonDialog();
  const {updateRole, updateRolePending} = useUpdateRole();

  const formik = useFormik({
    initialValues: {
      description: toggleDialog.roleDetails && toggleDialog.roleDetails.description,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: values => {
      console.log('onSubmit: ',values);
      updateRole(toggleDialog.roleDetails.roleId, values.description)
          .then(res => {
              formik.resetForm();
              Swal.fire({
                icon: 'success',
                text: res.message,
                timer: 2000
              }).then(() => {
                toggleAdminCommonDialog('RoleDetailsDialog', false, {});
              });
          });
    },
  });

  return (
    <Dialog
      fullScreen={fullScreen}
      open={toggleDialog.name === 'RoleDetailsDialog' && toggleDialog.flag === true}
      className="roles-role-details-dialog fob-details-dialog">

      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          <Toolbar>
            <Typography variant="h5" display="inline">Role</Typography>
            <span style={{flexGrow: 1}}></span>
            <IconButton
              edge="end"
              color="inherit" aria-label="menu"
              onClick={toggleAdminCommonDialog.bind(this, 'RoleDetailsDialog', false, {})}>
              <HighlightOffIcon />
            </IconButton>
          </Toolbar>
          <Divider />
        </DialogTitle>

        <DialogContent>

          <Container maxWidth="sm">
            <Grid container
                  lg={12}
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={2}>
              <Grid item lg={12}>
                <TextField
                  id="description"
                  label="Role Description"
                  InputProps={{disableUnderline: true}}
                  InputLabelProps={{ shrink: true }}
                  className="fob-filled-input-with-outside-label"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Container>
        </DialogContent>

        <DialogActions>
            {
              updateRolePending === true
                ? <LoadingButton/>
                  : <Button variant="contained"
                            disableElevation
                            color="secondary"
                            type="submit"
                            className="rounded-btn"
                  >
                    Save
                  </Button>
            }


          <Button variant="contained"
                  disableElevation
                  onClick={() => {
                    formik.resetForm();
                    toggleAdminCommonDialog('CreateRoleDialog', false, {});
                  }}
                  className="rounded-btn dark-btn"
                  disabled={updateRolePending === true}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

