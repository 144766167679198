import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  EQUIPMENTS_TOGGLE_SERVICE_AGREEMENT_LIST,
} from './constants';
import {isNull} from 'underscore';

export function toggleServiceAgreementList(flag = null) {
  return {
    type: EQUIPMENTS_TOGGLE_SERVICE_AGREEMENT_LIST,
    flag: flag,
  };
}

export function useToggleServiceAgreementList() {
  const dispatch = useDispatch();
  const isServiceAgreementListVisible = useSelector(state => state.equipments.isServiceAgreementListVisible);
  const boundAction = useCallback((...params) => dispatch(toggleServiceAgreementList(...params)), [dispatch]);
  return { isServiceAgreementListVisible, toggleServiceAgreementList: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case EQUIPMENTS_TOGGLE_SERVICE_AGREEMENT_LIST:
      return {
        ...state,
        isServiceAgreementListVisible: isNull(action.flag) ? !state.isServiceAgreementListVisible : action.flag,
      };

    default:
      return state;
  }
}
