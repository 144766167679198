import React from 'react';
import {
  Paper,
  Grid,
  Typography,
  Button,
  CardContent,
  FormControl,
  FilledInput,
  InputAdornment,
  Card,
} from '@material-ui/core';
import UserGuideCard from './UserGuideCard';
import SearchIcon from '@material-ui/icons/Search';
import CallIcon from '@material-ui/icons/Call';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';


export default function HelpDashboardContent() {
  return (
    <Paper className="help-help-dashboard-content fob-content-body">
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={4}
      >
        <Grid item xs={12}>
          <Typography variant="h5" component="h5">Hello, how can we help?</Typography>
        </Grid>

        <Grid item className="search-input-container" xs={12}>
          <FormControl variant="filled"
                       className="fob-search-input-container">
            <FilledInput
              id="fob-search-input"
              placeholder="Search for Answers..."
              disableUnderline
              startAdornment={
                 <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <Button variant="contained" color="secondary" className="rounded-btn">Search</Button>
                </InputAdornment>
              } />
          </FormControl>
        </Grid>

        <Grid item
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={4}
              className="guides-container"
        >
          <Grid item xs={12} sm={12} md={5}>
            <UserGuideCard />
          </Grid>

          <Grid item xs={12} sm={12} md={5}>
            <UserGuideCard />
          </Grid>
        </Grid>

        <Grid container
              item
              direction="column"
              justify="center"
              alignItems="center"
              md={12}
        >
          <Typography variant="h5" component="h5">Hello, how can we help?</Typography>
          <Typography align="center" variant="p" component="p">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed</Typography>
        </Grid>

        <Grid item
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={4}
              className="support-container"
        >
          <Grid item xs={12} sm={12} md={4} className="support-card-item">
            <Card>
              <CardContent>
                <CallIcon />
                <Typography variant="h5" component="h5">+1 (866) LAT-BFOB (866-528-2362)</Typography>
                <Typography variant="p" component="p">Call us anytime for a quick solution</Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={4} className="support-card-item">
            <a href="mailto:support@blockchainfob.com"><Card>
              <CardContent>
                <MailOutlineIcon />
                <Typography variant="h5" component="h5">support@blockchainfob.com</Typography>
                <Typography variant="p" component="p">Send us a mail to resolve your issue</Typography>
              </CardContent>
            </Card>
            </a>
          </Grid>

          <Grid item xs={12} sm={12} md={4} className="support-card-item">
            <Card>
              <CardContent>
                <PictureAsPdfIcon />
                <Typography variant="a" component="a" color="secondary">Link to PDF of the User Guide</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

