import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  DASHBOARD_GET_ASSET_MOVEMENT_BY_PARTS_AGGREGATION_BEGIN,
  DASHBOARD_GET_ASSET_MOVEMENT_BY_PARTS_AGGREGATION_SUCCESS,
  DASHBOARD_GET_ASSET_MOVEMENT_BY_PARTS_AGGREGATION_FAILURE,
  DASHBOARD_GET_ASSET_MOVEMENT_BY_PARTS_AGGREGATION_DISMISS_ERROR,
} from './constants';
import ApiService from "../../common/services/ApiService";

export function getAssetMovementByPartsAggregation(searchValue, startDate, endDate) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: DASHBOARD_GET_ASSET_MOVEMENT_BY_PARTS_AGGREGATION_BEGIN,
    });

    const startDateStr = startDate ? startDate.format('YYYY-MM-DD') : '';
    const endDateStr = endDate ? endDate.format('YYYY-MM-DD') : '';

    const promise = new Promise((resolve, reject) => {
      ApiService.getAssetMovementByPartsAggregation(searchValue, startDateStr, endDateStr).then(
        (res) => {
          dispatch({
            type: DASHBOARD_GET_ASSET_MOVEMENT_BY_PARTS_AGGREGATION_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: DASHBOARD_GET_ASSET_MOVEMENT_BY_PARTS_AGGREGATION_FAILURE,
            data: { error: err },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissGetAssetMovementByPartsAggregationError() {
  return {
    type: DASHBOARD_GET_ASSET_MOVEMENT_BY_PARTS_AGGREGATION_DISMISS_ERROR,
  };
}

export function useGetAssetMovementByPartsAggregation(params) {
  const dispatch = useDispatch();

  const { getAssetMovementByPartsAggregationPending, getAssetMovementByPartsAggregationError, getAssetMovementByPartsAggregationData } = useSelector(
    state => ({
      getAssetMovementByPartsAggregationPending: state.dashboard.getAssetMovementByPartsAggregationPending,
      getAssetMovementByPartsAggregationError: state.dashboard.getAssetMovementByPartsAggregationError,
      getAssetMovementByPartsAggregationData: state.dashboard.getAssetMovementByPartsAggregationData,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((searchValue, startDate, endDate) => {
    return dispatch(getAssetMovementByPartsAggregation(searchValue, startDate, endDate));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissGetAssetMovementByPartsAggregationError());
  }, [dispatch]);

  return {
    getAssetMovementByPartsAggregation: boundAction,
    getAssetMovementByPartsAggregationPending,
    getAssetMovementByPartsAggregationError,
    getAssetMovementByPartsAggregationData,
    dismissGetAssetMovementByPartsAggregationError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case DASHBOARD_GET_ASSET_MOVEMENT_BY_PARTS_AGGREGATION_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        getAssetMovementByPartsAggregationPending: true,
        getAssetMovementByPartsAggregationError: null,
      };

    case DASHBOARD_GET_ASSET_MOVEMENT_BY_PARTS_AGGREGATION_SUCCESS:
      // The request is success
      return {
        ...state,
        getAssetMovementByPartsAggregationPending: false,
        getAssetMovementByPartsAggregationError: null,
        getAssetMovementByPartsAggregationData: action.data,
      };

    case DASHBOARD_GET_ASSET_MOVEMENT_BY_PARTS_AGGREGATION_FAILURE:
      // The request is failed
      return {
        ...state,
        getAssetMovementByPartsAggregationPending: false,
        getAssetMovementByPartsAggregationError: action.data.error,
      };

    case DASHBOARD_GET_ASSET_MOVEMENT_BY_PARTS_AGGREGATION_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        getAssetMovementByPartsAggregationError: null,
      };

    default:
      return state;
  }
}
