import React, {useState} from 'react';
import {PageHeader} from "../common";
import {
    Grid,
    Paper,
    FormControl,
    Select,
    MenuItem,
    Typography,
} from '@material-ui/core';
import {DashboardContent} from './index';
import {
    ASSET_MOVEMENT_TYPE,
    LIFECYCLE_TRACKING_TYPE,
    MOST_COMMON_FAULT_CODES_TYPE,
    MOST_EXPENSIVE_FAULT_CODES_TYPE,
    SPEND_BY_MANUFACTURER_TYPE,
    SPEND_BY_MODEL_TYPE,
} from './redux/constants';

function DashboardContentTypeDropdown({
    contentType,
    setContentType,
}) {
    return (
        <FormControl className="paper-select"
                     component={Paper}
                     color="primary"
                     size="small"
                     fullWidth>
            <Select
                id="type-select"
                value={contentType}
                onChange={e => setContentType(e.target.value)}>
                <MenuItem value={ASSET_MOVEMENT_TYPE}><Typography variant="button">{ASSET_MOVEMENT_TYPE}</Typography></MenuItem>
                <MenuItem value={LIFECYCLE_TRACKING_TYPE}><Typography variant="button">{LIFECYCLE_TRACKING_TYPE}</Typography></MenuItem>
                <MenuItem value={SPEND_BY_MANUFACTURER_TYPE}><Typography variant="button">{SPEND_BY_MANUFACTURER_TYPE}</Typography></MenuItem>
                <MenuItem value={SPEND_BY_MODEL_TYPE}><Typography variant="button">{SPEND_BY_MODEL_TYPE}</Typography></MenuItem>
                <MenuItem value={MOST_EXPENSIVE_FAULT_CODES_TYPE}><Typography variant="button">{MOST_EXPENSIVE_FAULT_CODES_TYPE}</Typography></MenuItem>
                <MenuItem value={MOST_COMMON_FAULT_CODES_TYPE}><Typography variant="button">{MOST_COMMON_FAULT_CODES_TYPE}</Typography></MenuItem>
            </Select>
        </FormControl>
    );
}

export default function Dashboard({location}) {
  const [contentType, setContentType] =  useState(ASSET_MOVEMENT_TYPE);

  return (
    <div className="dashboard-dashboard">
        <PageHeader location={location} />

        <div className="fob-content-body">
            <Grid className="dashboard-dashboard-body"
                  component={Paper}
                  container
                  direction="column"
                  justifyContent="center">
                <Grid className="dashboard-dashboard-body111"
                      item
                      container
                      alignItems="center">
                    <Grid item
                          style={{'justify-self': 'flex-end'}}
                          lg={3}>
                        <DashboardContentTypeDropdown contentType={contentType}
                                                      setContentType={setContentType} />
                    </Grid>
                </Grid>

                <Grid item container>
                    <DashboardContent contentType={contentType} />
                </Grid>
            </Grid>
        </div>
    </div>
  );
};
