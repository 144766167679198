import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {Fab, GridList, GridListTile, IconButton} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import pdfIcon from './../../images/pdf_preview1.png';
import imageIcon from './../../images/image-file.png';
import videoIcon from './../../images/VideoImage_ic.png';
import {uniq} from 'underscore';

export function getFileAttr(file, type) {
    let attr = {url: '', icon: '', fileType: ''};
    if (type.includes('video')) {
        attr = {
            url: URL.createObjectURL(file),
            icon: videoIcon,
            fileType: 'video',
        }
    } else if (type.includes('pdf')) {
        attr = {
            url: URL.createObjectURL(file),
            icon: pdfIcon,
            fileType: 'pdf',
        }
    } else {
        attr = {
            url: URL.createObjectURL(file),
            icon: imageIcon,
            fileType: 'image',
        }
    }
    return attr;
}

async function myCustomFileGetter(event, files, setFiles) {
    const _files = [];
    const fileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    for (let i = 0; i < fileList.length; i++) {
        const file = {
            source: fileList.item(i),
        };
        let attr = getFileAttr(file.source, file.source.type);
        file.url = attr.url;
        file.icon = attr.icon;
        file.fileType = attr.fileType;
        _files.push(file);
    }
    const concatFiles = uniq(files.concat(_files), (f) => f.source.name);
    setFiles(concatFiles);
    return concatFiles;
}

export function ActivityFileThumbnail(props) {
    let comp;
    switch (props.file.fileType) {
        case 'pdf':
            comp = (
                <a href={props.file.url} target="_blank">
                    <img src={props.file.icon} alt={props.file.source.name} />
                </a>
            );
            break;
        case 'video':
            comp = (
                <a href={props.file.url} target="_blank">
                    <img src={props.file.icon} alt={props.file.source.name} />
                </a>
            );
            break;
        default:
            comp = <img src={props.file.url} alt={props.file.source.name} />
    }
    return comp;
}


export default function UploadActivityImages(props) {
  const onChange = props.onChange;
  const [files, setFiles] = useState([]);
  const {
      getRootProps,
      getInputProps
  } = useDropzone({
      accept: props.accept,
      multiple: true,
      getFilesFromEvent: event => myCustomFileGetter(event, files, setFiles),
  });

  useEffect(() => {
      onChange(files);
  }, [files, onChange]);

  const removeFile = (file) => {
    const newFiles = files.filter(f => f.source.name !== file.source.name);
    setFiles(newFiles);
  };

  return (
    <div className="equipments-upload-activity-images">
        <div className="dropzone">
            <label htmlFor="file" {...getRootProps()} className="activity-dropzone-label">
                <Fab component="span" size="small" color="primary" aria-label="edit"><EditIcon /></Fab>
                <input {...getInputProps()} />
            </label>
            <GridList cellHeight={160} className="activity-dropzone-files" cols={3}>
                {files.map((file) => (
                    <GridListTile key={file.name} cols={1}>
                        <IconButton aria-label="delete"
                                    className="activity-dropzone-file-remove"
                                    onClick={() => {
                                        removeFile(file);
                                    }}>
                            <CloseIcon />
                        </IconButton>
                        <ActivityFileThumbnail file={file} />
                    </GridListTile>
                ))}
            </GridList>
        </div>
    </div>
  );
};
