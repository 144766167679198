import ApiService from '../../common/services/ApiService';
import Cookies from 'js-cookie';
import history from '../../../common/history';
import { isEmpty } from 'underscore';

const AUTH_TOKEN = 'access_token';
const AUTH_EXPIRATION = 'expires_at';
const AUTH_USER = 'current_user';

const HOME_PAGE = '/equipment';
const LOGIN_PAGE = '/auth/signin';

const IS_USER_BIOMETRIC_SET = 'IS_USER_BIOMETRIC_SET';
const AUTH_USER_BIOMETRIC_DATA = 'AUTH_USER_BIOMETRIC_DATA';


export default class AuthService {

  static _setSession(data, userData) {
    Cookies.set(AUTH_TOKEN, data.token);
    Cookies.set(AUTH_EXPIRATION, data.expires);
    Cookies.set(AUTH_USER, userData);
    console.log('EXPIRE date: ', new Date(data.expires));
  }

  static _clearSession() {
    Cookies.remove(AUTH_TOKEN);
    Cookies.remove(AUTH_EXPIRATION);
    Cookies.remove(AUTH_USER);
  }

  static _getToken() {
    const accessToken = Cookies.get(AUTH_TOKEN);

    if (!accessToken) {
      // throw new Error('No access token found');
      console.log('No access token found');
    }

    return accessToken;
  }

  static getAuthUser() {
    const user = Cookies.get(AUTH_USER);

    if (!user) {
      // AuthService.signOut();
      // throw new Error('No authenticated user found');
      return;
    }

    if (!AuthService.isAuthenticated()) {
      // AuthService.signOut();
      // throw new Error('User not authenticated');
      return;
    }
    
    return JSON.parse(user);
  }

  static updateAuthUser(data) {
    Cookies.set(AUTH_USER, data);
  }

  static redirectToHomePage() {
    history.replace(HOME_PAGE);
  }

  static isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(Cookies.get(AUTH_EXPIRATION));
    const user = Cookies.get(AUTH_USER);

    return new Date().getTime() < expiresAt && !isEmpty(user);
  }

  static checkIsUserAuthenticated() {
    const user = Cookies.get(AUTH_USER);
    return (user && AuthService.isAuthenticated());
  }

  static signIn(args) {
    return new Promise((resolve, reject) => {
      ApiService.signIn(args)
        .then(
          data => {
            if (data && data.token && data.expires) {
              resolve(data);
              // ApiService.getUserProfile(data.token)
              //   .then( userRes => {
              //     if (userRes) {
              //       AuthService._setSession(data, userRes);
              //
              //       resolve(data);
              //
              //       AuthService.redirectToHomePage();
              //     } else {
              //       reject(userRes);
              //     }
              //     console.log('userReS: ',userRes);
              //   }, userErr => {
              //     console.log('userErr: ',userErr);
              //     reject(userErr);
              //   });
            } else {
              reject(data);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  static signOut() {
    return new Promise((resolve, reject) => {
      ApiService.signOut()
        .then( data => {
            resolve(data);
          }, err => {
            reject(err);
          }).finally(() => {
            AuthService._clearSession();
            history.replace(LOGIN_PAGE);
          });
    });
  }

  static checkInvalidTokenError(err) {
    // {"statusCode":1003.1,"message":{"code":"INVALID_TOKEN_DB","message":"Your Token is not there in the DB"}}
    if(err && err.response 
      && err.response.data && err.response.data.message 
      && err.response.data.message.code === "INVALID_TOKEN_DB") {
      AuthService._clearSession();
      history.replace(LOGIN_PAGE);
    }
  }

  static checkUserBiometricSettings() {
      const value = Cookies.get(IS_USER_BIOMETRIC_SET);

      if (!value) return null;

      return JSON.parse(value);
  }

  static setUserBiometricSettings(flag=true) {
        return Cookies.set(IS_USER_BIOMETRIC_SET, flag);
  }

  static setUserBiometricData(data) {
      Cookies.set(AUTH_USER_BIOMETRIC_DATA, data);
  }

  static checkUserBiometricData() {
      return Cookies.get(AUTH_USER_BIOMETRIC_DATA);
  }
}