import React, { useCallback, useContext, useEffect, useState } from 'react';
import BaseTable from '../common/BaseTable';
import { AppBar, Button, Fab, TableCell, TableRow, Toolbar } from '@material-ui/core';
import AuthContext from '../authentication/AuthContext';
import ManufacturerssHelper from '../common/services/ManufacturersHelper';
import AddIcon from '@material-ui/icons/Add';


const Header = (props) => {
  return (
    <AppBar position="relative" color="default" className="common-base-table-header">
      <Toolbar>
        <div style={{flexGrow: 1}} />

        <div className="header-actions">
          <Button variant="contained" color="secondary" onClick={props.toggleUploadManufacturersDialog.bind(this, true)}>
            Upload Manufactures
          </Button>

          <Fab size="medium"
               color="secondary"
               aria-label="add"
               className="datatable-add-item-btn"
               onClick={props.onClickAddBtn}
          >
            <AddIcon />
          </Fab>
        </div>
      </Toolbar>
    </AppBar>
  )
};



export default function ManufacturersTable(props) {
  const user = useContext(AuthContext);
  const toggleManufacturerDetailsDialog = props.toggleManufacturerDetailsDialog;
  const data = props.data;

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setColumns(
      ManufacturerssHelper.getManufacturerTableColumns()
    );
  }, []);


  useEffect(() => {
    if (!data || !data.rows) return;

    setRows(
      data.rows
    );
  }, [data]);

  const handleRowClick = useCallback(
    (row) => {
      toggleManufacturerDetailsDialog(true, row.manufacturerId);
    }, [toggleManufacturerDetailsDialog]);

  const renderTableBody = useCallback(
    (rows, page, rowsPerPage) => {
      return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
        <TableRow hover key={row.name} onClick={handleRowClick.bind(this, row)}>
          <TableCell align="center">{row.description}</TableCell>
          <TableCell align="center">{`${row.address.city}, ${row.address.state}`}</TableCell>
        </TableRow>
      ))
    }, [handleRowClick]);

  const MyHeader = () => <Header user={user}
                                 toggleUploadManufacturersDialog={props.toggleUploadManufacturersDialog}
                                 onClickAddBtn={props.onClickAddBtn} />;

  return (
    <div className="manufacturers-manufacturers-table fob-content-body">
      <BaseTable headerComponent={MyHeader}
                 columns={columns}
                 rows={rows}
                 renderTableBody={renderTableBody} />
    </div>
  );
};
