import React, {useState, useCallback} from 'react';
import {
    Grid,
    Typography,
} from '@material-ui/core';
import {SPEND_BY_MANUFACTURER_HEADER_TEXT} from '../redux/constants';
import Chart2DDoughnutPaper from './widgets/Chart2DDoughnutPaper';
import ApiService from '../../common/services/ApiService';

function useGetData() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const getData = useCallback((startDate, endDate) => {
        if (!startDate || !endDate) return;

        setLoading(true);

        const startDateStr = startDate ? startDate.format('YYYY-MM-DD') : '';
        const endDateStr = endDate ? endDate.format('YYYY-MM-DD') : '';

        ApiService.getSpendByManufacturerAggregation(startDateStr, endDateStr)
            .then((res) => {
                if (res) {
                    setData(res.map(item => ({value: item.grandTotal, label: item.manufacturerCode})));
                } else {
                    setError(res);
                }
            }, (err) => {
                setError(err);
            })
            .finally(() => setLoading(false));
    }, []);

    return {
        data,
        loading,
        error,
        getData,
    };
}

function SpendByManufacturerWidget() {
    const [dateRange, setDateRange] = useState({startDate: null, endDate: null});
    const {data, loading, error, getData} = useGetData(dateRange.startDate, dateRange.endDate);

    //console.log('data: ',data,' loadng: ',loading,' error: ',error);

    const handleDateRangeChanged = useCallback(({startDate, endDate}) => {
        setDateRange({startDate, endDate});
    }, []);

    const handleApplyFilter = useCallback(() => {
        getData(dateRange.startDate, dateRange.endDate);
    }, [getData, dateRange]);

    return (
        <Chart2DDoughnutPaper
            chartType="pie3d"
            loading={loading}
            data={data}
            searchValue={null}
            dateRange={dateRange}
            assetsAddresses={null}
            onSearchValueChanged={null}
            onDateRangeChanged={handleDateRangeChanged}
            handleApplyFilter={handleApplyFilter}
            otherChartOptions={{
                pieRadius: '100%',
            }}
        />
    );
}

export default function SpendByManufacturer() {
    return (
        <Grid item
              container
              direction="column"
              justifyContent="center"
              alignItems="center">
            <Grid item>
                <Typography color="primary" className="dashboard-dashboard-content-header-title" variant="h6">{SPEND_BY_MANUFACTURER_HEADER_TEXT}</Typography>
            </Grid>
            <Grid item
                  container
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  style={{justifyContent: 'space-around'}}>
                <Grid item
                      container
                      alignItems="center"
                      style={{justifyContent: 'center'}}
                      md={12}
                      lg={12}>
                    <SpendByManufacturerWidget />
                </Grid>
            </Grid>
        </Grid>
    );
}