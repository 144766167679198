import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  EQUIPMENTS_TOGGLE_ADD_OWNER_DIALOG,
} from './constants';
import {isNull} from 'underscore';


export function toggleAddOwnerDialog(flag=null, status=null) {
  return {
    type: EQUIPMENTS_TOGGLE_ADD_OWNER_DIALOG,
    flag: flag,
    status: status,
  };
}

export function useToggleAddOwnerDialog() {
  const dispatch = useDispatch();
  const isAddOwnerDialogVisible = useSelector(state => state.equipments.isAddOwnerDialogVisible);
  const boundAction = useCallback((...params) => dispatch(toggleAddOwnerDialog(...params)), [dispatch]);
  return { isAddOwnerDialogVisible, toggleAddOwnerDialog: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case EQUIPMENTS_TOGGLE_ADD_OWNER_DIALOG:
      return {
        ...state,
        isAddOwnerDialogVisible: isNull(action.flag) ? !state.isAddManufacturerDialogVisible : action.flag,
        addOwnerDialogStatus: action.status,
      };

    default:
      return state;
  }
}
