import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  EQUIPMENTS_UPDATE_SERVICE_AGREEMENT_BEGIN,
  EQUIPMENTS_UPDATE_SERVICE_AGREEMENT_SUCCESS,
  EQUIPMENTS_UPDATE_SERVICE_AGREEMENT_FAILURE,
  EQUIPMENTS_UPDATE_SERVICE_AGREEMENT_DISMISS_ERROR,
} from './constants';
import ApiService from '../../common/services/ApiService';

export function updateServiceAgreement(serviceAgreementId, data) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: EQUIPMENTS_UPDATE_SERVICE_AGREEMENT_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      ApiService.updateServiceAgreement(serviceAgreementId, data).then(
        (res) => {
          dispatch({
            type: EQUIPMENTS_UPDATE_SERVICE_AGREEMENT_SUCCESS,
            data: res,
          });
          resolve({...res, message: 'Successfully updated service agreement.'});
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: EQUIPMENTS_UPDATE_SERVICE_AGREEMENT_FAILURE,
            data: {
              error: {
                message: err && err.response && err.response.data ? err.response.data.message : 'Unable to update service agreement.'
              }
            },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissUpdateServiceAgreementError() {
  return {
    type: EQUIPMENTS_UPDATE_SERVICE_AGREEMENT_DISMISS_ERROR,
  };
}

export function useUpdateServiceAgreement(params) {
  const dispatch = useDispatch();

  const { updateServiceAgreementPending, updateServiceAgreementError, updateServiceAgreementResult } = useSelector(
    state => ({
      updateServiceAgreementPending: state.equipments.updateServiceAgreementPending,
      updateServiceAgreementError: state.equipments.updateServiceAgreementError,
      updateServiceAgreementResult: state.equipments.updateServiceAgreementResult,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(updateServiceAgreement(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissUpdateServiceAgreementError());
  }, [dispatch]);

  return {
    updateServiceAgreement: boundAction,
    updateServiceAgreementPending,
    updateServiceAgreementError,
    updateServiceAgreementResult: updateServiceAgreementResult,
    dismissUpdateServiceAgreementError: boundDismissError,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case EQUIPMENTS_UPDATE_SERVICE_AGREEMENT_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        updateServiceAgreementPending: true,
        updateServiceAgreementError: null,
      };

    case EQUIPMENTS_UPDATE_SERVICE_AGREEMENT_SUCCESS:
      // The request is success
      return {
        ...state,
        updateServiceAgreementPending: false,
        updateServiceAgreementError: null,
        updateServiceAgreementResult: action.data,
      };

    case EQUIPMENTS_UPDATE_SERVICE_AGREEMENT_FAILURE:
      // The request is failed
      return {
        ...state,
        updateServiceAgreementPending: false,
        updateServiceAgreementError: action.data.error,
      };

    case EQUIPMENTS_UPDATE_SERVICE_AGREEMENT_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        updateServiceAgreementError: null,
      };

    default:
      return state;
  }
}
