import React, { useState } from 'react';
import {
  Button, Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider, Grid,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import { useToggleUploadManufacturersDialog } from './redux/toggleUploadManufacturersDialog';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { LoadingButton, UploadBox, DialogBox } from '../common';
import { useImportManufacturers } from './redux/hooks';
import { isEmpty } from 'underscore';
import Alert from '@material-ui/lab/Alert';


export default function UploadManufacturersDialog() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const {toggleUploadManufacturersDialog} = useToggleUploadManufacturersDialog();
  const isUploadManufacturersDialogVisible = useSelector(state => state.manufacturers.isUploadManufacturersDialogVisible);
  const {importManufacturers, importManufacturersError, importManufacturersPending} = useImportManufacturers();
  const [progress, setProgress] = useState(0);

  const handleFileOnDrop = (files) => {
    console.log('handleFileOnDrop',files);

    if (!files.length) return;

    importManufacturers(files[0], (progressEvent) => {
      console.log('progressEvent: ',progressEvent);
      let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

      console.log("onUploadProgress", percentCompleted);

      setProgress(percentCompleted);
    });
  };

  return (
    <DialogBox
      fullScreen={fullScreen}
      open={isUploadManufacturersDialogVisible}
      className="manufacturers-upload-manufacturers-dialog fob-upload-dialog">
        <DialogTitle>
          <Toolbar>
            <Typography variant="h5" display="inline">Upload Manufacturers CSV File Format</Typography>
            <span style={{flexGrow: 1}}></span>
            <IconButton
              edge="end"
              color="inherit" aria-label="menu"
              onClick={toggleUploadManufacturersDialog.bind(this, false)}>
              <HighlightOffIcon />
            </IconButton>
          </Toolbar>

          <Divider />

        </DialogTitle>

        <DialogContent>
          <Container maxWidth="sm">
            <Grid item md={12}>
              {
                isEmpty(importManufacturersError)
                  ? null
                  :
                  <Alert elevation={0} variant="standard" severity="error">
                    {importManufacturersError.message}
                  </Alert>
              }
            </Grid>
          </Container>

          <section className="fob-dropzone-container">
            <UploadBox
                filesLimit={1}
                dropzoneClass="fob-dropzone"
                showFileNamesInPreview={true}
                useChipsForPreview={true}
                showPreviewsInDropzone={true}
                dropzoneText="Drag and drop or browse your files"
                onDrop={handleFileOnDrop}
                progress={progress}
            />
          </section>
        </DialogContent>

        <DialogActions>
          {
            importManufacturersPending === true
            ? <LoadingButton />
            : <Button variant="contained"
                      disableElevation
                      color="secondary"
                      className="rounded-btn"
                      onClick={toggleUploadManufacturersDialog.bind(this, false)}
              >
                Done
              </Button>
          }
        </DialogActions>
    </DialogBox>
  );
};

