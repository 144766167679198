// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { AuthenticationIndex, Register, SignIn } from './';

export default {
  path: 'auth',
  component: AuthenticationIndex,
  childRoutes: [
    { path: 'register', component: Register },
    { path: 'signin', component: SignIn },
  ],
};
