import React, { useContext, useState } from 'react';
import { AppBar, Button, Toolbar,
        Typography, Grid, IconButton, TextField,
        FormControl, Select, MenuItem } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DescriptionIcon from '@material-ui/icons/Description';
import { useToggleServiceAgreementDialog, useToggleWarrantyDialog, useUpdateEquipment, useToggleActivityDetailsDialog } from './redux/hooks';
import AuthContext from '../authentication/AuthContext';
import {useToggleServiceAgreementList} from "./redux/toggleServiceAgreementList";
import StringHelper from "../common/services/StringHelper";
import { useToggleAdminCommonDialog } from '../admin/redux/toggleAdminCommonDialog';
import { isEmpty, findWhere } from 'underscore';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { AddAddressDialog } from './index';
import { useFormik } from 'formik';
import Swal from "sweetalert2";
import { KeyboardDatePicker } from '@material-ui/pickers';
import {CONFIG} from './../../config';
import AddIcon from '@material-ui/icons/Add';
import {useToggleAddOwnerDialog} from "./redux/toggleAddOwnerDialog";
import infoIcon from './../../images/information_ic.png';


export default function EquipmentDetailsHeader(props) {
  const {toggleWarrantyDialog} = useToggleWarrantyDialog();
  const {toggleServiceAgreementDialog}  = useToggleServiceAgreementDialog();
  const {toggleServiceAgreementList} = useToggleServiceAgreementList();
  const {toggleActivityDetailsDialog} = useToggleActivityDetailsDialog();
  const user = useContext(AuthContext);
  const {toggleAdminCommonDialog} = useToggleAdminCommonDialog();
  const [isAddressDialogVisible, setIsAddressDialogVisible] = useState(false);
  const [isDateFieldVisible, setIsDateFieldVisible] = useState(false);
  const [isOwnerFieldVisible, setIsOwnerFieldVisible] = useState(false);
  const [isSerialFieldVisible, setIsSerialFieldVisible] = useState(false);
  // const { updateOwner, updateOwnerPending } = useUpdateOwner();
  const { updateEquipment, updateEquipmentPending } = useUpdateEquipment();
  const [address, setAddress] = useState(props.equipmentDetails.address);
  const [dateOfInstall, setDateOfInstall] = useState(props.equipmentDetails.dateOfInstall);
  const [ownerId, setOwnerId] = useState(props.equipmentDetails.ownerId);
  const [serialNumber, setSerialNumber] = useState(props.equipmentDetails.serialNumber);
  const [latId, setLatId] = useState(props.equipmentDetails.latId);
  const {toggleAddOwnerDialog} = useToggleAddOwnerDialog();
  const isSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const assetId = props.equipmentDetails.assetId;

  /** Update asset location from popup */
  let addressInitialValues = {
    address: {
      line1: (address && address.line1) ? address.line1 : '',
      city: (address && address.city) ? address.city : '',
      zipCode: (address && address.zipCode) ? address.zipCode : '',
      state: (address && address.state) ? address.state : '',
      country: (address && address.country) ? address.country : 'United States'
    }
  };
  const addressFormik = useFormik({
    enableReinitialize: true,
    initialValues: addressInitialValues,
    // validationSchema,
    onSubmit: (values, {resetForm}) => {
      if(assetId) {
        let data = {
          assetId,
          address: values.address
        };
        updateEquipment(data)
        .then(res => {
          Swal.fire({
            icon: 'success',
            text: res.message,
            timer: 2000
          }).then(() => {
            setAddress(values.address);
            resetForm(addressInitialValues);
            setIsAddressDialogVisible(false);
          });
        });
      }
    },
  });

  let ownerInitialValues = {
    ownerId,
    address: {
      country: 'United States'
    }
  };
  const ownerFormik = useFormik({
    initialValues: ownerInitialValues,
    onSubmit: (values, {resetForm}) => {
      if(assetId) {
        let data = {
          assetId,
          ownerId: values.ownerId
        };
        updateEquipment(data)
        .then(res => {
          Swal.fire({
            icon: 'success',
            text: res.message,
            timer: 2000
          }).then(() => {
            setOwnerId(values.ownerId);
            resetForm(ownerInitialValues);
            setIsOwnerFieldVisible(false);
          });
        });
      }
    },
  });

  let serialInitialValues = {
      serialNumber,
      address: {
          country: 'United States'
      }
  };
  const serialFormik = useFormik({
      initialValues: ownerInitialValues,
      onSubmit: (values, {resetForm}) => {
          if (assetId) {
              let data = {
                  assetId,
                  serialNumber: values.serialNumber
              };
              updateEquipment(data)
                  .then(res => {
                      Swal.fire({
                          icon: 'success',
                          text: res.message,
                          timer: 2000
                      }).then(() => {
                          setSerialNumber(values.serialNumber);
                          resetForm(serialInitialValues);
                          setIsSerialFieldVisible(false);
                      });
                  });
          }
      },
  });

  /** Update date of install of equipment  */
  let dateInitialValues = {
    date: dateOfInstall ? dateOfInstall : ''
  }
  const dateEditFormik = useFormik({
    enableReinitialize: true,
    initialValues: dateInitialValues,
    // validationSchema,
    onSubmit: (values, {resetForm}) => {
      if(assetId) {
        let data = {
          assetId,
          dateOfInstall: values.date
        };
        updateEquipment(data)
        .then(res => {
          Swal.fire({
            icon: 'success',
            text: res.message,
            timer: 2000
          }).then(() => {
            setDateOfInstall(values.date);
            resetForm(dateInitialValues);
            setIsDateFieldVisible(false);
          });
        });
      }
    },
  });

  const owner = props.ownersData ? findWhere(props.ownersData.rows, {ownerId}) : {};
  
  return (
    <div className="equipments-equipment-details-header">
      <AddAddressDialog formik={addressFormik}
                        countries={props.countries}
                        visible={isAddressDialogVisible}
                        setVisible={setIsAddressDialogVisible}
                        loading={updateEquipmentPending}
                        isSubmitForm />
      <AppBar position="relative" color="default" className="common-base-table-header">
        <Toolbar>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid container item md={2} className="equipments-equipment-details-header-grid-item user-profile-space-container">
              <span className="user-profile-space">
                 <Typography component="p">{user.email}</Typography>
                 <Typography component="p">{user.owner && user.owner.company}</Typography>
              </span>

            </Grid>
            {/*<Divider orientation="vertical" />*/}

            <Grid container item md={4} className="equipments-equipment-details-header-grid-item">

            <table>
              <tbody>
              <tr>
                <td>Manufacturer</td>
                <td>
                    {props.equipmentDetails.manufacturer.description}
                    {
                      props.equipmentDetails.manufacturerInfo === true
                      &&
                      (
                          <IconButton
                            className="equipments-equipment-details-header-td-btn-right"
                            size="small"
                            onClick={() => {
                                  toggleAdminCommonDialog('ManufacturersInformationDialog', true, {});
                            }}
                           >
                             <img src={infoIcon} className="info-icon"/>
                           </IconButton>
                      )
                    }

                </td>
              </tr>
              <tr>
                <td>
                    {isEmpty(serialNumber) &&
                    <>
                    <IconButton
                        size="small"
                        aria-label="edit"
                        onClick={() => {
                            setIsSerialFieldVisible(true)
                        }}
                    >
                      <EditIcon fontSize="small"/>
                    </IconButton>&nbsp;
                    </>
                    }
                  Serial Number
                </td>
                <td>
                    {
                      !isSerialFieldVisible
                      ?
                        <>
                        {serialNumber ? serialNumber : 'n/a'}
                        </>
                      :
                        <form>
                          <FormControl
                              fullWidth
                              className="fob-filled-input-with-outside-label equipments-equipment-details-header-serial-field">
                            <TextField
                                id="serialNumber"
                                name="serialNumber"
                                label={null}
                                InputProps={{disableUnderline: true}}
                                InputLabelProps={{shrink: true}}
                                value={serialFormik.values.serialNumber}
                                onChange={serialFormik.handleChange}
                                disabled={updateEquipmentPending}
                                autoFocus
                            />
                            <IconButton
                                size="small"
                                aria-label="save"
                                disabled={updateEquipmentPending}
                                onClick={serialFormik.handleSubmit}
                            >
                              <DoneIcon fontSize="small"/>
                            </IconButton>
                            <IconButton
                                size="small"
                                aria-label="close"
                                disabled={updateEquipmentPending}
                                onClick={() => {
                                    serialFormik.resetForm();
                                    setIsSerialFieldVisible(false);
                                }}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </FormControl>
                        </form>
                    }
                </td>
              </tr>
              <tr>
                <td>LAT ID</td>
                <td>{props.equipmentDetails.latId ? props.equipmentDetails.latId : 'n/a'}</td>
              </tr>
              <tr>
                <td>Description</td>
                <td>{props.equipmentDetails.part.description}</td>
              </tr>
              <tr>
                <td>
                  <IconButton 
                    size="small" 
                    aria-label="edit"
                    onClick={ () => {setIsOwnerFieldVisible(true)} }
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>&nbsp;
                  Owner
                </td>
                <td>
                  { !isOwnerFieldVisible ?
                    <>
                    {owner.company}
                    </>
                  : 
                    <FormControl
                      fullWidth
                      className="fob-filled-input-with-outside-label equipments-equipment-details-header-owner-field">
                      <Select
                            fullWidth
                            id="ownerId"
                            name="ownerId"
                            label={null}
                            placeholder="Select Owner"
                            color="secondary"
                            value={ownerFormik.values.ownerId}
                            onChange={ownerFormik.handleChange}
                            disabled={updateEquipmentPending}
                        >
                            <MenuItem
                                value={null}
                                onClick={toggleAddOwnerDialog.bind(this, true)}
                            >
                              <AddIcon color="secondary" /><Typography component="p" color="secondary">New Owner</Typography>
                            </MenuItem>
                            {
                                props.ownersData.rows.map(item => <MenuItem
                                    value={item.ownerId}>{item.company}</MenuItem>)
                            }
                      </Select>
                      <IconButton 
                          size="small" 
                          aria-label="save"
                          disabled={updateEquipmentPending}
                          onClick = { ownerFormik.handleSubmit }
                        >
                        <DoneIcon fontSize="small" />
                      </IconButton>
                      <IconButton 
                          size="small" 
                          aria-label="close"
                          disabled={updateEquipmentPending}
                          onClick = { () => { ownerFormik.resetForm(); setIsOwnerFieldVisible(false); } }
                        >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </FormControl>
                  }
                </td>
              </tr>
              <tr>
                <td>
                  {isEmpty(dateOfInstall) && 
                    <>
                    <IconButton 
                      size="small" 
                      aria-label="edit"
                      onClick = { () => {setIsDateFieldVisible(true)} }
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>&nbsp;
                    </>
                  }
                  Date of Install
                </td>
                <td>
                    { !isDateFieldVisible ?
                      <>
                      { dateOfInstall ? StringHelper.formatDisplayDate(dateOfInstall) : "n/a"}
                      </>
                    :
                    <form>
                      <FormControl
                        fullWidth
                        className="fob-filled-input-with-outside-label equipments-equipment-details-header-date-field">
                        <KeyboardDatePicker
                          disabled={updateEquipmentPending}
                          autoOk={true}
                          variant="inline"
                          inputVariant="filled"
                          format={CONFIG.DATE_DISPLAY_FORMAT}
                          margin="normal"
                          id="date"
                          labelId="date-label"
                          label={null}
                          className="fob-date-picker"
                          value={dateEditFormik.values.date}
                          onChange={date => {
                            dateEditFormik.setFieldValue('date', date);
                          }}
                        />
                        <IconButton 
                          size="small" 
                          aria-label="save"
                          disabled={updateEquipmentPending}
                          onClick = { dateEditFormik.handleSubmit }
                        >
                          <DoneIcon fontSize="small" />
                        </IconButton>
                        <IconButton 
                          size="small" 
                          aria-label="close"
                          disabled={updateEquipmentPending}
                          onClick = { () => { dateEditFormik.resetForm(); setIsDateFieldVisible(false); } }
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </FormControl>
                    </form>
                  }
                </td>
              </tr>
              <tr>
                <td>
                  <IconButton 
                    size="small" 
                    aria-label="edit"
                    onClick={ () => {setIsAddressDialogVisible(true)} }
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>&nbsp;
                  Location
                </td>
                <td>{StringHelper.formatAddress(address)}</td>
              </tr>
              </tbody>
            </table>
              {/*<Divider orientation="vertical" flexItem />*/}

            </Grid>

            <Grid container item md={4} className="equipments-equipment-details-header-grid-item">

            {/*<div flexItem className="equipments-equipment-details-header-column-2">*/}
            <table>
              <tbody>
              <tr>
                <td>Warranty Date Start</td>
                <td>
                    {
                        Array.isArray(props.equipmentDetails.warranties) && props.equipmentDetails.warranties.length
                            ? props.equipmentDetails.warranties.map(item => {
                                return StringHelper.formatDisplayDate(item.startDate);
                            }).join(',')
                            : null
                    }
                </td>
              </tr>
              <tr>
                <td>Warranty Date End</td>
                <td>
                    {
                        Array.isArray(props.equipmentDetails.warranties) && props.equipmentDetails.warranties.length
                            ? props.equipmentDetails.warranties.map(item => {
                                return StringHelper.formatDisplayDate(item.endDate);
                            }).join(',')
                            : null
                    }
                </td>
              </tr>
              <tr>
                <td>Service Agreement</td>
                <td>
                    {
                      Array.isArray(props.equipmentDetails.serviceAgreements) && props.equipmentDetails.serviceAgreements.length
                      ? props.equipmentDetails.serviceAgreements.map(item => {
                          return item.contractNumber;
                        }).join(',')
                      : null
                    }
                </td>
              </tr>
              <tr>
                <td>Company</td>
                <td>                    {
                    Array.isArray(props.equipmentDetails.serviceAgreements) && props.equipmentDetails.serviceAgreements.length
                        ? props.equipmentDetails.serviceAgreements.map(item => {
                            return item.company;
                        }).join(',')
                        : null
                }</td>
              </tr>
              <tr>
                <td>Contact</td>
                <td>                    {
                    Array.isArray(props.equipmentDetails.serviceAgreements) && props.equipmentDetails.serviceAgreements.length
                        ? props.equipmentDetails.serviceAgreements.map(item => {
                            return item.contact;
                        }).join(',')
                        : null
                    }
                </td>
              </tr>
              <tr>
                <td>Start Date</td>
                <td>
                    {
                        Array.isArray(props.equipmentDetails.serviceAgreements) && props.equipmentDetails.serviceAgreements.length
                            ? props.equipmentDetails.serviceAgreements.map(item => {
                                return StringHelper.formatDisplayDate(item.startDate);
                            }).join(',')
                            : null
                    }
                </td>
              </tr>
              <tr>
                <td>End Date</td>
                <td>
                    {
                        Array.isArray(props.equipmentDetails.serviceAgreements) && props.equipmentDetails.serviceAgreements.length
                            ? props.equipmentDetails.serviceAgreements.map(item => {
                                return StringHelper.formatDisplayDate(item.endDate);
                            }).join(',')
                            : null
                    }
                </td>
              </tr>
              </tbody>
            </table>
          {/*</div>*/}
            </Grid>


          {/*<div style={{flexGrow: 1}} />*/}

          <Grid item
                container
                md={2}
                className="equipments-equipment-details-header-grid-item"
          >
          <div className="header-actions">
            {
              props.equipmentDetails.warranties && props.equipmentDetails.warranties.length > 0
              ? <Button
                  variant="contained"
                  color="primary"
                  onClick={toggleWarrantyDialog.bind(this, true)}
                  startIcon={<CheckCircleOutlineIcon />}
                  className="rounded-btn dark-blue-btn"                  
                >
                  Warranty</Button>
              : <Button
                  variant="contained"
                  color="secondary" // Should be Red Add Warranty
                  onClick={toggleAdminCommonDialog.bind(this, 'AddWarrantyDialog', true, {})}
                  startIcon={<AddCircleOutlineIcon />}
                  className="rounded-btn active-btn"
                >
                Add Warranty</Button>
            }
            {
              props.equipmentDetails.serviceAgreements && props.equipmentDetails.serviceAgreements.length > 0
              ? <Button
                  variant="contained"
                  color="primary"
                  onClick={props.equipmentDetails.serviceAgreements && props.equipmentDetails.serviceAgreements.length > 1
                    ? toggleServiceAgreementList.bind(this, true)
                    : toggleServiceAgreementDialog.bind(this, true, props.equipmentDetails.serviceAgreements[0])}
                  startIcon={<DescriptionIcon />}
                  className="rounded-btn dark-blue-btn"
                >
                  Service Agreement
                </Button>
              : <Button
                  variant="contained"
                  color="secondary" // Should be Red Add Service Agreement
                  onClick={toggleAdminCommonDialog.bind(this, 'AddServiceAgreementDialog', true, {})}
                  startIcon={<AddCircleOutlineIcon />}
                  className="rounded-btn active-btn"
                >
                  Add Service Agreement
                </Button>
            }
            { isSm &&
              <Button
                variant="contained"
                color="secondary" // Should be Red Add Service Agreement
                onClick={toggleActivityDetailsDialog.bind(this, true, props.equipmentDetails, {}, true, null)}
                startIcon={<AddCircleOutlineIcon />}
                className="rounded-btn active-btn"
              >
                Add Activity
              </Button>
            }

          </div>
          </Grid>

          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};

