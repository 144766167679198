import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import { useToggleAdminCommonDialog } from '../admin/redux/toggleAdminCommonDialog';
import {
  Button,
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Toolbar,
  Typography
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useFormik } from 'formik';
import { clone } from 'underscore';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { AddAddressDialog } from './index';
import { useCreateServiceAgreement } from './redux/hooks';
import { LoadingButton, DialogBox } from '../common';
import Swal from "sweetalert2";
import AddAddressButton from '../common/AddAddressButton';
import StringHelper from "../common/services/StringHelper";
import {CONFIG} from './../../config';
import {useGetEquipmentDetails} from "./redux/getEquipmentDetails";


export default function AddServiceAgreementDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const toggleDialog = useSelector(state => state.admin.toggleDialog);
  const {toggleAdminCommonDialog} = useToggleAdminCommonDialog();
  const {createServiceAgreement, createServiceAgreementPending} = useCreateServiceAgreement();
  // const {toggleAddManufacturerDialog} = useToggleAddManufacturerDialog();
  const [installDate, setInstallDate] = useState('');
  const [isAddressDialogVisible, setIsAddressDialogVisible] = useState(false);
  const {getEquipmentDetails} = useGetEquipmentDetails();

  useEffect(() => {
    let manufacDate = StringHelper.formatDisplayDate(props.equipmentDetails.manufactureDate);
    setInstallDate(manufacDate);
  }, [props.equipmentDetails]);

  // value={`${props.user.firstName} ${props.user.lastName}`}
console.log('equipmentDetails: ',props.equipmentDetails);

  const formik = useFormik({
    initialValues: {
      assetId: props.equipmentDetails.assetId,
      serialNumber: props.equipmentDetails.serialNumber,
      description: '',
      contractNumber: '',
      company: props.user.owner && props.user.owner.company,
      contact: '',
      phoneNumber: props.user.phoneNumber,
      startDate: null,
      endDate: null,
      address: {
        line1: '',
        city: '',
        zipCode: '',
        state: '',
        country: 'United States',
      },
      hasAddress: false,
    },
    enableReinitialize: true,
    onSubmit: (values, {resetForm}) => {
      console.log('onSubmit service agreement: ', values);

      let data = clone(values);
      data.startDate = values.startDate.format('YYYY-MM-DD');
      data.endDate = values.endDate.format('YYYY-MM-DD');

      console.log('data: ',data);

      createServiceAgreement(data)
        .then(res => {
          console.log('res: ',res);
          resetForm();
          getEquipmentDetails(props.equipmentDetails.assetId);
          Swal.fire({
            icon: 'success',
            text: res.message,
            timer: 2000
          });
          toggleAdminCommonDialog('AddServiceAgreementDialog', false, {});
        });
    },
  });


  return (
      <DialogBox
        fullScreen={fullScreen}
        open={toggleDialog.name === 'AddServiceAgreementDialog' && toggleDialog.flag === true}
        className="equipments-add-service-agreement-dialog fob-details-dialog fob-form-dialog">
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>
            <Toolbar>
              <Typography variant="h5" display="inline">Add Service Agreement</Typography>
              <span style={{flexGrow: 1}}></span>
              <IconButton
                edge="end"
                color="inherit" aria-label="menu"
                onClick={toggleAdminCommonDialog.bind(this, 'AddServiceAgreementDialog', false, {})}>
                <HighlightOffIcon />
              </IconButton>
            </Toolbar>
            <Divider />
          </DialogTitle>

          <DialogContent>
            <Container maxWidth="sm">
              <Grid container
                    lg={12}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={2}>

                <Grid item container lg={12} spacing={2} >
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      id="manufacturer"
                      label="Manufacturer"
                      InputProps={{disableUnderline: true}}
                      InputLabelProps={{ shrink: true }}
                      className="fob-filled-input-with-outside-label"
                      value={props.equipmentDetails.manufacturer.description}
                      disabled
                      fullWidth
                    />
                    {/*<FormControl*/}
                    {/*  fullWidth*/}
                    {/*  className="fob-filled-input-with-outside-label">*/}
                    {/*  <InputLabel shrink id="company-select-label">Company</InputLabel>*/}
                    {/*  <Select*/}
                    {/*    id="manufacturerId"*/}
                    {/*    name="manufacturerId"*/}
                    {/*    className="fob-filled-input-with-outside-label"*/}
                    {/*    label="Company"*/}
                    {/*    placeholder="Select Company"*/}
                    {/*    color="secondary"*/}
                    {/*    value={props.formik.values.manufacturerId}*/}
                    {/*    onChange={props.formik.handleChange}*/}
                    {/*  >*/}
                    {/*    <MenuItem value=""*/}
                    {/*              key={0}*/}
                    {/*              onClick={toggleAddManufacturerDialog.bind(this, true)}*/}
                    {/*    >*/}
                    {/*      <AddIcon color="secondary" /><Typography component="p" color="secondary">New Company</Typography>*/}
                    {/*    </MenuItem>*/}
                    {/*    {*/}
                    {/*      props.manufacturersData.rows.map(item => <MenuItem key={item.manufacturerId} value={item.manufacturerId}>{item.description}</MenuItem>)*/}
                    {/*    }*/}
                    {/*  </Select>*/}
                    {/*</FormControl>*/}
                  </Grid>

                  <Grid item container xs={12} sm={12} lg={6}>
                    <TextField
                      id="serial"
                      label="Serial Number"
                      InputProps={{disableUnderline: true}}
                      InputLabelProps={{ shrink: true }}
                      className="fob-filled-input-with-outside-label"
                      value={formik.values.serialNumber}
                      disabled
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid item container lg={12} spacing={2}>
                  <Grid item xs={12} sm={12} lg={6}>
                    <TextField
                      id="owner"
                      label="Owner"
                      InputProps={{disableUnderline: true}}
                      InputLabelProps={{ shrink: true }}
                      className="fob-filled-input-with-outside-label"
                      value={props.equipmentDetails.owner.company}
                      fullWidth
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={6}>
                    <TextField
                      id="dateOfInstall"
                      label="Date of Install"
                      InputProps={{disableUnderline: true}}
                      InputLabelProps={{ shrink: true }}
                      className="fob-filled-input-with-outside-label"
                      value={installDate}
                      fullWidth
                      disabled
                    />
                  </Grid>
                </Grid>

                <Grid item container xs={12} sm={12} lg={12} spacing={2}>
                  <Grid item xs={12} sm={12} lg={6}>
                    <TextField
                      id="contact"
                      label="Contact"
                      InputProps={{disableUnderline: true}}
                      InputLabelProps={{ shrink: true }}
                      className="fob-filled-input-with-outside-label"
                      value={formik.values.contact}
                      onChange={formik.handleChange}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={6}>
                    <TextField
                      id="contractNumber"
                      label="Service Agreement"
                      InputProps={{disableUnderline: true}}
                      InputLabelProps={{ shrink: true }}
                      className="fob-filled-input-with-outside-label"
                      value={formik.values.contractNumber}
                      onChange={formik.handleChange}
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid item container xs={12} sm={12} lg={12} spacing={2}>
                  <Grid item xs={12} sm={12} lg={6}>
                    <TextField
                      id="description"
                      label="Description"
                      InputProps={{disableUnderline: true}}
                      InputLabelProps={{ shrink: true }}
                      className="fob-filled-input-with-outside-label"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={6}>
                    <FormControl
                      fullWidth
                      className="fob-filled-input-with-outside-label">
                      <InputLabel shrink id="startdate-label">Start Date</InputLabel>
                      <KeyboardDatePicker
                        autoOk={true}
                        variant="inline"
                        inputVariant="filled"
                        format={CONFIG.DATE_DISPLAY_FORMAT}
                        margin="normal"
                        id="startDate"
                        labelId="startdate-label"
                        label={null}
                        className="fob-date-picker"
                        value={formik.values.startDate}
                        onChange={date => {
                          formik.setFieldValue('startDate', date);
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item container xs={12} sm={12} lg={12} spacing={2}>
                  <Grid item xs={12} sm={12} lg={6}>
                    {
                      formik.values.address.hasAddress
                        ? <TextField
                          id="location"
                          label="Location"
                          InputProps={{disableUnderline: true}}
                          InputLabelProps={{ shrink: true }}
                          className="fob-filled-input-with-outside-label"
                          value={formik.values.address.line1}
                          onChange={formik.handleChange}
                          fullWidth
                          onClick={() => {
                            setIsAddressDialogVisible(true);
                          }}
                        />
                        : <FormControl
                          fullWidth
                          className="fob-filled-input-with-outside-label">
                          <AddAddressButton onClick={() => {
                                    setIsAddressDialogVisible(true);
                                  }}
                          />
                        </FormControl>
                    }
                  </Grid>

                  <Grid item xs={12} sm={12} lg={6}>
                    <FormControl
                      fullWidth
                      className="fob-filled-input-with-outside-label">
                      <InputLabel shrink id="enddate-label">End Date</InputLabel>
                      <KeyboardDatePicker
                        autoOk={true}
                        variant="inline"
                        inputVariant="filled"
                        format={CONFIG.DATE_DISPLAY_FORMAT}
                        margin="normal"
                        id="endDate"
                        labelId="enddate-label"
                        label={null}
                        className="fob-date-picker"
                        value={formik.values.endDate}
                        onChange={date => {
                          formik.setFieldValue('endDate', date);
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

              </Grid>
            </Container>
          </DialogContent>

          <DialogActions>
            {
              createServiceAgreementPending === true
                ? <LoadingButton />
                : <Button variant="contained"
                          disableElevation
                          color="secondary"
                          type="submit"
                          className="rounded-btn"
                >
                  Save
                </Button>
            }

            <Button variant="contained"
                    disableElevation
                    onClick={toggleAdminCommonDialog.bind(this, 'AddServiceAgreementDialog', false, {})}
                    className="rounded-btn dark-btn"
                    disabled={createServiceAgreementPending === true}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>

        <AddAddressDialog formik={formik}
                          visible={isAddressDialogVisible}
                          setVisible={setIsAddressDialogVisible}
                          countries={props.countries} />
      </DialogBox>
  );
};

