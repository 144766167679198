import React from 'react';
import {
    Dialog,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function DialogBox(props) {
  const isSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={props.fullScreen}
      open={props.open}
      className={!isSm ? props.className : props.className+" MuiDialog-root-mobile"}>
      {props.children}
    </Dialog>
  );
};
