import React from 'react';
import {Container} from '@material-ui/core';
import {
  ASSET_MOVEMENT_TYPE,
  LIFECYCLE_TRACKING_TYPE,
  SPEND_BY_MANUFACTURER_TYPE,
  SPEND_BY_MODEL_TYPE,
  MOST_EXPENSIVE_FAULT_CODES_TYPE, MOST_COMMON_FAULT_CODES_TYPE,
} from './redux/constants';
import {AssetMovement, LifecycleTracking} from './index';
import SpendByManufacturer from './charts/SpendByManufacturer';
import SpendByModel from './charts/SpendByModel';
import MostExpensiveFaultCodes from './charts/MostExpensiveFaultCodes';
import MostCommonFaultCodes from './charts/MostCommonFaultCodes';

function SelectedDashboardContent({contentType}) {
  if (contentType === ASSET_MOVEMENT_TYPE) {
    return (<AssetMovement />);
  } else if (contentType === LIFECYCLE_TRACKING_TYPE) {
    return (<LifecycleTracking />);
  } else if (contentType === SPEND_BY_MANUFACTURER_TYPE) {
    return (<SpendByManufacturer />);
  } else if (contentType === SPEND_BY_MODEL_TYPE) {
    return (<SpendByModel />);
  } else if (contentType === MOST_EXPENSIVE_FAULT_CODES_TYPE) {
    return (<MostExpensiveFaultCodes />);
  } else if (contentType === MOST_COMMON_FAULT_CODES_TYPE) {
    return (<MostCommonFaultCodes />);
  }

  return null;
}

export default function DashboardContent({contentType}) {
  return (
    <Container className="dashboard-dashboard-content">
        <SelectedDashboardContent contentType={contentType} />
    </Container>
  );
};
