import { combineReducers } from 'redux';
// import { routerReducer } from 'react-router-redux';
import { connectRouter } from 'connected-react-router'
import history from './history';
import homeReducer from '../features/home/redux/reducer';
import commonReducer from '../features/common/redux/reducer';
import authenticationReducer from '../features/authentication/redux/reducer';
import equipmentsReducer from '../features/equipments/redux/reducer';
import adminReducer from '../features/admin/redux/reducer';
import profileReducer from '../features/profile/redux/reducer';
import helpReducer from '../features/help/redux/reducer';
import searchReducer from '../features/search/redux/reducer';
import manufacturersReducer from '../features/manufacturers/redux/reducer';
import partsReducer from '../features/parts/redux/reducer';
import rolesReducer from '../features/roles/redux/reducer';
import categoriesReducer from '../features/categories/redux/reducer';
import dashboardReducer from '../features/dashboard/redux/reducer';

// NOTE 1: DO NOT CHANGE the 'reducerMap' name and the declaration pattern.
// This is used for Rekit cmds to register new features, remove features, etc.
// NOTE 2: always use the camel case of the feature folder name as the store branch name
// So that it's easy for others to understand it and Rekit could manage them.

const reducerMap = {
  router: connectRouter(history),
  home: homeReducer,
  common: commonReducer,
  authentication: authenticationReducer,
  equipments: equipmentsReducer,
  admin: adminReducer,
  profile: profileReducer,
  help: helpReducer,
  search: searchReducer,
  manufacturers: manufacturersReducer,
  parts: partsReducer,
  roles: rolesReducer,
  categories: categoriesReducer,
  dashboard: dashboardReducer,
};

export default combineReducers(reducerMap);
