import React, {useEffect} from 'react';
import {Dialog, DialogContent, DialogActions, DialogTitle, Button} from '@material-ui/core';
import {useToggleBiometricUsagePromptDialog} from "./redux/toggleBiometricUsagePromptDialog";
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import AuthService from "./services/AuthService";
import WebviewCommunication from "../common/services/WebviewCommunication";
import {useSignIn} from "./redux/signIn";
import {isNull} from 'underscore';


export default function BiometricUsagePromptDialog() {
  const {isBiometricUsagePromptDialogVisible, toggleBiometricUsagePromptDialog} = useToggleBiometricUsagePromptDialog();
  const {signInPending, signInError, signInSuccess, isUserBiometricSet} = useSignIn();

  useEffect(() => {
      if (signInPending === false && isNull(signInError) && isNull(isUserBiometricSet) && signInSuccess === true) {
          toggleBiometricUsagePromptDialog(true);
      }
  }, [signInPending, signInError, signInSuccess, isUserBiometricSet, toggleBiometricUsagePromptDialog]);


  const handleUseBiometricUsage = () => {
      AuthService.setUserBiometricSettings(true);
      WebviewCommunication.postMessage('USE_BIOMETRIC_AUTHENTICATION', {isUserBiometricSet: true});
      toggleBiometricUsagePromptDialog(false);
  };

  const handleCancelBiometricUsage = () => {
      AuthService.setUserBiometricSettings(false);
      WebviewCommunication.postMessage('USE_BIOMETRIC_AUTHENTICATION', {isUserBiometricSet: false});
      toggleBiometricUsagePromptDialog(false);
  };

  return (
    <Dialog open={isBiometricUsagePromptDialogVisible}
            className="authentication-biometric-usage-prompt-dialog bottom-half-dialog">
      <DialogTitle>
          <FingerprintIcon fontSize="large" className="top-icon"/>
      </DialogTitle>
      <DialogContent>
          <h3>Fingerprint Signin</h3>
          <p>Access your account with just your fingerprint</p>
          <p>All fingerprints stored on this device can be used to access your account. We do not recommend this if you are sharing this device.</p>
      </DialogContent>
      <DialogActions>
          <Button variant="contained"
                  disableElevation
                  color="primary"
                  onClick={handleCancelBiometricUsage}
          >NOT NOW</Button>
          <Button variant="contained"
                  disableElevation
                  color="secondary"
                  onClick={handleUseBiometricUsage}
          >USE TOUCH ID</Button>
      </DialogActions>
    </Dialog>
  );
};
